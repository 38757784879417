import {
  DownloadOutlined,
  EditOutlined,
  HomeFilled,
  PlusOutlined,
  SettingOutlined,
  CopyOutlined,
} from '@ant-design/icons/lib';
import { classNames } from '@discngine/moosa-common';
import { convertScoringTemplateToDecisionTree } from '@discngine/moosa-decision-tree';
import { useMoosaDataContext } from '@discngine/moosa-models';
import { getElementIdToSvg } from '@discngine/moosa-screenshot';
import { DatasetDetails, LoadingSpinner } from '@discngine/moosa-shared-components';
import { resetDecisionTreeConfig } from '@discngine/moosa-store/decisionTreeConfig';
import {
  selectConfigPanelMode,
  setConfigPanelMode,
} from '@discngine/moosa-store/sarConfig';
import { selectSarPinnedSubstances } from '@discngine/moosa-store/sarTableData';
import {
  selectErrorsAvailable,
  selectScoringTemplate,
  selectScoringTemplateData,
} from '@discngine/moosa-store/scoringTemplate';
import { addComputedColumn } from '@discngine/moosa-store/tableConfig';
import {
  fetchDataWithScores,
  selectIsCompareMode,
  selectShowErrors,
  setShowErrors,
} from '@discngine/moosa-store/tableData';
import {
  DEFAULT_SIDER_WIDTH,
  MAX_SIDER_WIDTH,
  selectSiderWidth,
  selectTableInfo,
  selectTableInfoIsLoading,
  selectTableTotalColumns,
  selectTableMissingValues,
  selectTableTotalRows,
  setSiderWidth,
  selectTableColumnsMap,
} from '@discngine/moosa-store/tableInfo';
import { RDKitContext } from '@discngine/moosa-structure-renderer';
import { Button, Drawer, Layout, Radio, Space, Tooltip } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import debounce from 'lodash/debounce';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import { Link, useParams } from 'react-router-dom';
import { TemplatesPanel } from 'src/components/TemplatesPanel/TemplatesPanel';
import { useRDKitProvider } from 'src/components/useRDKitProvider';
import { ReactComponent as CollapseIcon } from 'src/resources/icons/collapse-shevron.svg';
import Spinner from 'src/shared/Spinner/Spinner';
import {
  applyToggleCharts,
  cancelSmartChartCalculations,
} from 'src/store/charts/charts.thunk';
import { fetchTableInfo } from 'src/store/fetchTableInfoThunk';

import { AppDispatch } from '../../store/store';

import { CompareModeSwitch } from './CompareModeSwitch/CompareModeSwitch';
import { CompareTemplateModeSwitch } from './CompareTemplateModeSwitch/CompareTemplateModeSwitch';
import { CssVariablesMapper } from './CssVarMapper/CssVarMapper';
import { DecisionTreeProperties } from './DecisionTreeProperties';
import { dispatchSetTreeEvent } from './DecisionTreeWrapper/useSetTreeEventListener';
import { EditLabelsForm } from './EditLabelsForm/EditLabelsForm';
import { MMPRequestConfig } from './MMPRequestConfig';
import styles from './MultiDimensionsAnalysis.module.less';
import { SarMatrixProperties } from './SarMatrixProperties';
import { SarTableProperties } from './SarTableProperties';
import { ShowErrorsCheckbox } from './ShowErrorsCheckbox';
import { TableProperties } from './TableProperties';

const DataTableWrapperLazy = React.lazy(
  () => import('src/components/DataTableWrapper/DataTableWrapper')
);
const ChartsPanelLazy = React.lazy(() => import('./ChartsPanel/ChartsPanel'));
const DataSarTableWrapperLazy = React.lazy(
  () => import('src/components/DataSarTableWrapper/DataSarTableWrapper')
);
const DataSarRadiantChartWrapperLazy = React.lazy(
  () => import('src/components/DataSarRadiantChartWrapper/DataSarRadiantChartWrapper')
);
const SarMatrixTableDataWrapperLazy = React.lazy(
  () => import('src/components/DataSarMatrixWrapper/SarMatrixTableDataWrapper')
);
const DecisionTreeWrapperLazy = React.lazy(() => import('./DecisionTreeWrapper'));

export enum DataViewMode {
  TABLE,
  CHART,
  SAR,
  RADIANT_CHART,
  MATRIX,
  DECISION_TREE,
}

const RESIZE_HANDLE_SIZE: [number, number] = [10, 10];
const RESIZE_MIN: [number, number] = [DEFAULT_SIDER_WIDTH, 0];
const RESIZE_MAX: [number, number] = [MAX_SIDER_WIDTH, 0];
const DEBOUNCE_DELAY = 250;
const debouncedFetch = debounce(
  (dispatch, actionCreator) => dispatch(actionCreator()),
  DEBOUNCE_DELAY
);

const getSettingsTitle = (mode: DataViewMode) => {
  switch (mode) {
    case DataViewMode.SAR:
      return 'SAR Table';
    case DataViewMode.MATRIX:
      return 'SAR Matrix';
    case DataViewMode.TABLE:
      return 'Table';
    case DataViewMode.CHART:
      return 'Chart';
    case DataViewMode.RADIANT_CHART:
      return 'MMP';
    case DataViewMode.DECISION_TREE:
      return 'DT';
    default:
      throw new Error('Unknown DataViewMode');
  }
};

const datasetTitle = (name: string, collapsed: boolean) => (
  <div className={styles.datasetName}>
    <Link to="/">
      <HomeFilled className={styles.datasetNameIcon} height={24} width={24} />
    </Link>
    <Tooltip overlayClassName={styles.datasetNameTooltip} placement="bottom" title={name}>
      <span
        className={classNames(styles.datasetNameText, {
          [styles.nameOnSiderCollapsed]: collapsed,
        })}
      >
        {name}
      </span>
    </Tooltip>
  </div>
);

const MultiDimensionsAnalysis: React.FC = () => {
  const params = useParams();
  const tableId = params.id ?? '';
  const isLoading = useSelector(selectTableInfoIsLoading);
  const tableStats = useSelector(selectTableInfo);
  const siderWidth: number = useSelector(selectSiderWidth);
  const isCompareMode = useSelector(selectIsCompareMode);
  const showErrors = useSelector(selectShowErrors);
  const showErrorsAvailable = useSelector(selectErrorsAvailable);
  const isSarConfigPanelOpen = useSelector(selectConfigPanelMode);
  const template = useSelector(selectScoringTemplate);
  const { getDataWithScore } = useMoosaDataContext();
  const pinnedSubstances = useSelector(selectSarPinnedSubstances);
  const tableTotalRows = useSelector(selectTableTotalRows);
  const tableTotalColumns = useSelector(selectTableTotalColumns);
  const missingValues = useSelector(selectTableMissingValues);
  const templateData = useSelector(selectScoringTemplateData);
  const columnMeta = useSelector(selectTableColumnsMap);

  const [isInit, setIsInit] = useState(true);

  const dispatch = useDispatch<AppDispatch>();
  const dataService = useMoosaDataContext();
  const [viewMode, setViewMode] = useState<DataViewMode>(DataViewMode.TABLE);
  const [editLabels, openEditLabels] = useState(false);
  const toggleChart = useCallback(
    (event: RadioChangeEvent) => {
      dispatch(
        applyToggleCharts(
          dataService.getDataWithScore,
          event.target.value === DataViewMode.CHART
        )
      );
      setViewMode(event.target.value);
    },
    [dispatch, dataService]
  );

  const [isTableConfigVisible, setIsTableConfigVisible] = useState(
    !!isSarConfigPanelOpen
  );

  const fetchData = useCallback(
    (currentPage: number, pageSize: number) => {
      debouncedFetch(dispatch, () =>
        fetchDataWithScores(
          template,
          getDataWithScore,
          currentPage,
          pageSize,
          undefined,
          pinnedSubstances
        )
      );
    },
    [dispatch, getDataWithScore, pinnedSubstances, template]
  );

  useEffect(() => {
    setIsTableConfigVisible(!!isSarConfigPanelOpen);
  }, [isSarConfigPanelOpen]);
  const showTableConfig = useCallback(() => {
    setIsTableConfigVisible(true);
  }, []);
  const closeTableConfig = useCallback(() => {
    setIsTableConfigVisible(false);
    openEditLabels(false);
    dispatch(setConfigPanelMode(false));
  }, [dispatch]);

  const [siderCollapsed, setSiderCollapsed] = useState(false);

  useEffect(() => {
    setSiderCollapsed((state) => {
      return state || viewMode === DataViewMode.DECISION_TREE;
    });
  }, [viewMode]);

  useEffect(() => {
    dispatch(fetchTableInfo(dataService, tableId));
    setIsInit(false);
  }, [dispatch, tableId, dataService]);

  useEffect(
    () => () => {
      cancelSmartChartCalculations();
    },
    []
  );

  useEffect(() => {
    if (!showErrorsAvailable) {
      dispatch(setShowErrors(false));
    }
  }, [dispatch, showErrorsAvailable]);

  useEffect(() => {
    dispatch(resetDecisionTreeConfig());
  }, [dispatch]);

  const onResize = useCallback(
    (event: SyntheticEvent, data: ResizeCallbackData) =>
      dispatch(setSiderWidth(data.size.width)),
    [dispatch]
  );
  const handleShowErrorsChange = useCallback(
    (evt: { target: { checked: boolean } }) => {
      dispatch(setShowErrors(evt.target.checked));
    },
    [dispatch]
  );

  const RDKit = useRDKitProvider();

  if (isInit || isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className={styles.mainHeader}>
        <div className={styles.leftHeaderWrapper}>
          {datasetTitle(tableStats.name, siderCollapsed)}
        </div>
        <div className={styles.overTable}>
          <Space size={16}>
            <CompareModeSwitch dataViewMode={viewMode} />
            <CompareTemplateModeSwitch dataViewMode={viewMode} />
            <ShowErrorsCheckbox
              handleShowErrorsChange={handleShowErrorsChange}
              showCheckbox={viewMode === DataViewMode.TABLE}
              showErrors={showErrors}
              showErrosAvailable={showErrorsAvailable}
            />
          </Space>
          <Radio.Group
            buttonStyle="solid"
            optionType="button"
            value={viewMode}
            onChange={toggleChart}
          >
            <Radio.Button key={DataViewMode.TABLE} value={DataViewMode.TABLE}>
              Table
            </Radio.Button>
            <Radio.Button key={DataViewMode.CHART} value={DataViewMode.CHART}>
              Chart
            </Radio.Button>
            <Radio.Button key={DataViewMode.SAR} value={DataViewMode.SAR}>
              SAR
            </Radio.Button>
            <Radio.Button
              key={DataViewMode.RADIANT_CHART}
              value={DataViewMode.RADIANT_CHART}
            >
              MMP
            </Radio.Button>
            <Radio.Button key={DataViewMode.MATRIX} value={DataViewMode.MATRIX}>
              Matrix
            </Radio.Button>
            <Radio.Button
              key={DataViewMode.DECISION_TREE}
              value={DataViewMode.DECISION_TREE}
            >
              DT
            </Radio.Button>
          </Radio.Group>
          <div className={styles.configButtons}>
            <div className={viewMode !== DataViewMode.CHART ? '' : styles.hidden}>
              {viewMode === DataViewMode.DECISION_TREE && (
                <Button
                  className={styles.addCalcColumn}
                  icon={<CopyOutlined />}
                  type="link"
                  onClick={() =>
                    dispatchSetTreeEvent(
                      convertScoringTemplateToDecisionTree(templateData.func, columnMeta)
                    )
                  }
                >
                  Convert Scoring Template to DT
                </Button>
              )}
              {(viewMode === DataViewMode.TABLE ||
                viewMode === DataViewMode.DECISION_TREE) && (
                <Button
                  className={styles.addCalcColumn}
                  icon={<EditOutlined />}
                  type="link"
                  onClick={() => {
                    setIsTableConfigVisible(true);
                    openEditLabels(true);
                  }}
                >
                  Labels
                </Button>
              )}
              {viewMode === DataViewMode.TABLE && (
                <Button
                  className={styles.addCalcColumn}
                  icon={<PlusOutlined />}
                  type="link"
                  onClick={() => dispatch(addComputedColumn())}
                >
                  Computed Column
                </Button>
              )}
              {viewMode === DataViewMode.SAR && (
                <Button
                  className={styles.addCalcColumn}
                  icon={<DownloadOutlined />}
                  type="link"
                  onClick={() => getElementIdToSvg('MoosaSarTable')}
                >
                  Export
                </Button>
              )}
              {(viewMode === DataViewMode.SAR ||
                viewMode === DataViewMode.MATRIX ||
                viewMode === DataViewMode.RADIANT_CHART ||
                viewMode === DataViewMode.TABLE ||
                viewMode === DataViewMode.DECISION_TREE) && (
                <Button
                  className={styles.showHideColumn}
                  type="link"
                  onClick={showTableConfig}
                >
                  <SettingOutlined /> {getSettingsTitle(viewMode)}
                  {viewMode === DataViewMode.RADIANT_CHART
                    ? ' request settings'
                    : ' properties'}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Layout className={styles.siderWrapper}>
        <Layout.Sider
          className={classNames(styles.sider, {
            [styles.siderCollapsed]: siderCollapsed,
          })}
          collapsed={siderCollapsed}
          collapsedWidth={0}
          collapsible
          trigger={null}
          width={siderWidth + 12}
        >
          <ResizableBox
            axis={'x'}
            className={styles.resizableBox}
            handle={<div className={styles.handle}></div>}
            handleSize={RESIZE_HANDLE_SIZE}
            height={0}
            maxConstraints={RESIZE_MAX}
            minConstraints={RESIZE_MIN}
            resizeHandles={['e']}
            width={siderWidth}
            onResize={onResize}
          >
            <div className={styles.siderContainer}>
              <div className={styles.collapseLine}>
                <div className={styles.datasetInfo}>
                  <DatasetDetails
                    columnsCount={tableTotalColumns}
                    missingValuesCount={missingValues}
                    rowsCount={tableTotalRows}
                  />
                </div>
              </div>
              <TemplatesPanel />
              {isCompareMode && <div className={styles.cover}></div>}
            </div>
          </ResizableBox>
        </Layout.Sider>
        <div className={styles.collapse}>
          <Button type="primary" onClick={() => setSiderCollapsed(!siderCollapsed)}>
            <CollapseIcon
              className={`${styles.collapseIcon} ${siderCollapsed ? styles.expand : ''}`}
            />
          </Button>
        </div>
        <Layout className={styles.siteLayout}>
          <React.Suspense fallback={<LoadingSpinner />}>
            <RDKitContext.Provider value={RDKit}>
              {viewMode === DataViewMode.TABLE && (
                <div className={styles.mainPanel}>
                  {!isLoading && <DataTableWrapperLazy />}
                </div>
              )}
              {viewMode === DataViewMode.CHART && (
                <div className={styles.mainPanel}>
                  <ChartsPanelLazy />
                </div>
              )}
              {viewMode === DataViewMode.SAR && (
                <div className={styles.mainPanel}>
                  <DataSarTableWrapperLazy
                    fetchData={fetchData}
                    pinnedSubstances={pinnedSubstances}
                    templateIds={template.order}
                  />
                </div>
              )}
              {viewMode === DataViewMode.RADIANT_CHART && (
                <div className={styles.mainPanel}>
                  <DataSarRadiantChartWrapperLazy />
                </div>
              )}
              {viewMode === DataViewMode.MATRIX && (
                <div className={styles.mainPanel}>
                  <SarMatrixTableDataWrapperLazy />
                </div>
              )}
              {viewMode === DataViewMode.DECISION_TREE && (
                <div className={styles.mainPanel}>
                  <DecisionTreeWrapperLazy />
                </div>
              )}
            </RDKitContext.Provider>
          </React.Suspense>
        </Layout>
      </Layout>
      <CssVariablesMapper />
      <Drawer
        bodyStyle={{ padding: '16px 12px 16px 24px' }}
        headerStyle={{ padding: '16px 24px' }}
        open={isTableConfigVisible}
        placement="right"
        title={`${getSettingsTitle(viewMode)} ${
          viewMode === DataViewMode.RADIANT_CHART ? 'request' : ''
        } settings`}
        width="370"
        onClose={closeTableConfig}
      >
        <PerfectScrollbar
          className={styles.sideScroll}
          options={{ suppressScrollX: true }}
        >
          {viewMode === DataViewMode.TABLE &&
            (editLabels ? <EditLabelsForm /> : <TableProperties />)}
          {viewMode === DataViewMode.SAR && <SarTableProperties />}
          {viewMode === DataViewMode.MATRIX && <SarMatrixProperties />}
          <RDKitContext.Provider value={RDKit}>
            {viewMode === DataViewMode.RADIANT_CHART && <MMPRequestConfig />}
          </RDKitContext.Provider>
          {viewMode === DataViewMode.DECISION_TREE &&
            (editLabels ? <EditLabelsForm /> : <DecisionTreeProperties />)}
        </PerfectScrollbar>
      </Drawer>
    </>
  );
};

export default MultiDimensionsAnalysis;
