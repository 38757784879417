import { RangeCondition } from '@discngine/moosa-models';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback } from 'react';

import { TIMEZONE_OFFSET_MS } from '../../../constants';
import styles from '../shared/PropertyNodeEditPanel.module.less';

export interface RangeDateConditionInputProps {
  condition: RangeCondition;
  disabled: boolean;
  onChange: (condition: RangeCondition) => void;
}

export const RangeDateConditionInput = React.memo(
  ({ condition, disabled, onChange }: RangeDateConditionInputProps) => {
    const onMinThresholdChange = useCallback(
      (value: Dayjs | null) => {
        condition.min.threshold = value
          ? value.valueOf() - TIMEZONE_OFFSET_MS // move date to UTC
          : Date.now();
        onChange({ ...condition });
      },
      [condition, onChange]
    );

    const onMaxThresholdChange = useCallback(
      (value: Dayjs | null) => {
        condition.max.threshold = value
          ? value.valueOf() - TIMEZONE_OFFSET_MS // move date to UTC
          : Date.now();
        onChange({ ...condition });
      },
      [condition, onChange]
    );

    return (
      <>
        <div className={styles.dateConditionRow}>
          <div className={styles.dateLine}>
            <div className={styles.dateLabel}>DATE from</div>
            <div className={styles.datePicker}>
              <DatePicker
                className={styles.input}
                disabled={disabled}
                placeholder="Threshold"
                size="middle"
                style={{ width: '100%' }}
                value={dayjs(condition.min.threshold + TIMEZONE_OFFSET_MS)}
                onChange={onMinThresholdChange}
              />
            </div>
          </div>
          <div className={styles.dateLine}>
            <div className={styles.dateLabel}>to</div>
            <div className={styles.datePicker}>
              <DatePicker
                className={styles.input}
                disabled={disabled}
                placeholder="Threshold"
                size="middle"
                style={{ width: '100%' }}
                value={dayjs(condition.max.threshold + TIMEZONE_OFFSET_MS)}
                onChange={onMaxThresholdChange}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
);
