import { DownOutlined } from '@ant-design/icons';
import { useIsOverflow } from '@discngine/moosa-common';
import {
  ColumnId,
  DTGroupOption,
  DecisionTreeGroups,
  IColumnLabelMap,
} from '@discngine/moosa-models';
import { Button, Dropdown, Space, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { getColumnLabel } from 'utils';

import styles from './GroupSelector.module.less';

interface GroupSelectorProps {
  groups: DecisionTreeGroups;
  columnId: ColumnId | null | undefined;
  columnLabelMap?: IColumnLabelMap;
  selectedGroup: DTGroupOption | null;
  onGroupSelect: (group: DTGroupOption | null) => void;
}

export const GroupSelector = ({
  groups,
  columnId,
  columnLabelMap,
  selectedGroup,
  onGroupSelect,
}: GroupSelectorProps) => {
  /**
   * Resets the selected index when the groupOptions change.
   */
  useEffect(() => {
    onGroupSelect(null);
  }, [groups.options, onGroupSelect]);

  /**
   * Passes the selected option to the onGroupSelect callback function.
   */
  const onSelect = useCallback(
    (key: DTGroupOption['key']) => {
      const option = groups.options.find((group) => group.key === key) || null;

      onGroupSelect(option);
    },
    [groups.options, onGroupSelect]
  );

  const label = useMemo(() => {
    return columnId ? getColumnLabel(columnId, columnLabelMap) : 'Groups';
  }, [columnId, columnLabelMap]);

  const labelRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(labelRef, [label]);

  return (
    <Space className={styles.groupSelector} size={10} wrap={true}>
      <Tooltip title={isOverflow ? label : undefined}>
        <div ref={labelRef} className={styles.label}>
          {label}
        </div>
      </Tooltip>

      <Dropdown
        menu={{
          items: [
            ...groups.options.map(({ name, color, key }) => ({
              key,
              label: (
                <div className={styles.groupOption}>
                  {name || 'Missing Values'}

                  <Space size={8}>
                    <span className={styles.groupOptionCount}>
                      {groups.groups.get(name)?.size || 0}
                    </span>

                    <span
                      className={styles.groupOptionColor}
                      style={{ backgroundColor: color }}
                    />
                  </Space>
                </div>
              ),
            })),
            { type: 'divider' },
            {
              key: '-1',
              label: 'All',
            },
          ],
          selectable: true,
          selectedKeys: [selectedGroup ? selectedGroup.key : ''],
          onSelect: (event) => onSelect(event.key),
        }}
        placement="bottomLeft"
        trigger={['click']}
      >
        <Button className={styles.dropdownButton}>
          <Space size={13}>
            <Space size={4} style={{ minWidth: '100px' }}>
              {selectedGroup?.color && (
                <span
                  className={styles.groupOptionColor}
                  style={{ backgroundColor: selectedGroup.color }}
                />
              )}

              <span className={styles.buttonGroupName}>
                {selectedGroup ? selectedGroup.name || 'Missing Values' : 'All'}
              </span>
            </Space>

            <DownOutlined className={styles.dropdownIcon} />
          </Space>
        </Button>
      </Dropdown>
    </Space>
  );
};
