import { DiagramData } from '../../types';

export const WHITE = '#FFF';
export const DANGER = '#E7615A';
export const WARNING = '#C0392B';

export const MISMATCH_CRITICAL = '#E7615A';
export const MISMATCH_WARNING = '#EDC800';

export const NODE_BORDER = '#DDD';
export const NODE_FILL = WHITE;

export const NODE_MENU = '#1B1B1B';

export const NODE_HEADER_TEXT = '#525252';
export const NODE_HEADER_FILL = '#EBF1F3';
export const NODE_HEADER_EDITABLE = NODE_BORDER;
export const NODE_HEADER_EDITABLE_WARNING = WARNING;
export const NODE_HEADER_WARNING = DANGER;

export const OUTPUT_MISSING = '#C1C1C1';
export const OUTPUT_YES = '#15AF97';
export const OUTPUT_NO = '#E7615A';

export const OUTPUT_COUNT_MISSING_TEXT = '#6E6E6E';
export const OUTPUT_COUNT_TEXT = '#004D67';

export const NODE_CONDITION_MISMATCH_ERROR = '#FCECEB';
export const NODE_CONDITION_MISMATCH_WARNING = '#FBF4CC';

export const OPERATOR_NODE_BORDER = '#C1C1C1';
export const OPERATOR_NODE_TEXT = '#525252';

export const NODE_CONDITION_TEXT = '#000';
export const NODE_CONDITION_EDIT_TEXT = '#046F93';

export const PORT_BORDER = '#004D67';
export const PORT_FILL = WHITE;

export const HIGHLIGHTED = 'rgb(187, 220, 252)';
export const HIGHLIGHTED_LIGHT = '#F3FAFB';
export const HIGHLIGHTED_BORDER = '#40A9FF';

export const ARROW_LABEL_FILL = '#004D67';
export const ARROW_LABEL_TEXT = WHITE;
export const ARROW_LINE = '#004D67';

export const HISTOGRAM_MISSING = '#EBEBEB';
export const HISTOGRAM_BAR = '#004D67';
export const HISTOGRAM_SELECTED_AREA = '#429198';

export const EMPTY_DATA_TEXT = '#A6A6A6';

/**
 * Gets the background color for a node based on the provided graph object.
 *
 * @param _ - Placeholder parameter (not used in the function).
 * @param object - The graph object for which to determine the background color.
 * @returns The background color for the node.
 */
export const getNodeBackgroundColor = (_: any, object: go.GraphObject) => {
  const nodeId = object.part?.key;
  const modelData = object.diagram?.model.modelData as DiagramData;

  return nodeId && nodeId === modelData.highlightedNode ? HIGHLIGHTED : NODE_FILL;
};

/**
 * Gets the border color for a node based on the provided graph object.
 *
 * @param _ - Placeholder parameter (not used in the function).
 * @param object - The graph object for which to determine the border color.
 * @returns The border color for the node.
 */
export const getNodeBorderColor = (_: any, object: go.GraphObject) => {
  const nodeId = object.part?.key;
  const modelData = object.diagram?.model.modelData as DiagramData;

  return nodeId && nodeId === modelData.highlightedNode
    ? HIGHLIGHTED_BORDER
    : NODE_BORDER;
};

/**
 * Gets the border size for a node based on the provided graph object.
 *
 * @param _ - Placeholder parameter (not used in the function).
 * @param object - The GraphObject for which to determine the border size.
 * @returns The border size for the node.
 */
export const getNodeBorderSize = (_: any, object: go.GraphObject) => {
  const nodeId = object.part?.key;
  const modelData = object.diagram?.model.modelData as DiagramData;

  // TODO: replace with 2px for highlighted state and prevent node size changing
  return nodeId && nodeId === modelData.highlightedNode ? 1 : 1;
};
