import { CloseOutlined } from '@ant-design/icons';
import { DiscreteValue } from '@discngine/moosa-models';
import { Space, Switch } from 'antd';
import { OUTPUT_NO } from 'lib/go/colors';
import React, { useCallback } from 'react';
import { useDrag, useDrop, DragSourceMonitor } from 'react-dnd';
import { DndItem } from 'types';

import styles from './shared/PropertyNodeEditPanel.module.less';

const DISCRETE_VALUE_TYPE = 'DISCRETE_VALUE_TYPE';

interface DiscreteConditionValueItemProps {
  item: DiscreteValue;
  index: number;
  disabled: boolean | undefined;
  isDeletable: boolean | undefined;
  onDelete?: () => void;
  onIsCheckedChange: (isChecked: boolean) => void;
  onPositionChange?: (toIndex: number) => void;
}

export const DiscreteConditionValueItem = ({
  index,
  item,
  disabled,
  isDeletable,
  onDelete,
  onIsCheckedChange,
  onPositionChange,
}: DiscreteConditionValueItemProps) => {
  const handleDragEnd = useCallback(
    (
      item: DndItem | undefined,
      monitor: DragSourceMonitor<{ index: number }, { index: number }>
    ): void => {
      const dropResult = monitor.getDropResult();

      if (dropResult) {
        onPositionChange?.(dropResult.index);
      }
    },
    [onPositionChange]
  );

  const [{ opacity }, drag, preview] = useDrag<DndItem, DndItem, { opacity: number }>({
    type: DISCRETE_VALUE_TYPE,
    item: { type: DISCRETE_VALUE_TYPE },
    end: handleDragEnd,
    collect: (monitor: DragSourceMonitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  const [, drop] = useDrop({
    accept: DISCRETE_VALUE_TYPE,
    drop: () => ({ index }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div ref={preview} style={{ opacity, flex: 1 }}>
      <div ref={drop}>
        <div className={styles.discreteValueRow}>
          {onPositionChange && (
            <div ref={drag} className={styles.anchor}>
              <div className={styles.anchorIcon} />
            </div>
          )}

          <span className={styles.value} title={item.value}>
            {item.value}
          </span>

          <Space size={8}>
            <Switch
              checked={item.isSelected}
              checkedChildren="TRUE"
              disabled={disabled}
              unCheckedChildren="FALSE"
              onChange={onIsCheckedChange}
            />

            {onDelete && (
              <div className={styles.deleteIcon}>
                {!disabled && isDeletable && (
                  <CloseOutlined style={{ color: OUTPUT_NO }} onClick={onDelete} />
                )}
              </div>
            )}
          </Space>
        </div>
      </div>
    </div>
  );
};
