import {
  DecisionTreeResult,
  ColumnId,
  DecisionTreeGroupedResult,
  DatasetRowId,
} from '@discngine/moosa-models';
import { groupDecisionTreeResult } from 'calculateResults/calculateDecisionTreeGroups';
import { useCallback } from 'react';

export type GetDecisionTreeGroupedResult = (
  result: DecisionTreeResult
) => DecisionTreeGroupedResult | null;

export const useGetDecisionTreeGroupedResult = (
  getGroup: (rowId: DatasetRowId, groupColumnId: ColumnId) => string | null | undefined,
  groupColumnId: ColumnId | null
): GetDecisionTreeGroupedResult => {
  return useCallback(
    (result: DecisionTreeResult): DecisionTreeGroupedResult | null => {
      if (!groupColumnId) return null;

      return groupDecisionTreeResult(getGroup, result, groupColumnId);
    },
    [getGroup, groupColumnId]
  );
};
