import { rgbToString, IRGBColor } from '@discngine/moosa-common';
import { DiscretePropertyValue } from '@discngine/moosa-models';
import { FC, useCallback, useMemo, useState } from 'react';

import { AvailableColorsPanel } from '../../AvailableColorsPanel/AvailableColorsPanel';
import { useComponentsContext } from '../../ComponentsContext/ComponentsContext';
import chessboard from '../resourses/chessboard.svg';

import styles from './DiscreteTextPropertyItem.module.less';

interface IDiscretePropertyItemProps {
  item: DiscretePropertyValue;
  indexOfSelectedColor: number;
  onColorChange: (index: number, color: IRGBColor | null) => void;
}

export const DiscretePropertyItem: FC<IDiscretePropertyItemProps> = ({
  item,
  indexOfSelectedColor,
  onColorChange,
}) => {
  const { Icons, Popover } = useComponentsContext();

  const [isAvailableColorsPanelVisible, setIsAvailableColorsPanelVisible] =
    useState(false);
  const handleAvailableColorsPanelVisibleChange = useCallback((newVisible: boolean) => {
    setIsAvailableColorsPanelVisible(newVisible);
  }, []);

  const backgroundStyle = useMemo(() => {
    if (item.color) {
      return { background: rgbToString(item.color) };
    } else {
      return { backgroundImage: `url(${chessboard})` };
    }
  }, [item.color]);

  const onChange = useCallback(
    (color: IRGBColor | null) => {
      onColorChange(indexOfSelectedColor, color);
      setIsAvailableColorsPanelVisible(false);
    },
    [indexOfSelectedColor, onColorChange]
  );

  const onRemove = useCallback(
    (indexOfSelectedColor: number) => {
      onColorChange(indexOfSelectedColor, null);
      setIsAvailableColorsPanelVisible(false);
    },
    [onColorChange]
  );

  return (
    <div className={styles.root}>
      <div>{item.value}</div>
      <Popover
        content={
          <AvailableColorsPanel
            indexOfSelectedColor={indexOfSelectedColor}
            isPossibleToRemoveColor={true}
            selectedColor={item.color || null}
            showFullColorsPalette={true}
            onColorChange={onChange}
            onRemoveColor={onRemove}
          />
        }
        open={isAvailableColorsPanelVisible}
        placement="bottomRight"
        trigger="click"
        zIndex={10001}
        onOpenChange={handleAvailableColorsPanelVisibleChange}
      >
        <div className={styles.colorBoxWrap}>
          <div className={styles.colorBox} style={backgroundStyle} />
          <Icons.DownIcon className={styles.arrowDownIcon} />
        </div>
      </Popover>
    </div>
  );
};
