import { FieldType } from '../../../serverModels/IDatasetMetaModel';
import { DesirabilityFunctionType } from '../../../serverModels/IScoringTemplate';
import {
  DesirabilityFunctionParams,
  FunctionRules,
  IScoringFuncProperty,
} from '../scoringTemplate';
import { IColumnMetaInfo } from '../tableInfo';

import { customCurveFunctionRules } from './customCurveFunctionRules';
import { discreteFunctionRules } from './DiscreteFunctionRules';
import { linearFunctionRules } from './linearFunctionRules';
import { logarithmicFunctionRules } from './logarithmicFunctionRules';
import { rectangularFunctionRules } from './rectangularFunctionRules';
import { sigmoidFunctionRules } from './sigmoidFunctionRules';
import { triangularFunctionRules } from './triangularFunctionRules';
import { unitStepFunctionRules } from './unitStepFunctionRules';

export const SCORING_FUNCTIONS: { name: string; type: DesirabilityFunctionType }[] = [
  {
    name: 'Discrete Properties',
    type: DesirabilityFunctionType.discrete,
  },
  {
    name: 'Linear',
    type: DesirabilityFunctionType.linear,
  },
  {
    name: 'Logarithmic',
    type: DesirabilityFunctionType.logarithmic,
  },
  {
    name: 'Rectangular',
    type: DesirabilityFunctionType.rectangular,
  },
  {
    name: 'Triangular',
    type: DesirabilityFunctionType.triangular,
  },
  {
    name: 'Unit Step',
    type: DesirabilityFunctionType.unitStep,
  },
  {
    name: 'Logistic',
    type: DesirabilityFunctionType.sigmoid,
  },
  {
    name: 'Custom Curve',
    type: DesirabilityFunctionType.custom,
  },
];

export const SCORING_FUNCTIONS_RULES: FunctionRules = {
  [DesirabilityFunctionType.linear]: linearFunctionRules,
  [DesirabilityFunctionType.unitStep]: unitStepFunctionRules,
  [DesirabilityFunctionType.triangular]: triangularFunctionRules,
  [DesirabilityFunctionType.rectangular]: rectangularFunctionRules,
  [DesirabilityFunctionType.logarithmic]: logarithmicFunctionRules,
  [DesirabilityFunctionType.sigmoid]: sigmoidFunctionRules,
  [DesirabilityFunctionType.custom]: customCurveFunctionRules,
  [DesirabilityFunctionType.discrete]: discreteFunctionRules,
};

export const DEFAULT_COLORS = [
  '#e4744e',
  '#f2b06e',
  '#f6e652',
  '#98d268',
  '#489556',
  '#51a4aa',
  '#0093dc',
  '#5276bb',
  '#a376c2',
  '#92656e',
  '#ee7d87',
  '#b34454',
  '#bdba6f',
  '#e9f3a3',
  '#bed4a9',
  '#a0dbdd',
  '#c6f0fd',
  '#5041ad',
  '#bd9da4',
  '#828282',
];

const DEFAULT_COLORS_LENGTH = DEFAULT_COLORS.length;

export function* getColor(): Generator<string> {
  let i = 0;

  while (true) {
    yield DEFAULT_COLORS[i++];

    if (i === DEFAULT_COLORS_LENGTH) {
      i = 0;
    }
  }
}
const colorGenerator = getColor();

export function initScoringFunctionsParams(metadata: IColumnMetaInfo) {
  const functionParams: DesirabilityFunctionParams = {
    [DesirabilityFunctionType.linear]: linearFunctionRules.init(metadata),
    [DesirabilityFunctionType.unitStep]: unitStepFunctionRules.init(metadata),
    [DesirabilityFunctionType.triangular]: triangularFunctionRules.init(metadata),
    [DesirabilityFunctionType.rectangular]: rectangularFunctionRules.init(metadata),
    [DesirabilityFunctionType.logarithmic]: logarithmicFunctionRules.init(metadata),
    [DesirabilityFunctionType.sigmoid]: sigmoidFunctionRules.init(metadata),
    [DesirabilityFunctionType.custom]: customCurveFunctionRules.init(metadata),
    [DesirabilityFunctionType.discrete]: discreteFunctionRules.init(metadata),
  };

  return functionParams;
}

export function getDefaultScoringFunction(
  metadata: IColumnMetaInfo
): IScoringFuncProperty {
  const type = metadata.isDiscreteColumn
    ? DesirabilityFunctionType.discrete
    : DesirabilityFunctionType.linear;

  return {
    column: metadata.name,
    color: colorGenerator.next().value,
    isInUse: true,
    weight: 1,
    type,
    functionParams: initScoringFunctionsParams(metadata),
    measurementError: { type: 'none' },
    isDiscreteStringFunction:
      metadata.isDiscreteColumn && metadata.type === FieldType.String,
  };
}
