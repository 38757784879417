export const TEXTINGS = {
  SAVE: {
    title: 'Save decision tree',
    okText: 'Save',
    postfix: '',
  },
  RENAME: {
    title: 'Rename decision tree to',
    okText: 'Rename',
    postfix: '-renamed',
  },
  SAVE_AS_COPY: {
    title: 'Save decision tree as',
    okText: 'Save',
    postfix: '-copy',
  },
  SAVE_AS_VERSION: {
    title: 'Save decision tree as version',
    okText: '',
    postfix: '',
  },
};
