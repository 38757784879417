import { classNames } from '@discngine/moosa-common';
import { ColumnId, DTNodeId } from '@discngine/moosa-models';
import { Space } from 'antd';
import React from 'react';
import { MenuItem } from 'types';

import styles from './NodeMenu.module.less';

interface NodeMenuProps {
  nodeId: DTNodeId;
  propertyId: ColumnId;
  items: MenuItem[];
  position: { x: number; y: number };
  cancel: () => void;
}

export const NodeMenu = ({
  nodeId,
  propertyId,
  items,
  cancel,
  position,
}: NodeMenuProps) => {
  return (
    <div
      className={styles.nodeMenu}
      style={{
        top: `${position.y}px`,
        left: `${position.x}px`,
      }}
      onClick={cancel}
    >
      {items.map(({ label, icon, isEnabled, onClick }, index) => (
        <div
          key={index}
          className={classNames(styles.nodeMenuItem, {
            [styles.nodeMenuItemDisabled]: !isEnabled,
          })}
          onClick={() => isEnabled && onClick(nodeId, propertyId)}
        >
          <Space size={8}>
            {icon}
            {label}
          </Space>
        </div>
      ))}
    </div>
  );
};
