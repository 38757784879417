import { IScoringFuncProperty } from '@discngine/moosa-models';
import {
  DesirabilityFunctionEditor,
  DesirabilityFunctionEditorProps,
} from '@discngine/moosa-shared-components-gen';
import React, { FC } from 'react';

import { AntComponentsProvider } from '../AntComponentsProvider/AntComponentsProvider';

import { DesirabilityFunctionSelect } from './DesirabilityFunctionSelect/DesirabilityFunctionSelect';
import { InfinityListProps } from './types';

type DesirabilityFunctionProps = DesirabilityFunctionEditorProps & {
  onChangeScoringFunction: (desirability: IScoringFuncProperty) => void;
  onSave: (desirability: IScoringFuncProperty, existingId?: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  onSearch: (query: string) => void;
  infinityListProps: InfinityListProps;
};

const DesirabilityFunction: FC<DesirabilityFunctionProps> = React.memo(
  function DesirabilityFunction({
    desirability,
    metadata,
    onParamsChange,
    onAddFunctionPoint,
    onUpdateFunctionPoint,
    onRemoveFunctionPoint,
    onChangeScoringFunction,
    onSave,
    onDelete,
    onSearch,
    onMoveFinish,
    onDiscretePointsOrderChange,
    infinityListProps,
    barsRerender,
  }) {
    return (
      <>
        <DesirabilityFunctionSelect
          desirability={desirability}
          infinityListProps={infinityListProps}
          metadata={metadata}
          onChangeScoringFunction={onChangeScoringFunction}
          onDelete={onDelete}
          onSave={onSave}
          onSearch={onSearch}
        />
        <AntComponentsProvider>
          <DesirabilityFunctionEditor
            barsRerender={barsRerender}
            desirability={desirability}
            metadata={metadata}
            onAddFunctionPoint={onAddFunctionPoint}
            onDiscretePointsOrderChange={onDiscretePointsOrderChange}
            onMoveFinish={onMoveFinish}
            onParamsChange={onParamsChange}
            onRemoveFunctionPoint={onRemoveFunctionPoint}
            onUpdateFunctionPoint={onUpdateFunctionPoint}
          />
        </AntComponentsProvider>
      </>
    );
  }
);

export default DesirabilityFunction;
