import { IDatasetMetaStatistics } from '../serverModels/IDatasetMetaModel';
import { IHistogramBar } from '../uiModels/moosa-store/tableInfo';

export function createHistogramBars(statistics: IDatasetMetaStatistics): IHistogramBar[] {
  const {
    min,
    max,
    histogramStep = 1, // todo temporary fix, wait for BE
  } = statistics;

  return statistics.histogram.map((yVal, index, arr) => {
    const bar: IHistogramBar = {
      x0: min + histogramStep * index,
      x: index === arr.length - 1 ? max : min + histogramStep * (index + 1),
      y0: 0,
      y: arr.length !== 0 ? yVal : yVal || 1, // todo temporary fix, wait for BE
    };

    return bar;
  });
}
