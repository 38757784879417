import {
  Condition,
  DesirabilityFunctionCondition,
  DiscreteCondition,
} from '@discngine/moosa-models';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import React, { FC, useMemo } from 'react';

import { EmptyHistogramNotification } from './EmptyHistogramNotification';
import {
  MARGIN,
  NUM_TICKS_Y,
  SMALL_NUM_TICKS_X,
  SMALL_NUM_TICKS_Y,
  tickFormatter,
  tickLabelProps,
} from './histogramHelpers';
import style from './NumericHistogram.module.less';
import { NumericHistogramInput } from './NumericHistogramInput';
import { NumericSlicedBar } from './NumericSlicedBar';
import { NumericHistogramData } from './types';

type NumericHistogramProps = {
  data: NumericHistogramData;
  width: number;
  height: number;
  condition?: Exclude<Condition, DiscreteCondition | DesirabilityFunctionCondition>;
  onChange?: (condition: Condition) => void;
  isSmall?: boolean; // Reduced view of histogram with reduced number of ticks for X and Y axes
};

export const NumericHistogram: FC<NumericHistogramProps> = React.memo(
  ({ data, height, width, condition, onChange, isSmall = false }) => {
    const yMax = height - MARGIN.bottom;
    const xMax = width - MARGIN.right;

    const yValueMax = useMemo(() => {
      return Math.max(...data.bars.map(({ y }) => y.reduce((sum, y) => sum + y, 0)));
    }, [data]);

    const xRange = useMemo(() => {
      const range = { min: Infinity, max: -Infinity, delta: 0 };

      for (const { x } of data.bars) {
        if (x[0] < range.min) {
          range.min = x[0];
        }

        if (x[1] > range.max) {
          range.max = x[1];
        }
      }

      range.delta = (range.max - range.min) * 0.01 || range.max * 0.01;

      return range;
    }, [data]);

    const yScale = useMemo(() => {
      return scaleLinear({ range: [MARGIN.top, yMax], domain: [yValueMax, 0] });
    }, [yMax, yValueMax]);

    const xScale = useMemo(() => {
      return scaleLinear({
        range: [MARGIN.left, xMax],
        domain: [xRange.min - xRange.delta, xRange.max + xRange.delta],
      });
    }, [xMax, xRange.delta, xRange.max, xRange.min]);

    return (
      <svg
        className={style.plot}
        height={height}
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        {yValueMax <= 0 && <EmptyHistogramNotification height={height} width={width} />}

        {yValueMax > 0 && (
          <Group>
            <Group>
              <NumericSlicedBar
                data={data}
                numericBarGap={isSmall ? 0 : undefined}
                xScale={xScale}
                yScale={yScale}
              />
            </Group>

            <Group left={MARGIN.left}>
              <AxisLeft
                hideZero
                numTicks={isSmall ? SMALL_NUM_TICKS_Y : NUM_TICKS_Y}
                scale={yScale}
              />
            </Group>

            <Group top={yMax}>
              <AxisBottom
                numTicks={isSmall ? SMALL_NUM_TICKS_X : undefined}
                scale={xScale}
                tickFormat={tickFormatter}
                tickLabelProps={tickLabelProps}
              />
            </Group>

            <NumericHistogramInput
              condition={condition}
              dataType={'numeric'}
              height={height}
              width={width}
              xScale={xScale}
              onChange={onChange}
            />
          </Group>
        )}
      </svg>
    );
  }
);
