import { IDiscretePoint } from '@discngine/moosa-models';
import React, { FC, useCallback } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';

import { useComponentsContext } from '../../../../ComponentsContext/ComponentsContext';
import { PlusIcon, WarningFilledIcon, CloseCircleIcon } from '../../../../icons';
import { isValidYValue } from '../../isValidValue';

import styles from './DiscreteValuesPointEditor.module.less';

export type DiscreteValuesPointEditorProps = {
  point: IDiscretePoint;
  onPointChange: (point: IDiscretePoint) => void;
  columnName: string;
  columnType: string;
  onShowModal: (index: number) => void;
  onPointsOrderChange: (
    columnName: string,
    fromPoint: string | number,
    toPoint: string | number
  ) => void;
};

const DND_TYPE = 'PointEditor';

export interface DndItem {
  x: number | string;
  y: number;
  originalPointIndex: number;
}

export interface DropResult {
  dropEffect: string;
  point: DndItem;
}

export const DiscreteValuesPointEditor: FC<DiscreteValuesPointEditorProps> = React.memo(
  function DiscreteValuesPointEditor({
    point,
    onPointChange,
    columnName,
    columnType,
    onShowModal,
    onPointsOrderChange,
  }) {
    const { Col, Row, ValueInput } = useComponentsContext();

    const handleYChange = useCallback(
      (val: string | number) => {
        const value = val ? Number(val) : NaN;

        if (isValidYValue(value)) {
          onPointChange({
            id: point.id,
            x: point.x,
            y: value,
            originalPointIndex: point.originalPointIndex,
            count: point.count,
            mark: true,
            isAddedByUser: point.isAddedByUser,
          });
        }
      },
      [
        onPointChange,
        point.count,
        point.id,
        point.isAddedByUser,
        point.originalPointIndex,
        point.x,
      ]
    );

    const [{ opacity }, drag, preview] = useDrag({
      type: DND_TYPE,
      item: { x: point.x, y: point.y, originalPointIndex: point.originalPointIndex! },
      end: (draggedItem: DndItem, monitor: DragSourceMonitor<any, DropResult>) => {
        const dropResult = monitor.getDropResult();

        if (draggedItem && dropResult) {
          onPointsOrderChange(columnName, draggedItem.x, dropResult.point.x);
        }
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    });

    const [, drop] = useDrop({
      accept: DND_TYPE,
      drop: () => ({ point }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });

    const onAddPointToFunction = () => {
      onPointChange({
        id: point.id,
        x: point.x,
        y: 0,
        originalPointIndex: point.originalPointIndex,
        count: point.count,
        mark: true,
      });
    };

    return (
      <>
        <Col ref={preview} style={{ opacity }}>
          <Row ref={drop} className={styles.point}>
            <Col span={12}>
              <div className={styles.value}>
                {columnType === 'String' && <div ref={drag} className={styles.anchor} />}
                <div>{point.x}</div>
                {point.isNotInFunction && (
                  <WarningFilledIcon
                    className={styles.warning}
                    title="This value is present in the dataset but absent in the desirability function"
                  />
                )}
              </div>
            </Col>
            <Col span={12}>
              <div
                className={`${styles.inputWrap} ${
                  point.isNotInFunction && styles.notInFunction
                }`}
              >
                <ValueInput
                  disabled={point.isNotInFunction}
                  value={point.y}
                  onValueChange={handleYChange}
                />
                {point.isAddedByUser && (
                  <CloseCircleIcon
                    className={styles.close}
                    onClick={() => onShowModal(point.originalPointIndex!)}
                  />
                )}
                {point.isNotInFunction && (
                  <PlusIcon
                    className={styles.addToFunction}
                    onClick={onAddPointToFunction}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
);
