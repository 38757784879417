import { DTNodeType } from '@discngine/moosa-models';
import go from 'gojs';
import { v4 as uuid } from 'uuid';

import { DiagramState } from '../../types';

import { AutoLayout, ManualLayout } from './layouts';
import { andNodeTemplate } from './templates/and';
import { arrowTemplate } from './templates/arrow';
import { atLeastNNodeTemplate } from './templates/atLeastN';
import { atMostXNodeTemplate } from './templates/atMostX';
import { chartNodeTemplate } from './templates/chart';
import { dateNodeTemplate } from './templates/date';
import { groupNodeTemplate } from './templates/group';
import { orNodeTemplate } from './templates/or';
import { propertyNodeTemplate } from './templates/property';
import { structureNodeTemplate } from './templates/structure';
import { updateModelData } from './utils';

/**
 * Checks whether a link can be established between two nodes based on being in a group.
 *
 * @param fromNode - The source node of the link.
 * @param fromPort - The port of the source node where the link originates.
 * @param toNode - The target node of the link.
 * @param toPort - The port of the target node where the link ends.
 * @param link - The link being validated.
 * @returns - Returns true if the link can be established, false otherwise.
 */
const linkValidation = (
  fromNode: go.Node,
  fromPort: go.GraphObject,
  toNode: go.Node,
  toPort: go.GraphObject,
  link: go.Link
): boolean => {
  return fromNode.data.group === toNode.data.group;
};

/**
 * Diagram initialization method, which is passed to the ReactDiagram component.
 * This method is responsible for making the diagram and initializing the model, any templates,
 * and maybe doing other initialization tasks like customizing tools.
 * The model's data should not be set here, as the ReactDiagram component handles that.
 *
 * @param diagramState - The current diagram state.
 * @param licenseKey - GoJS license.
 * @returns A newly created GoJS Diagram object.
 */
export const initDiagram = (
  diagramState: DiagramState,
  licenseKey?: string
): go.Diagram => {
  const GRID_SIZE = 40;
  const DOT_SIZE = 2;

  if (licenseKey) {
    go.Diagram.licenseKey = licenseKey;
  }

  const diagram = new go.Diagram({
    layout: diagramState.layout === 'Auto' ? AutoLayout : ManualLayout,
    initialContentAlignment: go.Spot.Top,
    initialAutoScale: go.Diagram.UniformToFill,
    allowRelink: true,
    scrollMode: go.Diagram.InfiniteScroll,
    'undoManager.isEnabled': false,
    'toolManager.hoverDelay': 10,
    'toolManager.toolTipDuration': 10000,
    scale: 0.75,
    grid: new go.Panel('Grid', {
      background: '#FFF',
      gridCellSize: new go.Size(GRID_SIZE, GRID_SIZE),
    }).add(
      new go.Shape('LineH', {
        stroke: '#A1A2A3',
        strokeCap: 'round',
        strokeJoin: 'round',
        strokeWidth: DOT_SIZE,
        strokeDashArray: [0, GRID_SIZE - DOT_SIZE, DOT_SIZE, 0],
      })
    ),
  });

  diagram.commandHandler.pasteSelection = function () {
    const selection = diagram.selection.toArray()[0];
    const needSave = diagram.selection.toArray().length === 1;

    if (needSave) {
      updateModelData(diagram, { selectedNode: selection.data });
    }
    go.CommandHandler.prototype.pasteSelection.call(diagram.commandHandler);
  };

  diagram.animationManager.initialAnimationStyle = go.AnimationManager.None;
  diagram.addDiagramListener('InitialAnimationStarting', (event) => {
    const animation = event.subject.defaultAnimation;

    animation.easing = go.Animation.EaseOutExpo;
    animation.duration = 500;
    animation.add(event.diagram, 'scale', 0.75 * diagram.scale, diagram.scale);
    animation.add(event.diagram, 'opacity', 0, 1);
  });

  diagram.nodeTemplateMap.addAll([
    {
      key: DTNodeType.Property,
      value: propertyNodeTemplate({}),
    },
    {
      key: DTNodeType.Date,
      value: dateNodeTemplate({}),
    },
    {
      key: DTNodeType.StructureSearch,
      value: structureNodeTemplate({}),
    },
    { key: DTNodeType.And, value: andNodeTemplate({}) },
    { key: DTNodeType.Or, value: orNodeTemplate({}) },
    { key: DTNodeType.ALN, value: atLeastNNodeTemplate({}) },
    { key: DTNodeType.AMX, value: atMostXNodeTemplate({}) },
    { key: DTNodeType.Chart, value: chartNodeTemplate({}) },
  ]);

  diagram.groupTemplateMap.add('', groupNodeTemplate);
  diagram.linkTemplateMap.add('', arrowTemplate);

  diagram.toolManager.linkingTool.linkValidation = linkValidation;

  diagram.model = new go.GraphLinksModel(undefined, undefined, {
    linkFromPortIdProperty: 'fromPort',
    linkToPortIdProperty: 'toPort',
    linkKeyProperty: 'key',
    makeUniqueKeyFunction: () => uuid(),
    makeUniqueLinkKeyFunction: () => uuid(),
  });

  return diagram;
};
