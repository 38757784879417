import { IMoosaDataService, IScoringTemplate } from '@discngine/moosa-models';
import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import { Context, ReactNode, useMemo } from 'react';
import {
  createDispatchHook,
  createSelectorHook,
  ReactReduxContextValue,
  TypedUseSelectorHook,
} from 'react-redux';

import {
  VersionisedListSlice,
  buildHooks,
} from '@discngine/moosa-store/createVersionisedListSlice';

import { getScoringTemplatesDataProvider } from './scoringTemplatesSlice';
import { GetListParams } from './types';

export type ScoringTemplatesContextType<RootState> = {
  useDispatch: () => ThunkDispatch<RootState, unknown, AnyAction> & Dispatch;
  useSelector: TypedUseSelectorHook<RootState>;
  hooks: ReturnType<
    typeof buildHooks<string, RootState, IScoringTemplate, GetListParams>
  >;
};

type Props<RootState extends { [K in keyof RootState]: RootState[K] }> = {
  SliceContext: Context<ScoringTemplatesContextType<RootState> | null>;
  storeContext: Context<ReactReduxContextValue<RootState[keyof RootState]> | null>;
  slice: VersionisedListSlice<string, IScoringTemplate, GetListParams>;
  dataService: IMoosaDataService;
  children?: ReactNode | undefined;
};

export const ScoringTemplatesWithVersionsSliceProvider = <
  RootState extends { [K in keyof RootState]: RootState[K] }
>(
  props: Props<RootState>
) => {
  const { SliceContext, storeContext, slice, dataService, children } = props;

  // must be executed in the first rendering cycle
  useMemo(() => {
    slice.configureDataProvider(getScoringTemplatesDataProvider(dataService));
  }, [dataService, slice]);

  const value = useMemo(() => {
    const useDispatch = createDispatchHook(storeContext);
    const useSelector = createSelectorHook(storeContext);
    const hooks = buildHooks({ slice, useSelector, useDispatch });

    return { hooks, useDispatch, useSelector };
  }, [slice, storeContext]);

  return (
    !!value && <SliceContext.Provider value={value}>{children}</SliceContext.Provider>
  );
};
