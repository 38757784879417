import { DTNodeId, DTNodeType } from '@discngine/moosa-models';
import go from 'gojs';
import { DTNodeOutputPortType } from 'types';

import { NODES_GAP } from '../../constants';

type LinksPortsIndexes = Map<string, number>;

const portsIndexes: Record<DTNodeOutputPortType, number> = {
  no: 0,
  missingValues: 1,
  yes: 2,
  combine: 0,
};

const updateParentPortsIndexes = (
  link: go.Link,
  parentNodes: LinksPortsIndexes
): LinksPortsIndexes => {
  if (!link.fromNode) return parentNodes;

  const nodeId = String(link.fromNode.key);
  const portIndex = portsIndexes[link.fromPortId as DTNodeOutputPortType];
  const minPortIndex = parentNodes.get(nodeId) || Infinity;

  parentNodes.set(nodeId, Math.min(portIndex, minPortIndex));

  return parentNodes;
};

const compareNodeOrderByParentPorts = (
  link: go.Link,
  parentNodes: LinksPortsIndexes
): 1 | -1 | 0 => {
  const { fromNode, fromPortId } = link;

  if (!fromNode || !fromPortId) return 0;

  const nodeId = String(fromNode.key);
  const portIndex = portsIndexes[fromPortId as DTNodeOutputPortType];
  const minPortIndex = parentNodes.get(nodeId)!;

  if (portIndex === undefined || minPortIndex === undefined) return 0;

  return portIndex < minPortIndex ? 1 : portIndex > minPortIndex ? -1 : 0;
};

export const LayeredLayout = () => {
  return new go.LayeredDigraphLayout({
    direction: 90,
    layerSpacing: NODES_GAP,
    columnSpacing: NODES_GAP,
    alignOption: go.LayeredDigraphLayout.AlignAll,
    layeringOption: go.LayeredDigraphLayout.LayerLongestPathSink,
    aggressiveOption: go.LayeredDigraphLayout.AggressiveNone,
    initializeOption: go.LayeredDigraphLayout.InitDepthFirstIn,
  });
};

export const TreeLayout = () => {
  return new go.TreeLayout({
    angle: 90,
    arrangementSpacing: new go.Size(NODES_GAP, NODES_GAP),
    arrangement: go.TreeLayout.ArrangementHorizontal,
    alignment: go.TreeLayout.AlignmentCenterSubtrees,
    layerSpacing: NODES_GAP,
    nodeSpacing: NODES_GAP,
    layerStyle: go.TreeLayout.LayerSiblings,
    sorting: go.TreeLayout.SortingAscending,
    comparer: (vertexA, vertexB) => {
      if (vertexA.node?.category === DTNodeType.Group) return -1;

      const incomingA = vertexA.node?.findLinksInto();
      const incomingB = vertexB.node?.findLinksInto();

      let parentNodes: Map<DTNodeId, number> = new Map();

      if (incomingA && incomingB) {
        // Calculate minimum port indexes for all parent nodes
        incomingA.each((link) => {
          parentNodes = updateParentPortsIndexes(link, parentNodes);
        });

        // Compare port indexes for links to common parent nodes
        while (incomingB.next()) {
          const link = incomingB.value;

          const order = compareNodeOrderByParentPorts(link, parentNodes);

          if (order !== 0) {
            return order;
          }
        }
      }

      return 0;
    },
  });
};

export const AutoLayout = TreeLayout();
export const ManualLayout = new go.Layout();
