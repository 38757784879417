import { type InputHTMLAttributes, JSXElementConstructor, ReactNode, Ref } from 'react';
import * as React from 'react';

import { ValueInputProps, ValueInputSizeType } from '../ValueInput/ValueInputProps';

export interface IComponentsContext {
  Input: JSXElementConstructor<InputProps>;
  ValueInput: JSXElementConstructor<ValueInputProps>;
  Select: JSXElementConstructor<SelectProps>;
  Tooltip: JSXElementConstructor<TooltipProps>;
  Popover: JSXElementConstructor<PopoverProps>;
  Button: JSXElementConstructor<ButtonProps>;
  Row: JSXElementConstructor<RowProps>;
  Col: JSXElementConstructor<ColProps>;
  Modal: JSXElementConstructor<ModalProps>;
  Icons: { [key in ComponentContextIconsEnum]: JSXElementConstructor<IconProps> };
}

export interface InputProps {
  addonAfter?: ReactNode;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  size?: ValueInputSizeType;
  type?: 'number' | 'text';
  value: InputHTMLAttributes<HTMLInputElement>['value'];
  onBlur?: InputHTMLAttributes<HTMLInputElement>['onBlur'];
  onChange?: InputHTMLAttributes<HTMLInputElement>['onChange'];
}

export interface SelectProps {
  className?: string;
  children?: ReactNode;
}

// from AntD
export type TooltipPlacement =
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'leftTop'
  | 'leftBottom'
  | 'rightTop'
  | 'rightBottom';

export interface TooltipProps {
  title?: string;
  children?: ReactNode;
  placement?: TooltipPlacement;
}

export type ActionType = 'hover' | 'focus' | 'click' | 'contextMenu';

export interface PopoverProps {
  children?: ReactNode;
  open?: boolean;
  placement?: TooltipPlacement;
  onOpenChange?: (open: boolean) => void;
  trigger?: ActionType;
  content?: ReactNode;
  zIndex?: number;
}

export interface ButtonProps {
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  onClick?: InputHTMLAttributes<HTMLButtonElement>['onClick'];
}

export interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  ref?: Ref<HTMLDivElement>;
  gutter?: number | [number, number];
  justify?:
    | 'start'
    | 'end'
    | 'center'
    | 'space-around'
    | 'space-between'
    | 'space-evenly';
  wrap?: boolean;
  align?: 'top' | 'middle' | 'bottom' | 'stretch';
}
export interface ColProps extends React.HTMLAttributes<HTMLDivElement> {
  ref?: Ref<HTMLDivElement>;
  span?: number | string;
}

export interface ModalProps {
  className?: string;
  children?: ReactNode;
  centered?: boolean;
  footer?: ReactNode;
  closeIcon?: ReactNode;
  open?: boolean;
  width?: number;
  onOk?: InputHTMLAttributes<HTMLButtonElement>['onClick'];
  onCancel?: InputHTMLAttributes<HTMLButtonElement>['onClick'];
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
}

export enum ComponentContextIconsEnum {
  DeleteIcon = 'DeleteIcon',
  CheckIcon = 'CheckIcon',
  DownIcon = 'DownIcon',
}

export interface IconProps {
  className?: string;
  onClick?: InputHTMLAttributes<SVGSVGElement | HTMLSpanElement>['onClick'];
}
