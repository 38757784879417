import {
  ColumnId,
  FieldType,
  IColumnMetaInfo,
  PortGroupingType,
} from '@discngine/moosa-models';
import {
  selectDecisionTreeDefaultPortGrouping,
  selectDecisionTreeGroupColumnId,
  setDecisionTreeGroupColumnId,
  setDecisionTreeDefaultPortGrouping,
  selectDecisionTreeDateColumnId,
  selectDecisionTreeDateFormat,
  setDecisionTreeDateColumnId,
  setDecisionTreeDateFormat,
} from '@discngine/moosa-store/decisionTreeConfig';
import { selectTableColumnNames } from '@discngine/moosa-store/tableConfig';
import { selectTableColumnsMap } from '@discngine/moosa-store/tableInfo';
import { Input, Select } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './DecisionTreeProperties.module.less';

export const DecisionTreeProperties = () => {
  const dispatch = useDispatch();

  const groupColumnId = useSelector(selectDecisionTreeGroupColumnId);
  const defaultPortGrouping = useSelector(selectDecisionTreeDefaultPortGrouping);
  const dateColumnId = useSelector(selectDecisionTreeDateColumnId);
  const dateFormat = useSelector(selectDecisionTreeDateFormat);

  const [inputDateFormat, setInputDateFormat] = useState(dateFormat);

  useEffect(() => {
    setInputDateFormat(dateFormat);
  }, [dateFormat]);

  const updateDateFormat = useCallback(() => {
    const format = (inputDateFormat ?? '').trim();

    dispatch(setDecisionTreeDateFormat(format.length ? format : undefined));
  }, [dispatch, inputDateFormat]);

  const columnNames = useSelector(selectTableColumnNames);
  const columnMeta: Record<ColumnId, IColumnMetaInfo> =
    useSelector(selectTableColumnsMap);

  const columns = useMemo(() => {
    const columns = [];

    for (const columnId in columnMeta) {
      const { type, isDiscreteColumn } = columnMeta[columnId];

      if (isDiscreteColumn && (type === FieldType.String || type === FieldType.Number)) {
        columns.push({
          value: columnId,
          label: columnNames[columnId] ?? columnId,
        });
      }
    }

    return columns;
  }, [columnMeta, columnNames]);

  const potentialDateColumns = useMemo(() => {
    const columns = [];

    for (const columnId in columnMeta) {
      const { type } = columnMeta[columnId];

      if (
        type === FieldType.String ||
        type === FieldType.Number ||
        type === FieldType.Date
      ) {
        columns.push({
          value: columnId,
          label: columnNames[columnId] ?? columnId,
        });
      }
    }

    return columns;
  }, [columnMeta, columnNames]);

  const portGroupingTypes: { value: PortGroupingType; label: string }[] = [
    { value: PortGroupingType.Regular, label: 'No grouping' },
    { value: PortGroupingType.FalseMissing, label: 'False + Missing' },
    { value: PortGroupingType.TrueMissing, label: 'True + Missing' },
    { value: PortGroupingType.HideMissing, label: 'Hide missing values' },
  ];

  return (
    <>
      <div>
        <h3 className={styles.title}>Group Column</h3>

        <Select
          allowClear={true}
          options={columns}
          placeholder="Column with groups"
          value={groupColumnId}
          onClear={() => dispatch(setDecisionTreeGroupColumnId(null))}
          onSelect={(value) => dispatch(setDecisionTreeGroupColumnId(value))}
        />
      </div>

      <div style={{ marginTop: '20px' }}>
        <h3 className={styles.title}>Default port grouping</h3>

        <Select
          allowClear={false}
          placeholder="Port grouping"
          value={defaultPortGrouping}
          onSelect={(value) => dispatch(setDecisionTreeDefaultPortGrouping(value))}
        >
          {portGroupingTypes.map(({ value, label }, index) => (
            <Select.Option key={index} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </div>

      <div style={{ marginTop: '20px' }}>
        <h3 className={styles.title}>Date type column</h3>

        <Select
          allowClear={false}
          placeholder="Date column"
          value={dateColumnId}
          onSelect={(value) => dispatch(setDecisionTreeDateColumnId(value))}
        >
          {potentialDateColumns.map(({ value, label }, index) => (
            <Select.Option key={index} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </div>

      <div style={{ marginTop: '10px' }}>
        <Input
          className={styles.field}
          placeholder="Date Format"
          value={inputDateFormat}
          onBlur={updateDateFormat}
          onChange={(event) => {
            setInputDateFormat(event.target.value);
          }}
        />
        <a
          href="https://day.js.org/docs/en/parse/string-format"
          rel="noreferrer"
          target="_blank"
        >
          date format description
        </a>
      </div>
    </>
  );
};
