import {
  ColumnId,
  IScoringTemplate,
  ITableDataRow,
  useMoosaDataContext,
} from '@discngine/moosa-models';
import { PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { IScoringTemplateState } from '@discngine/moosa-store/scoringTemplate';

import { selectTablePage, selectTablePageSize } from './tableData.selectors';
import { fetchDataWithScores, fetchScoredDataset } from './tableData.thunk';

export function getCellValue(row: ITableDataRow, colId: ColumnId) {
  return row ? row[colId] : undefined;
}

const DEBOUNCE_DELAY_MS = 500;

const debouncedLoad = debounce(
  (dispatch, getTemplate, getDataWithScore, page, pageSize, getComparisonTemplate) =>
    dispatch(
      fetchDataWithScores(
        getTemplate(),
        getDataWithScore,
        page,
        pageSize,
        getComparisonTemplate()
      )
    ),
  DEBOUNCE_DELAY_MS
);

export function useUpdateTableAfterAction(
  getTemplate: () => IScoringTemplateState,
  getComparisonTemplate: () => IScoringTemplate | undefined
) {
  const dispatch = useDispatch<any>();
  const dataService = useMoosaDataContext();
  const page = useSelector(selectTablePage);
  const pageSize = useSelector(selectTablePageSize);

  return useCallback(
    (...actions: (PayloadAction<any> | ThunkAction<any, any, any, any>)[]) => {
      if (actions.length === 1) {
        dispatch(actions[0]);
      } else if (actions.length) {
        batch(() => actions.forEach(dispatch));
      }

      debouncedLoad(
        dispatch,
        getTemplate,
        dataService.getDataWithScore,
        page,
        pageSize,
        getComparisonTemplate
      );
    },
    [
      dispatch,
      getTemplate,
      dataService.getDataWithScore,
      page,
      pageSize,
      getComparisonTemplate,
    ]
  );
}

export function useFetchScoredDataset(getTemplate: () => IScoringTemplateState) {
  const dispatch = useDispatch<any>();
  const dataService = useMoosaDataContext();

  return useCallback(
    (...actions: (PayloadAction<any> | ThunkAction<any, any, any, any>)[]) => {
      if (actions.length === 1) {
        dispatch(actions[0]);
      } else if (actions.length) {
        batch(() => actions.forEach(dispatch));
      }

      dispatch(fetchScoredDataset(getTemplate(), dataService.getDataWithScore));
    },
    [dispatch, getTemplate, dataService]
  );
}

export function useFetchTableFirstTime(template: IScoringTemplateState) {
  const dispatch = useDispatch<any>();
  const dataService = useMoosaDataContext();
  const page = useSelector(selectTablePage);
  const pageSize = useSelector(selectTablePageSize);

  return useCallback(() => {
    dispatch(fetchDataWithScores(template, dataService.getDataWithScore, page, pageSize));
  }, [dispatch, template, page, pageSize, dataService]);
}
