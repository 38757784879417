import { DeleteFilled, FileTextFilled } from '@ant-design/icons';
import { CheckCircleOutlined } from '@ant-design/icons/lib';
import { fullDateToGBFormat, humanFileSize } from '@discngine/moosa-common';
import { IDatasetMetaModel } from '@discngine/moosa-models';
import { Button, notification, Table, Typography } from 'antd';
import { TableRef } from 'antd/es/table';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import styles from './DatasetTable.module.less';

const { Text } = Typography;

interface DatasetProps {
  data: any[];
  openDataset: Function;
  className: string;
  onRowDelete: (rowId: string) => Promise<void>;
  onRowRestore?: (rowId: string) => void;
  loading?: boolean;
  onLoadNext?: () => void;
}

const DatasetTable: React.FC<DatasetProps> = ({
  data,
  openDataset,
  className,
  onRowDelete,
  onRowRestore,
  loading,
  onLoadNext,
}) => {
  const restore = useCallback(
    async (datasetId: string) => {
      try {
        onRowRestore?.(datasetId);
      } catch (err) {
        console.error(err);
        notification.error({
          message: `Could not restore dataset`,
        });
      }
    },
    [onRowRestore]
  );

  const deleteDataset = useCallback(
    async (datasetId: string) => {
      try {
        const itemToDelete = data.find((item) => item._id === datasetId);

        await onRowDelete(datasetId);

        notification.info({
          icon: <CheckCircleOutlined style={{ color: '#01579b' }} />,
          message: (
            <div className={styles.popup}>
              Dataset <b> {itemToDelete?.fileName}</b> is deleted.
              {onRowRestore && <br />}
              {onRowRestore && 'You can restore it.'}
            </div>
          ),
          ...(onRowRestore && {
            btn: (
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  restore(datasetId);
                  notification.destroy(datasetId);
                }}
              >
                Restore file
              </Button>
            ),
          }),
          key: datasetId,
          duration: 10, // 10 seconds - business requirement
          className: styles.notification,
        });
      } catch (err) {
        console.error(err);
        notification.error({
          message: `Dataset was not deleted.`,
        });
      }
    },
    [data, onRowDelete, onRowRestore, restore]
  );

  console.info(data);

  const onRow = useCallback(
    (record: IDatasetMetaModel) => {
      return {
        onClick: () => {
          console.info('openDataset', record._id);
          openDataset(record._id);
        }, // click row
      };
    },
    [openDataset]
  );

  const rowClassName = useCallback((record: any) => {
    if (record.isUploaded) {
      return styles.uploadedRow;
    }

    if (record.errorMessage) {
      return styles.errorRow;
    }

    return styles.tableRow;
  }, []);

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'fileName',
        key: 'fileName',
        width: '250px',
        render: (text: string) => (
          <div>
            <FileTextFilled className={styles.iconFile} />
            <span className={styles.fileName} title={text}>
              {text}
            </span>
          </div>
        ),
      },
      {
        title: 'Name',
        key: 'datasetContent',
        width: '320px',
        render: (text: string, record: IDatasetMetaModel) => (
          <Text className={styles.statisticData} type="secondary">
            {`${record.fields.length} Columns / ${record.rowCount} Rows / ${record.missingValues} missing values`}
          </Text>
        ),
      },
      {
        title: 'Buttons',
        key: 'buttons',
        width: '250px',
        render: (record: IDatasetMetaModel) => {
          if (!record.scoringTemplateId) {
            return '';
          }

          return (
            <span className={styles.scoringTemplateIndicator}>
              <CheckCircleOutlined className={styles.scoringTemplateIndicatorIcon} />
              associated scoring profile
            </span>
          );
        },
      },
      {
        title: 'TemplateName',
        key: 'templateName',
        width: '250px',
        render: (record: IDatasetMetaModel) => {
          return record.scoringTemplateName;
        },
      },
      {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        width: '170px',
        render: (text: string, record: IDatasetMetaModel) =>
          humanFileSize(record.fileSize),
      },
      {
        title: 'Modified',
        dataIndex: 'updatedAt',
        width: '170px',
        key: 'updatedAt',
        render: (text: string) => {
          const date = new Date(text);

          return fullDateToGBFormat(date);
        },
      },
      {
        title: '',
        render: (record: IDatasetMetaModel) => (
          <DeleteFilled
            className={styles.iconDelete}
            onClick={(event) => {
              event.stopPropagation();
              deleteDataset(record._id);
            }}
          />
        ),
        width: '64px',
        key: 'delete',
      },
    ],
    [deleteDataset]
  );

  const tableRef = useRef<TableRef>(null);

  useEffect(() => {
    const tableRoot = tableRef.current?.nativeElement;
    const tableContent = document.querySelector('.ant-table');

    if (!tableRoot || !tableContent) {
      return;
    }

    const listener = () => {
      const contentHeight = tableContent.clientHeight;

      const threshold = contentHeight / 3;

      if (tableRoot.scrollTop + tableRoot.clientHeight >= contentHeight - threshold) {
        onLoadNext?.();
      }
    };

    tableRoot.addEventListener('scroll', listener);

    return () => tableRoot.removeEventListener('scroll', listener);
  }, [onLoadNext]);

  return (
    <Table
      ref={tableRef}
      className={className}
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={false}
      rowClassName={rowClassName}
      rowKey={'_id'}
      showHeader={false}
      onRow={onRow}
    />
  );
};

export default DatasetTable;
