import { IScore, ScoringFunction } from '@discngine/moosa-models';
import { Popover } from 'antd';
import { FC, useMemo } from 'react';

import { FactorsPopover } from './FactorsPopover';
import styles from './FactorsRenderer.module.less';
import { IFactorBar } from './IFactorBar';

type FactorsRendererProps = {
  columnIds: string[];
  columnIndices: Record<string, number>;
  order: string[];
  score: IScore;
  scoringFunction: ScoringFunction;
  isComparisonColumn: boolean;
  onChangeColumnColor: (column: string, color: string) => void;
};

export const FactorsRenderer: FC<FactorsRendererProps> = function FactorsRenderer({
  columnIds,
  columnIndices,
  order,
  score,
  scoringFunction,
  isComparisonColumn,
  onChangeColumnColor,
}) {
  const factorMax = useMemo(
    () =>
      Math.max(
        ...Object.values(score.factors).map((value) =>
          value == null ? -Infinity : value
        )
      ),
    [score]
  );

  const barChart: IFactorBar[] = useMemo(
    () =>
      order
        .filter((col) => columnIds.includes(col))
        .filter((col) => (isComparisonColumn ? true : scoringFunction[col].isInUse))
        .map((col) => columnIndices[col])
        .map((index) => {
          const factor = score && score.factors[index];
          const colId = columnIds[Number(index)];

          return {
            colId,
            color: scoringFunction[colId].color,
            height:
              factor && factorMax
                ? `${factor ? Math.ceil((factor / factorMax) * 100) : 0}%`
                : '0',
          };
        }),
    [
      order,
      columnIds,
      isComparisonColumn,
      scoringFunction,
      columnIndices,
      score,
      factorMax,
    ]
  );

  return (
    <Popover
      content={
        <FactorsPopover
          barChart={barChart}
          isPossibleToChangeColor={!isComparisonColumn}
          onChangeColumnColor={onChangeColumnColor}
        />
      }
      overlayClassName={styles.overlay}
      placement="bottom"
    >
      <div className={styles.factors}>
        {barChart.map(({ colId, color, height }) => (
          <div
            key={colId}
            className={styles.factor}
            style={{
              backgroundColor: color,
              height: height,
            }}
          />
        ))}
      </div>
    </Popover>
  );
};
