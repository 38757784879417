import {
  Condition,
  ConditionType,
  DateCondition,
  DecisionTreeAndNode,
  DecisionTreeAtLeastNNode,
  DecisionTreeAtMostXNode,
  DecisionTreeChartNode,
  DecisionTreeNode,
  DecisionTreeOrNode,
  DecisionTreePropertyNode,
  DesirabilityFunctionCondition,
  DTNodeType,
  DTOperation,
  FieldType,
  getDefaultScoringFunction,
  IColumnMetaInfo,
  ITextCategories,
  PortGroupingType,
  RangeCondition,
  SimpleCondition,
} from '@discngine/moosa-models';
import sortBy from 'lodash/sortBy';
import { v4 as uuid } from 'uuid';

import { ConditionOption, PropertyNodeSettings } from './types';

export const getDefaultDateConditions = (
  metaData: IColumnMetaInfo,
  type?: ConditionType,
  previous?: Condition | null
): DateCondition | null => {
  const condition: SimpleCondition = {
    type: ConditionType.Simple,
    operation: DTOperation.Greater,
    threshold: Date.now(),
  };

  return condition;
};

export const getDefaultConditionSettings = (
  metaData: IColumnMetaInfo,
  type?: ConditionType,
  previous?: Condition | null
): Condition | null => {
  let conditionType = type;

  if (!conditionType) {
    if (metaData.isDiscreteColumn) conditionType = ConditionType.Discrete;
    else if (metaData.type === FieldType.Number) conditionType = ConditionType.Simple;
  }

  if (!conditionType) return null;

  switch (conditionType) {
    case ConditionType.Simple: {
      const condition: SimpleCondition = {
        type: conditionType,
        operation: DTOperation.Greater,
        threshold: metaData?.statistics
          ? (metaData.statistics.max + metaData.statistics.min) / 2
          : 0,
      };

      if (previous && previous.type === ConditionType.Range) {
        condition.operation =
          previous.min.operation === DTOperation.Less
            ? DTOperation.Greater
            : DTOperation.GreaterEqual;
        condition.threshold = previous.min.threshold;
      }

      return condition;
    }

    case ConditionType.Range: {
      const condition: RangeCondition = {
        type: conditionType,
        min: {
          operation: DTOperation.Less,
          threshold: metaData?.statistics?.min || 0,
        },
        max: {
          operation: DTOperation.Less,
          threshold: metaData?.statistics?.max || 0,
        },
      };

      if (previous && previous.type === ConditionType.Simple) {
        if (
          previous.operation === DTOperation.Less ||
          previous.operation === DTOperation.LessEqual
        ) {
          condition.max = {
            operation: previous.operation,
            threshold: previous.threshold,
          };
        } else {
          condition.min = {
            operation:
              previous.operation === DTOperation.Greater
                ? DTOperation.Less
                : DTOperation.LessEqual,
            threshold: previous.threshold,
          };
        }
      }

      return condition;
    }

    case ConditionType.Discrete:
      const textValues =
        metaData?.type === FieldType.Number
          ? sortBy(metaData?.statistics?.textCategories, ({ value }) => Number(value))
          : metaData?.statistics?.textCategories || [];

      return {
        type: conditionType,
        values: textValues.map((textValue: ITextCategories) => ({
          value: String(textValue.value),
          isSelected: false,
        })),
      };

    case ConditionType.DesirabilityFunction:
      if (!metaData) return null;

      const condition: DesirabilityFunctionCondition = {
        type: conditionType,
        desirability: getDefaultScoringFunction(metaData),
        operation: DTOperation.Greater,
        threshold: 0.7,
      };

      return condition;

    default:
      ((x: never) => {
        throw new Error('Unexpected condition type');
      })(conditionType);
  }
};

const defaultNodeSettings: PropertyNodeSettings = {
  id: '',
  name: '',
  condition: null,
  portGroupingType: PortGroupingType.Regular,
};

export const getConditionSettings = (
  condition: DecisionTreePropertyNode['condition']
): PropertyNodeSettings['condition'] => {
  if (!condition) {
    return null;
  }

  if (condition.type === ConditionType.Simple) {
    return {
      type: ConditionType.Simple,
      threshold: condition.threshold || 0,
      operation: condition.operation || DTOperation.Greater,
    };
  }

  if (condition.type === ConditionType.Range) {
    return {
      type: ConditionType.Range,
      min: {
        threshold: condition.min.threshold || 0,
        operation: condition.min.operation || DTOperation.Less,
      },
      max: {
        threshold: condition.max.threshold || 0,
        operation: condition.max.operation || DTOperation.Less,
      },
    };
  }

  if (condition.type === ConditionType.DesirabilityFunction) {
    return {
      type: ConditionType.DesirabilityFunction,
      desirability: structuredClone(condition.desirability),
      operation: condition.operation,
      threshold: condition.threshold,
    };
  }

  return {
    type: ConditionType.Discrete,
    values: condition.values,
  };
};

const getNodeCondition = (
  condition: PropertyNodeSettings['condition']
): DecisionTreePropertyNode['condition'] => {
  if (!condition) {
    return null;
  }

  if (condition.type === ConditionType.Simple) {
    return {
      type: ConditionType.Simple,
      threshold: condition.threshold || 0,
      operation: condition.operation || DTOperation.Greater,
    };
  }

  if (condition.type === ConditionType.Range) {
    return {
      type: ConditionType.Range,
      min: {
        threshold: condition.min.threshold || 0,
        operation: condition.min.operation || DTOperation.Less,
      },
      max: {
        threshold: condition.max.threshold || 0,
        operation: condition.max.operation || DTOperation.Less,
      },
    };
  }

  if (condition.type === ConditionType.DesirabilityFunction) {
    return {
      type: ConditionType.DesirabilityFunction,
      desirability: structuredClone(condition.desirability),
      operation: condition.operation,
      threshold: condition.threshold,
    };
  }

  return {
    type: ConditionType.Discrete,
    values: condition.values,
  };
};

export const getNodeSettings = (node: DecisionTreePropertyNode): PropertyNodeSettings => {
  return {
    ...defaultNodeSettings,
    id: node?.id || uuid(),
    name: node?.propertyId ?? '',
    portGroupingType: node.portGroupingType || PortGroupingType.Regular,
    condition: getConditionSettings(node.condition),
  };
};

export const getPropertyNode = (
  nodeSettings: PropertyNodeSettings,
  node: DecisionTreePropertyNode
): DecisionTreePropertyNode => {
  return {
    ...node,
    propertyId: nodeSettings.name,
    condition: getNodeCondition(nodeSettings.condition),
    portGroupingType: nodeSettings.portGroupingType,
  };
};

export const isCompleted = (nodeSettings: PropertyNodeSettings): boolean => {
  if (!nodeSettings.condition) {
    return false;
  }

  if (nodeSettings.condition.type === ConditionType.Simple) {
    return (
      !!nodeSettings.condition.operation && nodeSettings.condition.threshold !== null
    );
  }

  if (nodeSettings.condition.type === ConditionType.Range) {
    return (
      !!nodeSettings.condition.min.operation &&
      !!nodeSettings.condition.max.operation &&
      nodeSettings.condition.min.threshold !== null &&
      nodeSettings.condition.max.threshold !== null
    );
  }

  if (nodeSettings.condition.type === ConditionType.Discrete) {
    return true;
  }

  if (nodeSettings.condition.type === ConditionType.DesirabilityFunction) {
    return (
      !!nodeSettings.condition.desirability &&
      !!nodeSettings.condition.operation &&
      nodeSettings.condition.threshold !== null
    );
  }

  return false;
};

export const createNewNode = (
  nodeType:
    | DTNodeType.Or
    | DTNodeType.And
    | DTNodeType.ALN
    | DTNodeType.AMX
    | DTNodeType.Chart
): DecisionTreeNode => {
  switch (nodeType) {
    case DTNodeType.And: {
      const dtNode: DecisionTreeAndNode = {
        id: uuid(),
        type: DTNodeType.And,
        outputArrows: {
          combine: [],
        },
        inputArrows: [],
      };

      return dtNode;
    }

    case DTNodeType.Or: {
      const dtNode: DecisionTreeOrNode = {
        id: uuid(),
        type: DTNodeType.Or,
        outputArrows: {
          combine: [],
        },
        inputArrows: [],
      };

      return dtNode;
    }

    case DTNodeType.ALN: {
      const dtNode: DecisionTreeAtLeastNNode = {
        id: uuid(),
        nodeN: 3,
        type: DTNodeType.ALN,
        outputArrows: {
          combine: [],
        },
        inputArrows: [],
      };

      return dtNode;
    }

    case DTNodeType.AMX: {
      const dtNode: DecisionTreeAtMostXNode = {
        id: uuid(),
        nodeN: 3,
        type: DTNodeType.AMX,
        outputArrows: {
          combine: [],
        },
        inputArrows: [],
      };

      return dtNode;
    }

    case DTNodeType.Chart: {
      const dtNode: DecisionTreeChartNode = {
        id: uuid(),
        columnId: null,
        type: DTNodeType.Chart,
        outputArrows: {
          combine: [],
        },
        inputArrows: [],
      };

      return dtNode;
    }

    default:
      return (function protectSwitch(type: never): never {
        throw new Error('impossible');
      })(nodeType);
  }
};

export const conditionTypesToOptions = (types: ConditionType[]): ConditionOption[] => {
  const labels: Record<ConditionType, ConditionOption['label']> = {
    [ConditionType.Simple]: 'Simple',
    [ConditionType.Range]: 'Range',
    [ConditionType.Discrete]: 'Discrete',
    [ConditionType.DesirabilityFunction]: 'Desirability',
  };

  return types.map((type) => ({
    label: labels[type],
    value: type,
  }));
};
