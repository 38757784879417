import { Checkbox, Radio, RadioChangeEvent, Select } from 'antd';
import React, { useCallback, useEffect } from 'react';

import { Sorting } from '../types';

import styles from './ParameterSelect.module.less';

export interface IParameterSelectProps {
  isScoredDataMode: boolean;
  sorting: Sorting;
  onChangeSorting: (sorting: Sorting) => void;
  parameterID?: string;
  parameters: Array<{ id: string; name: string }>;
  onChangeParameter: (parameterID: string) => void;
  onChangeScoredDataMode: (useScoring: boolean) => void;
  availableParamsForScoredDataMode: Array<string>;
  invertAxes: boolean;
  showRadialMolecules: boolean;
  onChangeInvertAxes: (invertAxes: boolean) => void;
  onChangeShowRadialMolecules: (showRadialMolecules: boolean) => void;
}
export const ParameterSelect = ({
  parameters,
  parameterID,
  sorting,
  isScoredDataMode,
  onChangeParameter,
  onChangeScoredDataMode,
  onChangeSorting,
  availableParamsForScoredDataMode,
  invertAxes,
  showRadialMolecules,
  onChangeInvertAxes,
  onChangeShowRadialMolecules,
}: IParameterSelectProps) => {
  const handleScoredDataModeChange = useCallback(
    (evt: { target: { checked: boolean } }) => {
      onChangeScoredDataMode(evt.target.checked);
    },
    [onChangeScoredDataMode]
  );

  const handleInvertAxesChange = useCallback(
    (evt: { target: { checked: boolean } }) => {
      if (onChangeInvertAxes) {
        onChangeInvertAxes(evt.target.checked);
      }
    },
    [onChangeInvertAxes]
  );

  const handleShowRadialMolecules = useCallback(
    (evt: { target: { checked: boolean } }) => {
      if (onChangeShowRadialMolecules) {
        onChangeShowRadialMolecules(evt.target.checked);
      }
    },
    [onChangeShowRadialMolecules]
  );

  const handleChangeSorting = useCallback(
    (evt: RadioChangeEvent) => {
      onChangeSorting(evt.target.value);
    },
    [onChangeSorting]
  );

  const scoredDataModeDisabled = !availableParamsForScoredDataMode.includes(parameterID!);

  useEffect(
    function turnOffScoringMode() {
      if (scoredDataModeDisabled) {
        onChangeScoredDataMode(false);
      }
    },
    [scoredDataModeDisabled, onChangeScoredDataMode]
  );

  return (
    <div className={styles.wrapper}>
      <div>
        <h3 className={styles.title}>Parameter</h3>
        <Select placeholder="Parameter" value={parameterID} onSelect={onChangeParameter}>
          {parameters?.map((parameter) => (
            <Select.Option key={parameter.id} value={parameter.id}>
              {parameter.name}
            </Select.Option>
          ))}
        </Select>

        <div className={styles.checkboxWrapper}>
          <Checkbox
            checked={isScoredDataMode}
            disabled={scoredDataModeDisabled}
            onChange={handleScoredDataModeChange}
          >
            <span>Use scoring</span>
          </Checkbox>
          <Checkbox checked={invertAxes} onChange={handleInvertAxesChange}>
            <span>Invert Axes</span>
          </Checkbox>
          <Checkbox checked={showRadialMolecules} onChange={handleShowRadialMolecules}>
            <span>Show Radial Molecules</span>
          </Checkbox>
        </div>

        <div className={styles.sortingWrapper}>
          <h3 className={styles.title}>Show value from</h3>
          <Radio.Group
            buttonStyle="solid"
            optionType="button"
            value={sorting}
            onChange={handleChangeSorting}
          >
            <Radio.Button key={Sorting.DESC} value={Sorting.DESC}>
              highest to lowest
            </Radio.Button>
            <Radio.Button key={Sorting.ASC} value={Sorting.ASC}>
              lowest to highest
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
    </div>
  );
};
