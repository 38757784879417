import { DTOperation, SimpleCondition } from '@discngine/moosa-models';
import { DatePicker, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useMemo } from 'react';

import { TIMEZONE_OFFSET_MS } from '../../../constants';
import styles from '../shared/PropertyNodeEditPanel.module.less';

export interface SimpleDateConditionInputProps {
  condition: SimpleCondition;
  disabled: boolean;
  onChange: (condition: SimpleCondition) => void;
}

export const SimpleDateConditionInput = React.memo(
  ({ condition, disabled, onChange }: SimpleDateConditionInputProps) => {
    const onOperationChange = useCallback(
      (operation: DTOperation) => {
        onChange({ ...condition, operation });
      },
      [condition, onChange]
    );

    const onDateChange = useCallback(
      (value: Dayjs | null) => {
        onChange({
          ...condition,
          threshold: value ? value.valueOf() - TIMEZONE_OFFSET_MS : Date.now(),
        });
      },
      [condition, onChange]
    );

    const date = useMemo(() => {
      return dayjs(condition.threshold + TIMEZONE_OFFSET_MS);
    }, [condition.threshold]);

    return (
      <div className={styles.conditionRow}>
        <span>DATE</span>
        <div className={styles.datePicker}>
          <Select
            disabled={disabled}
            style={{ width: 'auto' }}
            value={condition.operation}
            onChange={onOperationChange}
            onSelect={onOperationChange}
          >
            {Object.values(DTOperation).map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
          <DatePicker
            className={styles.input}
            disabled={disabled}
            placeholder="Threshold"
            size="middle"
            style={{ width: '100%' }}
            value={date}
            onChange={onDateChange}
          />
        </div>
      </div>
    );
  }
);
