import { HistogramData } from '@discngine/moosa-histogram';
import {
  DTOperation,
  DesirabilityFunctionCondition,
  IColumnMetaInfo,
  IScoringFuncProperty,
} from '@discngine/moosa-models';
import { InfinityListProps } from '@discngine/moosa-shared-components';
import { InputNumber, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { DesirabilityFunctionHistogram } from './DesirabilityFunctionHistogram';
import styles from './shared/PropertyNodeEditPanel.module.less';

export interface DesirabilityFunctionConditionInputProps {
  metaData: IColumnMetaInfo;
  condition: DesirabilityFunctionCondition;
  disabled: boolean;
  desirabilityFunctionListProps?: Partial<InfinityListProps>;
  histogramData?: HistogramData | null;
  onChange: (condition: DesirabilityFunctionCondition) => void;
  onSave: (desirability: IScoringFuncProperty, existingId?: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  onSearch: (query: string, isDiscreteStringFunction: boolean) => void;
}

export const DesirabilityFunctionConditionInput = React.memo(
  ({
    metaData,
    condition,
    disabled,
    desirabilityFunctionListProps,
    histogramData,
    onChange,
    onSave,
    onDelete,
    onSearch,
  }: DesirabilityFunctionConditionInputProps) => {
    const [desirability, setDesirability] = useState<IScoringFuncProperty>(
      condition.desirability
    );

    useEffect(() => setDesirability(condition.desirability), [condition.desirability]);

    const onOperationChange = useCallback(
      (operation: DTOperation) => onChange({ ...condition, operation }),
      [condition, onChange]
    );

    const onThresholdChange = useCallback(
      (value: number | null) => onChange({ ...condition, threshold: value || 0 }),
      [condition, onChange]
    );

    const onDesirabilityChange = useCallback(
      (desirability: IScoringFuncProperty) => onChange({ ...condition, desirability }),
      [condition, onChange]
    );

    const onDesirabilitySave = useCallback(
      async (desirability: IScoringFuncProperty, existingId?: string) => {
        await onSave(desirability, existingId);
        setDesirability(desirability);
      },
      [onSave]
    );

    return (
      <>
        {!disabled && (
          <DesirabilityFunctionHistogram
            desirability={desirability}
            desirabilityFunctionListProps={desirabilityFunctionListProps}
            histogramData={histogramData}
            metaData={metaData}
            onChange={onDesirabilityChange}
            onDelete={onDelete}
            onParamsChange={setDesirability}
            onSave={onDesirabilitySave}
            onSearch={onSearch}
          />
        )}

        <div className={styles.conditionRow}>
          <span>SCORE</span>

          <InputNumber
            addonBefore={
              <Select
                disabled={disabled}
                style={{ width: 'auto' }}
                value={condition.operation}
                onChange={onOperationChange}
                onSelect={onOperationChange}
              >
                {Object.values(DTOperation).map((option) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            }
            className={styles.input}
            disabled={disabled}
            max={1}
            min={0}
            placeholder="Threshold"
            size="middle"
            style={{ width: '100%' }}
            value={condition.threshold}
            onChange={onThresholdChange}
          />
        </div>
      </>
    );
  }
);
