import { CheckOutlined, CloseOutlined, QuestionOutlined } from '@ant-design/icons';
import { DecisionTreePropertyNodeResult } from '@discngine/moosa-models';
import React, { FC } from 'react';

import { OUTPUT_MISSING, OUTPUT_NO, OUTPUT_YES } from '../../../lib/go/colors';

import styles from './PropertyNodeEditPanel.module.less';

type OutputsInfoProps = {
  outputDatasetRowIds?: DecisionTreePropertyNodeResult['outputDatasetRowIds'];
};

export const OutputsInfo: FC<OutputsInfoProps> = ({ outputDatasetRowIds }) => {
  return (
    <div className={styles.conditionsWrapper}>
      <div>
        <CheckOutlined style={{ color: OUTPUT_YES }} />
      </div>
      <div>{outputDatasetRowIds?.yes.size || 0} compounds</div>

      <div>
        <CloseOutlined style={{ color: OUTPUT_NO }} />
      </div>
      <div>{outputDatasetRowIds?.no.size || 0} compounds</div>

      <div>
        <QuestionOutlined style={{ color: OUTPUT_MISSING }} />
      </div>
      <div>{outputDatasetRowIds?.missingValues.size || 0} compounds</div>
    </div>
  );
};
