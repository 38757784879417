import {
  DesirabilityFunctionType,
  IDesirabilityFunction,
  IMoosaDataService,
  IScoringFuncProperty,
  IScoringTemplate,
  IScoringTemplateData,
  IScoringTemplateItem,
  IScoringTemplateRequest,
  isScoringType,
  SCORING_FUNCTIONS_RULES,
  ScoringFunction,
  getColor,
  initScoringFunctionsParams,
} from '@discngine/moosa-models';
import { v4 as uuid } from 'uuid';

import { IColumnMetaInfo } from '../tableInfo';

const colorGenerator = getColor();

export enum DesirabilityFunctionLineSerialization {
  LINE = 'line',
  PARAMS = 'params',
}

export const convertTemplateToTableDataType = (
  template: IScoringTemplate,
  columnMap: { [id: string]: IColumnMetaInfo }
): IScoringTemplateData => {
  let func: ScoringFunction = {};
  let order: string[] = [];

  if (template) {
    template.columns.forEach(
      ({
        columnName,
        color,
        isVisible,
        weight,
        filter,
        missingValue,
        measurementError,
        desirability: { points, type, name },
        isDiscreteStringFunction,
      }) => {
        const metadata = columnMap[columnName];

        let functionParams: any = {};

        if (isScoringType(metadata?.type, columnMap[columnName]?.isDiscreteColumn)) {
          functionParams = initScoringFunctionsParams(metadata);
        }

        functionParams[type] = SCORING_FUNCTIONS_RULES[type].fromServerTemplate(
          points.map((point) => ({ ...point, id: uuid() })),
          metadata
        );

        func[columnName] = {
          column: columnName,
          color: color ?? colorGenerator.next().value,
          isInUse: isVisible,
          weight,
          type,
          name,
          functionParams,
          missingValue,
          filter,
          measurementError: measurementError || { type: 'none' },
          isDiscreteStringFunction:
            metadata?.isDiscreteColumn && isDiscreteStringFunction,
        };
        order.push(columnName);
      }
    );
  }

  return { func, order };
};

export function saveDesirabilityFunction(
  createDesirabilityFunction: IMoosaDataService['createDesirabilityFunction'],
  name: string,
  property: IScoringFuncProperty
) {
  return async () => {
    const payload = desirabilityFunctionToPayload(name, property);

    await createDesirabilityFunction(payload);
  };
}

export function scoringFunctionToPayload(
  name: string,
  template: IScoringTemplateData,
  allMetadata: Record<string, IColumnMetaInfo> | null = null,
  pointsSerialization: DesirabilityFunctionLineSerialization = DesirabilityFunctionLineSerialization.PARAMS
): IScoringTemplateRequest {
  let columns = template?.order;

  if (pointsSerialization === DesirabilityFunctionLineSerialization.LINE) {
    columns = columns?.filter((col) => allMetadata && allMetadata[col]);
  }

  const columnsData = columns!
    .map((col) => template!.func[col])
    .map((property: IScoringFuncProperty) => {
      const rules = SCORING_FUNCTIONS_RULES[property.type];
      const params = property.functionParams[property.type] as any;

      let points;

      switch (pointsSerialization) {
        case DesirabilityFunctionLineSerialization.LINE:
          const metadata = allMetadata![property.column];

          if (property.type === DesirabilityFunctionType.discrete) {
            points = params.points
              .filter((pointF: any) => !pointF?.isNotInFunction)
              .map((point: any) => ({ x: point.x, y: point.y }));
          } else {
            const range = SCORING_FUNCTIONS_RULES[property.type].getRange(
              params,
              metadata
            );

            //@ts-ignore
            points = rules.toLine(params, range).map(({ x, y }) => ({ x, y }));
          }
          break;
        case DesirabilityFunctionLineSerialization.PARAMS:
          points = rules.toServerTemplate(params);
          break;
        default:
          throw new Error();
      }

      const item: IScoringTemplateItem = {
        color: property.color,
        columnName: property.column,
        isVisible: property.isInUse,
        weight: property.weight,
        desirability: {
          type: property.type,
          name: property.name,
          points,
        },
        missingValue: property.missingValue,
        measurementError: property.measurementError,
        filter: property.filter,
        isDiscreteStringFunction: property.isDiscreteStringFunction,
      };

      return item;
    });

  return {
    name,
    columns: columnsData,
  };
}

export function desirabilityFunctionToPayload(
  name: string,
  property: IScoringFuncProperty
): Omit<IDesirabilityFunction, '_id'> {
  const { functionParams, type, isDiscreteStringFunction } = property;
  const rules = SCORING_FUNCTIONS_RULES[type];
  const params = functionParams[type] as any;
  const points = rules.toServerTemplate(params);

  return {
    name,
    points,
    type,
    isDiscreteStringFunction,
  };
}
