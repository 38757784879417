import { PortGroupingType } from '@discngine/moosa-models';
import { Select } from 'antd';
import React, { FC } from 'react';

import styles from './PropertyNodeEditPanel.module.less';

const groupingTypes = [
  { value: PortGroupingType.Regular, label: 'No grouping' },
  { value: PortGroupingType.FalseMissing, label: 'False + Missing' },
  { value: PortGroupingType.TrueMissing, label: 'True + Missing' },
  { value: PortGroupingType.HideMissing, label: 'Hide missing values' },
];

type PortGroupingProps = {
  portGroupingType: PortGroupingType;
  onChange: (portGroupingType: PortGroupingType) => void;
};

export const PortGrouping: FC<PortGroupingProps> = ({ portGroupingType, onChange }) => {
  return (
    <div className={styles.conditionRow}>
      <span></span>
      <Select
        filterOption={true}
        placeholder={'Select grouping type'}
        showSearch={true}
        value={portGroupingType || ''}
        onChange={onChange}
      >
        {groupingTypes.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
