import {
  ColumnId,
  DTNodeId,
  DTNodeType,
  DatasetRowId,
  isPropertyNodeResult,
  isStructureNodeResult,
  isDateNodeResult,
} from '@discngine/moosa-models';
import * as go from 'gojs';
import {
  DTNodeOutputPortType,
  DiagramData,
  DiagramNodeData,
  NodeConditionMismatch,
  NodeConditionMismatchLevel,
} from 'types';
import { getMismatchDescription } from 'utils';

import {
  PORT_FILL,
  PORT_BORDER,
  HIGHLIGHTED,
  HIGHLIGHTED_LIGHT,
  NODE_HEADER_EDITABLE,
  NODE_HEADER_EDITABLE_WARNING,
  NODE_HEADER_WARNING,
  NODE_HEADER_FILL,
  NODE_CONDITION_MISMATCH_ERROR,
  NODE_CONDITION_MISMATCH_WARNING,
  OUTPUT_COUNT_MISSING_TEXT,
  OUTPUT_COUNT_TEXT,
  NODE_HEADER_TEXT,
  WHITE,
} from '../colors';

const port: Partial<go.Shape> = {
  width: 8,
  height: 8,
  fill: PORT_FILL,
  margin: 8,
  cursor: 'pointer',
  stroke: PORT_BORDER,
  strokeWidth: 2,
};

export function InputPort(portId: string, options?: object): go.Panel {
  return new go.Panel('Vertical')
    .add(
      new go.Shape({
        portId,
        toSpot: go.Spot.TopCenter,
        ...port,
        ...options,
      }).bind(
        new go.Binding('toLinkable', '', (_, object: go.GraphObject) => {
          const nodeData = object.part?.data as DiagramNodeData | undefined;

          return !(nodeData?.group && nodeData.category === DTNodeType.Property);
        }).ofObject()
      )
    )
    .add(
      new go.TextBlock({
        margin: new go.Margin(0, 0, 5, 0),
      }).bind(
        new go.Binding('text', 'result', (_, object: go.GraphObject) => {
          const nodeId = object.part?.key;
          const modelData = object.diagram?.model.modelData as DiagramData;
          const nodeResult = nodeId && modelData.result?.nodes[nodeId];

          if (!nodeResult) return 0;

          return nodeResult.inputDatasetRowIds.size;
        }).ofModel()
      )
    );
}

export const portSVG = (color: string): string => {
  const svg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="${color}"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 8H16V16H8V8ZM9.77778 9.77778V14.2222H14.2222V9.77778H9.77778Z"
    />
  </svg>`;

  return `data:image/svg+xml;base64,${window.btoa(svg)}`;
};

export function OutputPort(props: {
  portId: DTNodeOutputPortType;
  options?: Partial<go.Panel>;
  icons?: go.Panel;
  onClick?: (
    nodeId: DTNodeId,
    propertyId: ColumnId | null,
    rowIDs: Set<DatasetRowId> | null
  ) => void;
}): go.Panel {
  const { portId, options, icons, onClick } = props;

  // Port panel
  const panel = new go.Panel('Vertical', {
    portId,
    fromSpot: go.Spot.BottomCenter,
    fromLinkable: true,
    cursor: 'pointer',

    ...options,

    click: (_, object: go.GraphObject) => {
      if (!onClick || !object.part) return;

      const nodeId = object.part.key as DTNodeId;
      const nodeData = object.part.data as DiagramNodeData;
      const modelData = object.diagram?.model.modelData as DiagramData;
      const nodeResult = nodeId && modelData.result?.nodes[nodeId];

      if (nodeResult) {
        onClick?.(
          nodeId,
          nodeData.category === DTNodeType.Property ||
            nodeData.category === DTNodeType.StructureSearch ||
            nodeData.category === DTNodeType.Date
            ? nodeData.text
            : null,
          isPropertyNodeResult(nodeResult) ||
            isStructureNodeResult(nodeResult) ||
            isDateNodeResult(nodeResult)
            ? portId !== 'combine'
              ? nodeResult.outputDatasetRowIds[portId]
              : null
            : nodeResult.outputDatasetRowIds.combine
        );
      }
    },
  })

    .bind(
      new go.Binding(
        'background',
        'isHighlighted',
        (isHighlighted: DiagramData['isHighlighted'], object: go.GraphObject) => {
          const nodeData = object.part?.data as DiagramNodeData | undefined;

          return isHighlighted && !nodeData?.group ? HIGHLIGHTED_LIGHT : 'transparent';
        }
      ).ofModel()
    )
    .bind(
      new go.Binding(
        'background',
        'highlightedPort',
        (highlightedPort: DiagramData['highlightedPort'], object: go.GraphObject) => {
          const nodeData = object.part?.data as DiagramNodeData | undefined;

          if (
            !nodeData?.group &&
            highlightedPort?.type === 'nodePort' &&
            object.part &&
            object.part.key === highlightedPort.nodeId &&
            object.portId === highlightedPort.portType
          ) {
            return HIGHLIGHTED;
          }

          return !nodeData?.group && object.diagram?.model.modelData.isHighlighted
            ? HIGHLIGHTED_LIGHT
            : 'transparent';
        }
      ).ofModel()
    );

  if (icons) {
    panel.add(icons);
  }

  // Compounds count
  panel.add(
    new go.Panel('Vertical')
      .add(
        new go.TextBlock({
          wrap: go.TextBlock.None,
          margin: new go.Margin(5, 6, -2, 6),
          stroke:
            portId === 'missingValues' ? OUTPUT_COUNT_MISSING_TEXT : OUTPUT_COUNT_TEXT,
          font: '400 14px Roboto, sans-serif',
          verticalAlignment: go.Spot.Center,
        }).bind(
          new go.Binding('text', 'result', (_, object: go.GraphObject) => {
            const nodeId = object.part?.key;
            const modelData = object.diagram?.model.modelData as DiagramData;
            const nodeResult = nodeId && modelData.result?.nodes[nodeId];

            if (!nodeResult) return 0;

            if (
              isPropertyNodeResult(nodeResult) ||
              isStructureNodeResult(nodeResult) ||
              isDateNodeResult(nodeResult)
            ) {
              if (portId === 'combine') return null;

              return nodeResult.outputDatasetRowIds[portId].size;
            } else {
              return nodeResult.outputDatasetRowIds.combine.size;
            }
          }).ofModel()
        )
      )
      .add(
        new go.Picture({
          width: 32,
          height: 32,
          source: portSVG(PORT_BORDER),
        })
      )
  );

  return panel;
}

export const getNodeHeaderTextColor = (object: go.GraphObject): string | null => {
  const nodeData = object.part?.data as DiagramNodeData | undefined;

  if (
    nodeData?.category === DTNodeType.Property ||
    nodeData?.category === DTNodeType.Date
  ) {
    return nodeData.condition ? NODE_HEADER_TEXT : WHITE;
  }

  if (nodeData?.category === DTNodeType.StructureSearch) {
    return nodeData.structure ? NODE_HEADER_TEXT : WHITE;
  }

  return NODE_HEADER_TEXT;
};

export const getNodeHeaderColor = (object: go.GraphObject): string | null => {
  const nodeData = object.part?.data as DiagramNodeData | undefined;
  const modelData = object.diagram?.model.modelData as DiagramData | undefined;

  if (!nodeData || !modelData) return null;

  const isEditable = nodeData.key === modelData.editableNodeId;
  const isValid =
    (nodeData.category === DTNodeType.Property && !!nodeData.condition) ||
    (nodeData.category === DTNodeType.Date && !!nodeData.condition) ||
    (nodeData.category === DTNodeType.Chart && !!nodeData.columnId) ||
    (nodeData.category === DTNodeType.StructureSearch && !!nodeData.structure);

  if (isEditable) {
    return isValid ? NODE_HEADER_EDITABLE : NODE_HEADER_EDITABLE_WARNING;
  } else {
    return isValid ? NODE_HEADER_FILL : NODE_HEADER_WARNING;
  }
};

export const getNodeTypeMismatchMessage = (): go.Panel => {
  return new go.Panel('Auto', {
    maxSize: new go.Size(285, 60),
    margin: new go.Margin(16, 0, 16, 0),
    defaultStretch: go.GraphObject.Horizontal,
  })
    .bind('visible', 'mismatch', (mismatch: DiagramNodeData['mismatch']) => !!mismatch)
    .add(
      new go.Shape('RoundedRectangle', {
        parameter1: 8,
        strokeWidth: 0,
      }).bind('fill', 'mismatch', (mismatch: DiagramNodeData['mismatch']) => {
        if (mismatch?.level === NodeConditionMismatchLevel.Critical) {
          return NODE_CONDITION_MISMATCH_ERROR;
        }

        if (mismatch?.level === NodeConditionMismatchLevel.Warning) {
          return NODE_CONDITION_MISMATCH_WARNING;
        }

        return 'transparent';
      })
    )
    .add(
      new go.TextBlock({
        font: '400 14px Roboto, sans-serif',
        stroke: '#373737',
        margin: new go.Margin(10, 15, 10, 15),
      }).bind('text', 'mismatch', (mismatch: NodeConditionMismatch) =>
        mismatch ? getMismatchDescription(mismatch) : ''
      )
    );
};
