import { decimalFormatter } from '@discngine/moosa-common';
import { classNames } from '@discngine/moosa-common';
import {
  DesirabilityFunctionMissingValue,
  IScore,
  ScoringFunction,
} from '@discngine/moosa-models';
import { ReasonType } from '@discngine/moosa-models';
import { Popover } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { FC } from 'react';

import { DataSubCell } from './DataSubCell/DataSubCell';
import { FactorsRenderer } from './FactorsRenderer/FactorsRenderer';
import styles from './ScoreRendererAbstract.module.less';
import { ScoringError } from './ScoringErrors/ScoringErrors';

interface Props {
  columnIds: string[];
  columnIndices: Record<string, number>;
  order: string[];
  score: IScore;
  showErrors: boolean;
  scoringFunction: ScoringFunction;
  variance: number;
  isComparisonColumn: boolean;
  isComparisonMode: boolean;
  hasComparisonTemplate: boolean;
  compact?: boolean;
  onChangeColumnColor: (column: string, color: string) => void;
}

function messageFromReason(
  reason: ReasonType,
  parameter?: string,
  missingValue?: DesirabilityFunctionMissingValue
): string {
  switch (reason) {
    case ReasonType.DoNotCalculate: {
      return `Scoring cannot be calculated as ${parameter} has missing value`;
    }
    case ReasonType.FilteredOff: {
      return `Filter applied on ${parameter}`;
    }
    case ReasonType.ReplacedWithX: {
      return `Scoring calculated using default X value  = ${missingValue?.replaceWith} for parameter ${parameter}`;
    }
    case ReasonType.ReplacedWithY: {
      return `Scoring calculated using default Y value  = ${missingValue?.replaceWithY} for parameter ${parameter}`;
    }
    case ReasonType.ReplacedWithColumn: {
      return `Scoring calculated using alternative column ${missingValue?.replaceWithColumn} for parameter ${parameter}`;
    }
    default: {
      return '';
    }
  }
}

export const ScoreRendererAbstract: FC<Props> = ({
  columnIds,
  columnIndices,
  order,
  score,
  scoringFunction,
  showErrors,
  variance,
  isComparisonColumn,
  isComparisonMode,
  hasComparisonTemplate,
  compact = false,
  onChangeColumnColor,
}) => {
  if (!score && isComparisonMode && isComparisonColumn) {
    const hint =
      'Scoring cannot be calculated as comparison scoring template is not set or there are no columns in it';

    return (
      <Popover content={hint} overlayClassName={styles.hint} placement="bottom">
        <div className="text-center">N/A</div>
      </Popover>
    );
  } else if (!score) {
    return null;
  }

  // case #7 https://quantori.atlassian.net/l/c/huT6AxrF
  if (
    score.value === 0 &&
    Object.keys(score.factors).length === 0 &&
    Object.keys(scoringFunction).length !== 0
  ) {
    const hint = 'None of added parameters is applicable to this dataset';

    return (
      <Popover content={hint} overlayClassName={styles.hint} placement="bottom">
        <div className="text-center">N/A</div>
      </Popover>
    );
  }

  const titleRows = Object.keys(score.reasons).map((key) => {
    const parameterIndex = +key;
    const reason = score.reasons[parameterIndex];
    const parameterName = columnIds[parameterIndex];
    const missingValue = scoringFunction[columnIds[parameterIndex]]?.missingValue;

    return messageFromReason(reason, parameterName, missingValue);
  });
  const multilineHint = titleRows.length ? (
    <ol className={styles.tipList}>
      {titleRows.map((row) => (
        <li key={row}>{row}</li>
      ))}
    </ol>
  ) : null;

  if (score.value === null || !Object.keys(score.factors).length) {
    const hint =
      isEmpty(scoringFunction) || isEmpty(multilineHint)
        ? 'Scoring cannot be calculated as scoring template is not set or there are no columns in it'
        : multilineHint;

    return (
      <Popover content={hint} overlayClassName={styles.hint} placement="bottom">
        <div className="text-center">N/A</div>
      </Popover>
    );
  }

  const scoreBox = (
    <div className={styles.scoreBox}>{decimalFormatter.format(score.value)}</div>
  );

  return (
    <div className={styles.scoreCell}>
      <DataSubCell
        compact={compact}
        errorNegative={
          <ScoringError base={score.value} delta={score.totalMeasurementError.negative} />
        }
        errorPositive={
          <ScoringError base={score.value} delta={score.totalMeasurementError.positive} />
        }
        showErrors={showErrors}
        showVariance={isComparisonMode && hasComparisonTemplate && !isComparisonColumn}
        variance={
          <div
            className={classNames(styles.variance, {
              [styles.positive]: variance > 0,
              [styles.negative]: variance < 0,
            })}
          >
            {`${variance > 0 ? '+' : ''}${variance.toFixed(2)}`}
          </div>
        }
      >
        {multilineHint ? (
          <Popover
            content={multilineHint}
            overlayClassName={styles.hint}
            placement="bottom"
          >
            {scoreBox}
          </Popover>
        ) : (
          scoreBox
        )}
      </DataSubCell>
      <FactorsRenderer
        columnIds={columnIds}
        columnIndices={columnIndices}
        isComparisonColumn={isComparisonColumn}
        order={order}
        score={score}
        scoringFunction={scoringFunction}
        onChangeColumnColor={onChangeColumnColor}
      />
    </div>
  );
};

export default ScoreRendererAbstract;
