import { ILineDiscretePoint, ILinePoint } from '@discngine/moosa-models';
import { HandlerArgs } from '@visx/drag/lib/useDrag';
import { localPoint } from '@visx/event';
import React, { useCallback, useState } from 'react';

import { ScaleLinear } from '../Histogram';

interface Args {
  xScale: ScaleLinear;
  yScale: ScaleLinear;
  move: (x: number, y: number, idx: number, id: string) => void;
  add: (x: number, y: number, idx: number) => void;
  remove: (idx: number) => void;
  isEditable: boolean;
  maxIndex: number;
  yMultiplier: number;
}

const MOVE_THRESHOLD = 5;

export const usePoints = (args: Args) => {
  const { yScale, xScale, move, add, isEditable, maxIndex, remove, yMultiplier } = args;

  const [currentPoint, setCurrentPoint] = useState<
    NonNullable<ILineDiscretePoint> | NonNullable<ILinePoint> | null
  >(null);

  const getPosition = useCallback(
    (event: HandlerArgs['event']) => {
      const { x, y } = localPoint(event) ?? { x: 0, y: 0 };

      const xValue = xScale.invert(x);
      const yValue = yScale.invert(y) / yMultiplier;

      return { x: xValue, y: yValue };
    },
    [xScale, yMultiplier, yScale]
  );

  const onDragMove = useCallback(
    ({ event }: HandlerArgs) => {
      if (currentPoint !== null && currentPoint.originalPointIndex !== undefined) {
        const { x, y } = getPosition(event);

        move(x, y, currentPoint.originalPointIndex, currentPoint.id);
      }
    },
    [currentPoint, getPosition, move]
  );

  const onDragEnd = useCallback(() => setCurrentPoint(null), []);

  const onDragStart = useCallback(
    ({ x = 0, y = 0 }: HandlerArgs, item: ILineDiscretePoint | ILinePoint) => {
      if (item.originalPointIndex !== undefined) {
        setCurrentPoint(item);
      }
    },
    []
  );

  const onPointAdd = useCallback(
    (event: React.MouseEvent) => {
      if (isEditable) {
        const { x, y } = getPosition(event);

        add(x, y, maxIndex);
      }
    },
    [add, getPosition, isEditable, maxIndex]
  );

  const onPointDelete = useCallback(
    ({ x = 0, y = 0 }: HandlerArgs, item: ILineDiscretePoint | ILinePoint) => {
      const isPointMoved =
        Math.abs(x - Number(currentPoint?.x ?? 0)) >= MOVE_THRESHOLD ||
        Math.abs(y - (currentPoint?.y ?? 0)) >= MOVE_THRESHOLD;

      if (item.originalPointIndex && isEditable && !isPointMoved) {
        remove(item.originalPointIndex);
      }
    },
    [currentPoint?.x, currentPoint?.y, isEditable, remove]
  );

  return { onDragMove, onDragEnd, onDragStart, onPointAdd, onPointDelete };
};
