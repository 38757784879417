import {
  CellBackgroundMode,
  RowSizes,
  COMPARISON_SCORE_COLUMN_ID,
  SpecialColumn,
} from '@discngine/moosa-models';
import {
  selectScoringColumnOrder,
  selectScoringFunction,
  setColumnColor,
} from '@discngine/moosa-store/scoringTemplate';
import {
  changeColumnOrder,
  PINNED_COLUMNS,
  selectTableCellBackgroundMode,
  selectTableColumnNames,
  selectTableColumnOrder,
  selectTableColumnTypes,
  selectTableColumnVisibility,
  selectTableRowsSize,
  selectUseTemplateColumns,
  setCellBackgroundMode,
  setColumnsFromTemplate,
  setColumnVisibility,
  setRowsSize,
  setUseTemplateColumns,
} from '@discngine/moosa-store/tableConfig';
import {
  IColumnMetaInfo,
  selectTableColumns,
  selectTableId,
} from '@discngine/moosa-store/tableInfo';
import { TablePropertiesPanel } from '@discngine/moosa-table-properties-panel';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, useAppStore } from '../../../store/store';

import { isAlwaysVisible, isPinned, isScoring, isSystem } from './utils';

export const TableProperties = () => {
  const dispatch = useDispatch<AppDispatch>();
  const scoringFunction = useSelector(selectScoringFunction);
  const tableId = useSelector(selectTableId);
  const backgroundMode = useSelector(selectTableCellBackgroundMode);
  const rowsSize = useSelector(selectTableRowsSize);

  const onChangeRowSize = useCallback(
    (rowSize: RowSizes) => dispatch(setRowsSize({ size: rowSize, tableId })),
    [dispatch, tableId]
  );

  const onChangeBackgroundMode = (mode: CellBackgroundMode) => {
    dispatch(setCellBackgroundMode({ tableId, mode: mode }));
  };

  const isUseTemplateColumns = useSelector(selectUseTemplateColumns);
  const { getState } = useAppStore();
  const getTemplateColumnsOrder = useCallback(
    () => selectScoringColumnOrder(getState()),
    [getState]
  );
  const onChangeTemplateColumns = useCallback(
    (checked: boolean) => {
      if (checked) {
        dispatch(setUseTemplateColumns(true));
        dispatch(setColumnsFromTemplate(getTemplateColumnsOrder));
      } else {
        dispatch(setUseTemplateColumns(false));
      }
    },
    [dispatch, getTemplateColumnsOrder]
  );

  const columnVisibility = useSelector(selectTableColumnVisibility);
  const columns: IColumnMetaInfo[] = useSelector(selectTableColumns);
  const columnsMap: Record<string, IColumnMetaInfo> = {};

  columns.forEach((column) => {
    columnsMap[column.name] = column;
  });

  const onChangeVisibility = useCallback(
    (tableId: string, column: string, visible: boolean) =>
      dispatch(setColumnVisibility({ tableId, column: column, visible })),
    [dispatch]
  );

  const handleColorChange = useCallback(
    (colId: string, color: string) => {
      dispatch(setColumnColor({ column: colId, color }));
    },
    [dispatch]
  );

  const onChangeColumnOrder = useCallback(
    (tableId: string, from: string, to: string) => {
      dispatch(
        changeColumnOrder({
          tableId,
          fromColumn: from,
          toColumn: to,
        })
      );
    },
    [dispatch]
  );

  const columnNames = useSelector(selectTableColumnNames);
  const columnTypes = useSelector(selectTableColumnTypes);

  const columnOrder = useSelector(selectTableColumnOrder);
  const orderedColumns = useMemo(
    () =>
      columnOrder.map((colId) => ({
        nameUppercase: columnNames[colId].toUpperCase(),
        colId,
      })),
    [columnOrder, columnNames]
  );

  const orderedSystemColumns = useMemo(
    () =>
      columnOrder.filter((id) =>
        PINNED_COLUMNS.some(
          (type) => type === columnTypes[id] && type !== SpecialColumn.Comparison
        )
      ),
    [columnOrder, columnTypes]
  );

  const systemColumns = useMemo(
    () =>
      orderedSystemColumns.map((colId) => {
        return {
          tableId: tableId,
          colId: colId,
          color: scoringFunction[colId]?.color,
          columnName: columnNames[colId],
          isNumber: false,
          isSystem: true,
          isPinned: isPinned(columnTypes[colId]),
          isAlwaysVisible: isAlwaysVisible(columnTypes[colId]),
          isVisible: columnVisibility[colId],
          onChangeVisibility: onChangeVisibility,
          onChangeColumnOrder,
          onColorChange: (color: string) => handleColorChange(colId, color),
        };
      }),
    [
      tableId,
      orderedSystemColumns,
      columnNames,
      columnTypes,
      columnVisibility,
      scoringFunction,
      onChangeVisibility,
      onChangeColumnOrder,
      handleColorChange,
    ]
  );

  const dataColumns = orderedColumns
    .filter(
      ({ colId }) =>
        !orderedSystemColumns.includes(colId) && colId !== COMPARISON_SCORE_COLUMN_ID
    )
    .map(({ colId }) => {
      const col = columnsMap[colId];

      return {
        tableId: tableId,
        colId: col.name,
        color: scoringFunction[colId]?.color,
        columnName: columnNames[col.name],
        isNumber: isScoring(col.type, col.isDiscreteColumn),
        isSystem: isSystem(col.type),
        isPinned: isPinned(col.type),
        isAlwaysVisible: isAlwaysVisible(col.type),
        isVisible: columnVisibility[col.name],
        onChangeVisibility: onChangeVisibility,
        onChangeColumnOrder,
        onColorChange: (color: string) => handleColorChange(colId, color),
      };
    });

  const result = [...systemColumns, ...dataColumns];

  return (
    <TablePropertiesPanel
      backgroundMode={backgroundMode}
      columns={result}
      rowSize={rowsSize}
      willSynchronizeColumnsWithTemplate={isUseTemplateColumns}
      onChangeBackgroundMode={onChangeBackgroundMode}
      onChangeRowSize={onChangeRowSize}
      onChangeTemplateColumns={onChangeTemplateColumns}
    />
  );
};
