import { DTNodeId } from '@discngine/moosa-models';
import * as go from 'gojs';

import '../extensions/Buttons';

import {
  CommonNodeTemplateProps,
  DiagramNodeData,
  DiagramNodePosition,
} from '../../../types';
import {
  NODE_BORDER,
  NODE_FILL,
  OPERATOR_NODE_TEXT,
  getNodeBackgroundColor,
  getNodeBorderColor,
  getNodeBorderSize,
} from '../colors';

import { InputPort, OutputPort } from './common';

const MAX_N_VALUE = 99;

interface AtMostXNodeTemplateProps extends CommonNodeTemplateProps {}

export const atMostXNodeTemplate = ({ onResultsClick }: AtMostXNodeTemplateProps) => {
  return (
    new go.Node('Vertical', {
      cursor: 'pointer',
      locationSpot: new go.Spot(0.5, 0, 0, 15),
    })
      // Prevent movement for grouped nodes
      .bind('movable', 'group', (group: DTNodeId) => !group)
      .bind('selectable', 'group', (group: DTNodeId) => !group)

      // Node position
      .bind(
        new go.Binding(
          'location',
          'position',
          (position: DiagramNodePosition): go.Point =>
            new go.Point(position.x, position.y),
          (location: go.Point): DiagramNodePosition => location
        )
      )

      // Input ports
      .add(InputPort('input'))

      // Node
      .add(
        new go.Panel('Spot', { name: 'container' })
          .add(
            new go.Shape('RoundedRectangle', {
              alignment: go.Spot.Center,
              fill: NODE_FILL,
              width: 100,
              height: 70,
              stroke: NODE_BORDER,
              strokeWidth: 1,
            })
              .bind(
                new go.Binding(
                  'fill',
                  'highlightedNode',
                  getNodeBackgroundColor
                ).ofModel()
              )
              .bind(
                new go.Binding('stroke', 'highlightedNode', getNodeBorderColor).ofModel()
              )
              .bind(
                new go.Binding(
                  'strokeWidth',
                  'highlightedNode',
                  getNodeBorderSize
                ).ofModel()
              )
          )
          .add(
            new go.Panel('Vertical')
              .add(
                new go.TextBlock({
                  stroke: OPERATOR_NODE_TEXT,
                  font: '500 14px Roboto, sans-serif',
                  text: 'At most',
                  margin: 8,
                })
              )
              .add(
                new go.Panel('Horizontal')
                  .add(
                    new go.Panel({
                      isEnabled: true,
                      cursor: 'pointer',
                      click: (_, ev) => {
                        const node = ev.part;
                        const data = node?.data as DiagramNodeData;

                        if (data?.nodeN && data.nodeN > 1) {
                          node?.diagram?.model.commit((model: go.Model) => {
                            model.set(data, 'nodeN', data?.nodeN && data.nodeN - 1);
                          });
                        }
                      },
                    })
                      .bind(
                        new go.Binding(
                          'isEnabled',
                          'nodeN',
                          (nodeN: string) => +nodeN > 1
                        )
                      )
                      .bind(
                        new go.Binding('cursor', 'nodeN', (nodeN: string) =>
                          +nodeN > 1 ? 'pointer' : 'default'
                        )
                      )
                      .add(
                        new go.Shape('MinusLine', {
                          margin: 3,
                          desiredSize: new go.Size(10, 10),
                          stroke: OPERATOR_NODE_TEXT,
                        }).bind(
                          new go.Binding('stroke', 'nodeN', (nodeN: string) =>
                            +nodeN > 1 ? OPERATOR_NODE_TEXT : NODE_BORDER
                          )
                        )
                      )
                  )
                  .add(
                    new go.TextBlock({
                      stroke: OPERATOR_NODE_TEXT,
                      font: '500 14px Roboto, sans-serif',
                      margin: 8,
                    }).bind('text', 'nodeN', String, Number)
                  )
                  .add(
                    new go.Panel({
                      isEnabled: true,
                      cursor: 'pointer',
                      click: (_, ev) => {
                        const node = ev.part;
                        const data = node?.data as DiagramNodeData;

                        if (data?.nodeN && data.nodeN < MAX_N_VALUE) {
                          node?.diagram?.model.commit((model: go.Model) => {
                            model.set(data, 'nodeN', data?.nodeN && data.nodeN + 1);
                          });
                        }
                      },
                    })
                      .bind(
                        new go.Binding(
                          'isEnabled',
                          'nodeN',
                          (nodeN: string) => +nodeN < MAX_N_VALUE
                        )
                      )
                      .bind(
                        new go.Binding('cursor', 'nodeN', (nodeN: string) =>
                          +nodeN < MAX_N_VALUE ? 'pointer' : 'default'
                        )
                      )
                      .add(
                        new go.Shape('PlusLine', {
                          margin: 3,
                          desiredSize: new go.Size(10, 10),
                          stroke: OPERATOR_NODE_TEXT,
                        }).bind(
                          new go.Binding('stroke', 'nodeN', (nodeN: string) =>
                            +nodeN < MAX_N_VALUE ? OPERATOR_NODE_TEXT : NODE_BORDER
                          )
                        )
                      )
                  )
              )
          )
      )

      // Output ports
      .add(OutputPort({ portId: 'combine', onClick: onResultsClick }))
  );
};
