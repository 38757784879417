import { elementToSVG } from 'dom-to-svg';

function download(filename: string, text: XMLDocument) {
  const element = document.createElement('a');
  const serializer = new XMLSerializer();
  let source = serializer.serializeToString(text);

  //add name spaces.
  //
  //eslint-disable-next-line
  if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
    source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
  }

  //eslint-disable-next-line
  if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
    source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
  }

  //add xml declaration
  //source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

  //convert svg source to URI data scheme.
  const url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);

  element.setAttribute('href', url);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export const getElementIdToSvg = (elementId: string) => {
  const element = document.getElementById(elementId);

  if (element) {
    const svgElement = elementToSVG(element);

    download(`${elementId}-${new Date().toJSON()}.svg`, svgElement);
  }
};

/**
 * Save part of html document as SVG picture
 *
 * @param element
 * @param fileName - should have .svg extension
 */
export const getElementToSVG = (element: HTMLElement, fileName: string) => {
  const svgElement = elementToSVG(element);

  download(fileName, svgElement);
};
