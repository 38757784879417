import { useIsOverflow } from '@discngine/moosa-common';
import { ColorPickerPure } from '@discngine/moosa-shared-components';
import { Tooltip } from 'antd';
import React, { useCallback, useRef } from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';

import { DND_ITEM_TYPE } from '../../constants';
import { DndItem } from '../../types';

import styles from './ListItem.module.less';

export interface IPropertyItem {
  colId: string;
  columnName: string;
  onDrugEnd: (monitor: DragSourceMonitor<any, DndItem>) => void;
  onDoubleClick?: () => void;
}

export const PropertyItem: React.FC<IPropertyItem> = ({
  colId,
  columnName,
  onDrugEnd,
  onDoubleClick,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(ref, [columnName]);

  const dragEnd = useCallback(
    (item: DndItem | undefined, monitor: DragSourceMonitor<any, DndItem>) => {
      onDrugEnd(monitor);
    },
    [onDrugEnd]
  );

  const [{ opacity }, drag, preview] = useDrag<DndItem, DndItem, { opacity: number }>({
    type: DND_ITEM_TYPE,
    item: { type: DND_ITEM_TYPE },
    end: dragEnd,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  return (
    <li
      key={colId}
      ref={preview}
      className={styles.listItem}
      style={{ opacity }}
      onDoubleClick={onDoubleClick}
    >
      <div ref={drag} className={styles.container}>
        <div className={styles.picker}>
          <ColorPickerPure isDisabled={true} />
        </div>

        <Tooltip placement="bottom" title={isOverflow ? columnName : undefined}>
          <div ref={ref} className={styles.name}>
            {columnName}
          </div>
        </Tooltip>

        <div className={styles.anchor}>
          <div className={styles.anchorIcon} />
        </div>
      </div>
    </li>
  );
};
