import { DTNodeType } from '@discngine/moosa-models';
import { Space } from 'antd';
import React, { useCallback } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';

import { DND_ITEM_TYPE } from '../../constants';
import { DndItem } from '../../types';

import { ReactComponent as AtLeastN } from './icons/atLeastN.svg';
import { ReactComponent as AtMostX } from './icons/atMostX.svg';
import { ReactComponent as Chart } from './icons/chart.svg';
import { ReactComponent as Intercect } from './icons/intersect.svg';
import { ReactComponent as Union } from './icons/union.svg';
import styles from './ListItem.module.less';

export interface IOperatorItem {
  name: string;
  type: DTNodeType;
  onDrugEnd: (monitor: DragSourceMonitor<any, DndItem>) => void;
  onDoubleClick?: () => void;
}

export const OperatorItem: React.FC<IOperatorItem> = ({
  name,
  type,
  onDrugEnd,
  onDoubleClick,
}) => {
  const dragEnd = useCallback(
    (item: DndItem | undefined, monitor: DragSourceMonitor<any, DndItem>) => {
      onDrugEnd(monitor);
    },
    [onDrugEnd]
  );

  const [{ opacity }, drag, preview] = useDrag<DndItem, DndItem, { opacity: number }>({
    type: DND_ITEM_TYPE,
    item: { type: DND_ITEM_TYPE },
    end: dragEnd,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  return (
    <li
      key={type}
      ref={preview}
      className={styles.listItem}
      style={{ opacity }}
      onDoubleClick={onDoubleClick}
    >
      <div ref={drag} className={styles.container}>
        <Space size={8}>
          <div className={styles.icon}>
            {type === DTNodeType.And && <Intercect />}
            {type === DTNodeType.Or && <Union />}
            {type === DTNodeType.ALN && <AtLeastN />}
            {type === DTNodeType.AMX && <AtMostX />}
            {type === DTNodeType.Chart && <Chart />}
          </div>

          <div className={styles.name}>{name}</div>
        </Space>

        <div className={styles.anchor}>
          <div className={styles.anchorIcon} />
        </div>
      </div>
    </li>
  );
};
