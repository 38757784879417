import { v4 as uuid } from 'uuid';

import {
  DesirabilityFunctionRules,
  RectangularFunctionParams,
  IRange,
  ILinePoint,
  ServerLinePoint,
} from '../scoringTemplate';
import { IColumnMetaInfo } from '../tableInfo';

import {
  assignIndices,
  getRangeForInitialization,
  getRangeForLinearApprox,
  getValueFromLinearApprox,
  removeIndices,
  toLine,
  updateLinePoint,
} from './rulesUtils';

export const rectangularFunctionRules: DesirabilityFunctionRules<RectangularFunctionParams> =
  {
    init(metadata: IColumnMetaInfo): RectangularFunctionParams {
      const { min, max } = getRangeForInitialization(metadata);

      const third = (2 * min + max) / 3;
      const twoThird = (min + 2 * max) / 3;
      const params: RectangularFunctionParams = {
        points: [
          { x: min, y: 0, originalPointIndex: 0, id: uuid() },
          { x: third, y: 1, originalPointIndex: 1, id: uuid() },
          { x: twoThird, y: 1, originalPointIndex: 2, id: uuid() },
          { x: max, y: 0, originalPointIndex: 3, id: uuid() },
        ],
      };

      return params;
    },
    getValue(xVal: number, params: RectangularFunctionParams): number {
      return getValueFromLinearApprox(xVal, params.points);
    },
    getRange(params: RectangularFunctionParams, metadata: IColumnMetaInfo): IRange {
      return getRangeForLinearApprox(params.points, metadata);
    },
    toLine(params: RectangularFunctionParams, range: IRange): ILinePoint[] {
      return toLine(params.points, range);
    },
    toServerTemplate(params: RectangularFunctionParams): ServerLinePoint[] {
      return removeIndices(params.points);
    },
    fromServerTemplate(points: ILinePoint[]): RectangularFunctionParams {
      return { points: assignIndices(points) } as RectangularFunctionParams;
    },
    movePoint(params: RectangularFunctionParams, point: ILinePoint) {
      return updateLinePoint(params.points, point) as RectangularFunctionParams;
    },
  };
