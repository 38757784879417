import { RedoOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import React from 'react';

import styles from './UndoRedo.module.less';

interface UndoRedoProps {
  /**
   * The GoJS diagram instance to handle external updates.
   */
  diagram: go.Diagram;
}

/**
 * Component for handling undo and redo functionality for a diagram.
 *
 * @param diagram - The diagram object.
 */
export const UndoRedo = ({ diagram }: UndoRedoProps) => (
  <Space className={styles.undoredo} size={8} wrap={true}>
    <Button
      disabled={!diagram.undoManager.canUndo()}
      onClick={() => diagram.undoManager.undo()}
    >
      <UndoOutlined />
      Undo
    </Button>

    <Button
      disabled={!diagram.undoManager.canRedo()}
      onClick={() => diagram.undoManager.redo()}
    >
      Redo
      <RedoOutlined />
    </Button>
  </Space>
);
