import * as go from 'gojs';

import { NODES_GAP } from '../../../constants';
import { DiagramNodePosition } from '../../../types';
import { NODE_BORDER, NODE_FILL } from '../colors';

export const groupNodeTemplate = new go.Group({
  copyable: false,
  movable: false,
  cursor: 'default',
  locationSpot: new go.Spot(0.5, 0, 0, 15),
  layout: new go.GridLayout({
    spacing: new go.Size(20, 20),
    wrappingColumn: 4,
    wrappingWidth: (350 + 20) * 5, // TODO: move size to constants
  }),
})
  // Node position
  .bind(
    new go.Binding(
      'location',
      'position',
      (position: DiagramNodePosition): go.Point => new go.Point(position.x, position.y),
      (location: go.Point): DiagramNodePosition => location
    )
  )

  .add(
    new go.Panel('Auto')
      .add(
        new go.Shape('RoundedRectangle', {
          parameter1: 10,
          stroke: NODE_BORDER,
          strokeWidth: 2,
          fill: NODE_FILL,
        })
      )
      .add(
        new go.Placeholder({
          margin: new go.Margin(20, 0, 0, 0),
          padding: NODES_GAP / 2,
        })
      )
  )
  .add(
    new go.TextBlock({
      margin: NODES_GAP / 2 + 5,
      alignment: go.Spot.Left,
      font: '600 24px Roboto, sans-serif',
    }).bind('text', 'text')
  );
