import { Radio, Space } from 'antd';
import React from 'react';
import { DiagramHistogramView } from 'types';

interface HistogramViewSelectorProps {
  views: DiagramHistogramView[];
  view: DiagramHistogramView;
  onSelect: (view: DiagramHistogramView) => void;
}

export const HistogramViewSelector = ({
  views,
  view,
  onSelect,
}: HistogramViewSelectorProps) => {
  return (
    <Space size={10} wrap={true}>
      <span>Histogram:</span>

      <Radio.Group
        buttonStyle="solid"
        options={views}
        optionType="button"
        value={view}
        onChange={(event) => onSelect(event.target.value)}
      />
    </Space>
  );
};
