import { DeleteFilled, SwapOutlined } from '@ant-design/icons';
import { IRGBColor, classNames } from '@discngine/moosa-common';
import {
  IGradient,
  IGradientColor,
  DiscretePropertyValue,
  IMoosaSarConfigTag,
  IColumnLabelMap,
} from '@discngine/moosa-models';
import {
  DiscretePropertyColorizingConfig,
  GradientScale,
} from '@discngine/moosa-shared-components-gen';
import { Button } from 'antd';
import Checkbox from 'antd/lib/checkbox';
import { FC, useCallback, useMemo } from 'react';

import { AntComponentsProvider } from '../../../AntComponentsProvider/AntComponentsProvider';

import styles from './ColorizingPanel.module.less';

interface IColorizingPanelProps {
  onGradientChange: (gradient: IGradient) => void;
  tag: IMoosaSarConfigTag;
  onDiscreteValueColorChange: (
    indexOfSelectedColor: number,
    color: IRGBColor | null
  ) => void;
  onColorizingModeChange: (isScoredMode: boolean) => void;
  onToggleColorizing: (isColored: boolean) => void;
  handleColorizingPanelVisibleChange: (newVisible: boolean) => void;
  discretePropertyValues: DiscretePropertyValue[];
  onSwapClick?: () => void;
  columnLabelMap?: IColumnLabelMap;
}

export const ColorizingPanel: FC<IColorizingPanelProps> = ({
  onGradientChange,
  onColorizingModeChange,
  handleColorizingPanelVisibleChange,
  onToggleColorizing,
  discretePropertyValues,
  onDiscreteValueColorChange,
  tag,
  onSwapClick,
  columnLabelMap,
}) => {
  const currentGradient = tag.isScoredColorizingMode
    ? tag.gradient.scoredGradient
    : tag.gradient.datasetGradient;

  const onColorizingModeToggle = useCallback(() => {
    if (tag.isScoredColorizingMode) {
      onColorizingModeChange(false);
    } else {
      onColorizingModeChange(true);
    }
  }, [tag.isScoredColorizingMode, onColorizingModeChange]);

  const onClose = useCallback(() => {
    handleColorizingPanelVisibleChange(false);
    onToggleColorizing(false);
  }, [handleColorizingPanelVisibleChange, onToggleColorizing]);

  const onChangeGradient = useCallback(
    (newGradient: IGradientColor[]) => {
      const changedGradientName = tag.isScoredColorizingMode
        ? 'scoredGradient'
        : 'datasetGradient';
      const changedGradient = { ...tag.gradient, [changedGradientName]: newGradient };

      onGradientChange(changedGradient);
    },
    [tag.gradient, tag.isScoredColorizingMode, onGradientChange]
  );

  const showSwapButton = useMemo(() => {
    return !tag.isDiscreteProperty && !tag.isScoredColorizingMode && onSwapClick;
  }, [onSwapClick, tag.isDiscreteProperty, tag.isScoredColorizingMode]);

  const label = columnLabelMap?.[tag.columnId]?.label || tag.columnId;

  return (
    <AntComponentsProvider>
      <div className={styles.root}>
        <div className={styles.caption}>
          <span>{label}</span>
        </div>
        <div className={styles.header}>
          <Checkbox
            checked={tag.isScoredColorizingMode}
            className={styles.checkbox}
            disabled={!tag.isScoreAvailable || !tag.isDatasetAvailable}
            onChange={onColorizingModeToggle}
          >
            Scored data
          </Checkbox>
          {showSwapButton ? (
            <Button icon={<SwapOutlined />} size={'small'} onClick={onSwapClick}></Button>
          ) : null}
        </div>
        {((tag.isDiscreteProperty && tag.isScoredColorizingMode) ||
          !tag.isDiscreteProperty) && (
          <div
            className={classNames(styles.gradientScaleWrap, styles.colorizingPanelColors)}
          >
            <GradientScale
              gradient={currentGradient}
              isScoredColorizingMode={tag.isScoredColorizingMode}
              range={tag.range}
              onGradientChange={onChangeGradient}
            />
          </div>
        )}
        {tag.isDiscreteProperty && !tag.isScoredColorizingMode && (
          <span className={styles.discretePropertyColorizingConfigColors}>
            <DiscretePropertyColorizingConfig
              discretePropertyValues={discretePropertyValues}
              onColorChange={onDiscreteValueColorChange}
            />
          </span>
        )}
        <DeleteFilled className={styles.deleteIcon} onClick={onClose} />
      </div>
    </AntComponentsProvider>
  );
};
