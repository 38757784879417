import { DownOutlined, UpOutlined } from '@ant-design/icons';
import {
  ColumnId,
  IColumnLabelMap,
  COMPARISON_SCORE_COLUMN_ID,
  DTNodeType,
} from '@discngine/moosa-models';
import { Input } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { DragSourceMonitor } from 'react-dnd';

import { CANVAS_LOCATION, COMPARE_OPERATORS } from '../../constants';
import { DropResult } from '../../types';
import { getColumnLabel } from '../../utils';

import { OperatorItem } from './OperatorItem';
import styles from './PropertiesPanel.module.less';
import { PropertyItem } from './PropertyItem';

interface IPropertiesPanelProps {
  columnIds: ColumnId[];
  columnLabelMap?: IColumnLabelMap;
  onPropertyAdd: (property: ColumnId, result?: DropResult) => void;
  onOperatorAdd: (
    nodeType:
      | DTNodeType.Or
      | DTNodeType.And
      | DTNodeType.ALN
      | DTNodeType.AMX
      | DTNodeType.Chart,
    result?: DropResult
  ) => void;
}

export const PropertiesPanel: FC<IPropertiesPanelProps> = ({
  columnIds,
  columnLabelMap,
  onPropertyAdd,
  onOperatorAdd,
}) => {
  const [isPropertiesOpen, setIsPropertiesOpen] = useState(true);
  const [isOperatorsOpen, setIsOperatorsOpen] = useState(true);
  const [filter, setFilter] = useState('');

  const filteredColumns = useMemo(
    () =>
      columnIds.filter((columnId) => {
        const query = filter.toUpperCase();

        if (columnId === COMPARISON_SCORE_COLUMN_ID) return false;

        return getColumnLabel(columnId, columnLabelMap).toUpperCase().includes(query);
      }),
    [columnIds, columnLabelMap, filter]
  );

  const handlePropertyAdd = useCallback(
    (propertyId: ColumnId, monitor?: DragSourceMonitor<any, DropResult>) => {
      const result = monitor?.getDropResult();

      if (monitor) {
        if (result?.location === CANVAS_LOCATION) {
          onPropertyAdd(propertyId, result);
        }
      } else {
        onPropertyAdd(propertyId);
      }
    },
    [onPropertyAdd]
  );

  const handleOperatorAdd = useCallback(
    (
      operatorType:
        | DTNodeType.Or
        | DTNodeType.And
        | DTNodeType.ALN
        | DTNodeType.AMX
        | DTNodeType.Chart,
      monitor?: DragSourceMonitor<any, DropResult>
    ) => {
      if (monitor) {
        const result = monitor.getDropResult();

        if (result?.location === CANVAS_LOCATION) {
          onOperatorAdd(operatorType, result);
        }
      } else {
        onOperatorAdd(operatorType);
      }
    },
    [onOperatorAdd]
  );

  return (
    <>
      <div className={styles.dropDownTitle}>
        <h3 className={styles.columnsGroupTitle}>Operators</h3>
        <div
          className={styles.dropDownBtn}
          onClick={() => setIsOperatorsOpen((isOperatorsOpen) => !isOperatorsOpen)}
        >
          {isOperatorsOpen ? <UpOutlined /> : <DownOutlined />}
        </div>
      </div>

      {isOperatorsOpen && (
        <ul className={styles.operatorsWrapper}>
          {COMPARE_OPERATORS.map((operator) => (
            <OperatorItem
              key={operator.name}
              name={operator.name}
              type={operator.type}
              onDoubleClick={() => handleOperatorAdd(operator.type)}
              onDrugEnd={(monitor: any) => handleOperatorAdd(operator.type, monitor)}
            />
          ))}
        </ul>
      )}
      <div className={styles.dropDownTitle}>
        <h3 className={styles.columnsGroupTitle}>Data Set Columns</h3>
        <div
          className={styles.dropDownBtn}
          onClick={() => setIsPropertiesOpen((isPropertiesOpen) => !isPropertiesOpen)}
        >
          {isPropertiesOpen ? <UpOutlined /> : <DownOutlined />}
        </div>
      </div>

      {isPropertiesOpen && (
        <>
          <Input.Search
            className={styles.filterInput}
            placeholder="Parameter"
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
          />
          <ul className={styles.visibilityViewList}>
            {filteredColumns.map((propertyId) => (
              <PropertyItem
                key={propertyId}
                colId={propertyId}
                columnName={getColumnLabel(propertyId, columnLabelMap)}
                onDoubleClick={() => handlePropertyAdd(propertyId)}
                onDrugEnd={(monitor) => handlePropertyAdd(propertyId, monitor)}
              />
            ))}
          </ul>
        </>
      )}
    </>
  );
};
