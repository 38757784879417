import { IColorPalette } from '@discngine/moosa-common';
import { DatasetRowId, IColumnHeader } from '@discngine/moosa-models';
import { IRowsAndScores } from '@discngine/moosa-store/tableData';
import { tableInfoSlice } from '@discngine/moosa-store/tableInfo';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CalculationState,
  ChartType,
  IChartRange,
  IChartsState,
  IScatterPlotPoint,
  ISmartChartOptions,
  SizeSortOrder,
} from './IChartsState';

export const DEFAULT_CHART_POINT_COLOR = 'rgba(77, 108, 132, 0.3)';

const initialChartRange: IChartRange = {
  minX: Number.POSITIVE_INFINITY,
  maxX: Number.NEGATIVE_INFINITY,
  minY: Number.POSITIVE_INFINITY,
  maxY: Number.NEGATIVE_INFINITY,
};

const initialState: IChartsState = {
  isShown: false,
  isLoading: false,
  isFirstOpen: true,
  currentChartType: ChartType.SmartChart,
  fullDataset: { rows: {}, scores: {}, total: 0 },
  smartChartState: {
    points: [],
    rowIds: [],
    options: {
      iterations: 100,
      neighbors: 15,
      minDist: 0.1,
    },
    colorPalette: {
      startAngle: 45,
      endAngle: 315,
    },
    colorColumn: null,
    sizeColumn: null,
    sizeSortOrder: SizeSortOrder.asc,
    range: initialChartRange,
    currentIteration: 0,
    maxIterations: 0,
    calculationState: CalculationState.None,
  },
  scatterPlotState: {
    points: [],
    rowIds: [],
    columnX: null,
    columnY: null,
    colorColumn: null,
    sizeColumn: null,
    colorPalette: {
      startAngle: 45,
      endAngle: 315,
    },
    sizeSortOrder: SizeSortOrder.asc,
  },
};

export const chartsSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    /** Common **/

    toggleChartMode(state, action: PayloadAction<boolean>) {
      state.isShown = action.payload;
    },
    toggleChartLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    toggleChartFirstOpen(state, action: PayloadAction<boolean>) {
      state.isFirstOpen = action.payload;
    },
    selectChartType(state, action: PayloadAction<ChartType>) {
      state.currentChartType = action.payload;
    },
    setFullDataset(state, action: PayloadAction<IRowsAndScores>) {
      state.fullDataset = action.payload;
    },

    /** SmartChart **/

    setSmartChartIterations(
      state,
      action: PayloadAction<{ max: number; current: number }>
    ) {
      state.smartChartState.currentIteration = action.payload.current;
      state.smartChartState.maxIterations = action.payload.max;
      state.smartChartState.calculationState = CalculationState.InProgress;
    },
    setSmartChartOptions(state, action: PayloadAction<ISmartChartOptions>) {
      state.smartChartState.options = action.payload;
    },
    setSmartChartState(state, action: PayloadAction<CalculationState>) {
      state.smartChartState.calculationState = action.payload;
    },
    setSmartChartColorColumn(state, action: PayloadAction<IColumnHeader | null>) {
      state.smartChartState.colorColumn = action.payload;
    },
    setSmartChartSizeColumn(state, action: PayloadAction<IColumnHeader | null>) {
      state.smartChartState.sizeColumn = action.payload;
    },
    setSmartChartSizeOrder(state, action: PayloadAction<SizeSortOrder>) {
      state.smartChartState.sizeSortOrder = action.payload;
    },
    setSmartChartColorPalette(state, action: PayloadAction<IColorPalette>) {
      state.smartChartState.colorPalette = action.payload;
    },
    setSmartChartData(
      state,
      action: PayloadAction<{ result: number[][]; rowIds: DatasetRowId[] }>
    ) {
      const chartRange = { ...initialChartRange };

      state.smartChartState.rowIds = action.payload.rowIds;
      state.smartChartState.points = (action.payload.result || []).map(([x, y]) => {
        chartRange.maxX = Math.max(x, chartRange.maxX);
        chartRange.minX = Math.min(x, chartRange.minX);
        chartRange.maxY = Math.max(y, chartRange.maxY);
        chartRange.minY = Math.min(y, chartRange.minY);

        const point: IScatterPlotPoint = {
          x,
          y,
          size: 2,
          color: DEFAULT_CHART_POINT_COLOR,
        };

        return point;
      });
      state.smartChartState.range = chartRange;
      state.smartChartState.calculationState = CalculationState.Finished;
    },
    overwriteSmartChartData(state, action: PayloadAction<IScatterPlotPoint[]>) {
      state.smartChartState.points = action.payload;
    },

    /** Scatter Plot **/

    setScatterPlotColumnX(state, action: PayloadAction<IColumnHeader | null>) {
      state.scatterPlotState.columnX = action.payload;
    },
    setScatterPlotColumnY(state, action: PayloadAction<IColumnHeader | null>) {
      state.scatterPlotState.columnY = action.payload;
    },
    toggleScatterPlotColumns(state) {
      const columnY = state.scatterPlotState.columnY;

      state.scatterPlotState.columnY = state.scatterPlotState.columnX;
      state.scatterPlotState.columnX = columnY;
    },
    setScatterPlotColorColumn(state, action: PayloadAction<IColumnHeader | null>) {
      state.scatterPlotState.colorColumn = action.payload;
    },
    setScatterPlotColorPalette(state, action: PayloadAction<IColorPalette>) {
      state.scatterPlotState.colorPalette = action.payload;
    },
    setScatterPlotSizeColumn(state, action: PayloadAction<IColumnHeader | null>) {
      state.scatterPlotState.sizeColumn = action.payload;
    },
    setScatterPlotSizeOrder(state, action: PayloadAction<SizeSortOrder>) {
      state.scatterPlotState.sizeSortOrder = action.payload;
    },
    overwriteScatterPlotData(state, action: PayloadAction<IScatterPlotPoint[]>) {
      state.scatterPlotState.points = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(tableInfoSlice.actions.initTableInfo.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const {
  toggleChartMode,
  toggleChartLoading,
  toggleChartFirstOpen,
  selectChartType,
  setFullDataset,
  // Smart chart
  setSmartChartOptions,
  setSmartChartState,
  setSmartChartColorColumn,
  setSmartChartSizeColumn,
  setSmartChartSizeOrder,
  setSmartChartIterations,
  setSmartChartData,
  overwriteSmartChartData,
  setSmartChartColorPalette,
  // Scatter plot
  setScatterPlotColumnX,
  setScatterPlotColumnY,
  toggleScatterPlotColumns,
  setScatterPlotColorColumn,
  setScatterPlotColorPalette,
  setScatterPlotSizeColumn,
  setScatterPlotSizeOrder,
  overwriteScatterPlotData,
} = chartsSlice.actions;
