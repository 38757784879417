import {
  AimOutlined,
  ExpandAltOutlined,
  RetweetOutlined,
  ShrinkOutlined,
  WarningFilled,
} from '@ant-design/icons';
import { classNames } from '@discngine/moosa-common';
import {
  ColumnId,
  DTGroupOption,
  DTNodeId,
  DTNodeType,
  DatasetRowId,
  DecisionTreeGroupedResult,
  DecisionTreeGroups,
  DecisionTreeResult,
  IColumnLabelMap,
} from '@discngine/moosa-models';
import { Button, Dropdown, Space } from 'antd';
import go from 'gojs';
import { ReactDiagram, ReactOverview } from 'gojs-react';
import {
  MISMATCH_CRITICAL,
  MISMATCH_WARNING,
  NODE_CONDITION_MISMATCH_ERROR,
  NODE_CONDITION_MISMATCH_WARNING,
} from 'lib/go/colors';
import { andNodeTemplate } from 'lib/go/templates/and';
import { atLeastNNodeTemplate } from 'lib/go/templates/atLeastN';
import { atMostXNodeTemplate } from 'lib/go/templates/atMostX';
import { orNodeTemplate } from 'lib/go/templates/or';
import { initDiagramOverview, setDiagramLayout, updateModelData } from 'lib/go/utils';
import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { applyUpdatesOnDiagramState, getMismatchDescription } from 'utils';
import { v4 as uuid } from 'uuid';

import { DEFAULT_DIAGRAM_DATA, DEFAULT_HISTOGRAM_VIEW } from '../../constants';
import { initDiagram } from '../../lib/go/initDiagram';
import { chartNodeTemplate } from '../../lib/go/templates/chart';
import { dateNodeTemplate } from '../../lib/go/templates/date';
import { propertyNodeTemplate } from '../../lib/go/templates/property';
import { structureNodeTemplate } from '../../lib/go/templates/structure';
import {
  DiagramArrowData,
  DiagramHistogramView,
  DiagramLayoutType,
  DiagramNodeData,
  DiagramState,
  MenuItem,
  NodeConditionMismatchLevel,
} from '../../types';

import styles from './DiagramWrapper.module.less';
import { GroupSelector } from './GroupSelector/GroupSelector';
import { HistogramViewSelector } from './HistogramViewSelector/HistogramViewSelector';
import { useDiagramDragDrop } from './hooks/useDiagramDragDrop';
import { LayoutMode } from './LayoutMode/LayoutMode';
import { NodeMenu } from './NodeMenu/NodeMenu';
import { UndoRedo } from './UndoRedo/UndoRedo';

interface DiagramWrapperProps {
  /**
   * Retrieves the menu items for the decision tree node based on nodeId and propertyId of the node.
   *
   * @param nodeId - The decision tree node identifier
   * @param propertyId - The decision tree node property id
   * @returns Array of menu items for the decision tree node.
   */
  getNodeMenu?: (nodeId: DTNodeId, propertyId: ColumnId) => MenuItem[];

  /**
   * custom names for dataset columns
   */
  columnLabelMap?: IColumnLabelMap;

  /**
   * The state of the diagram.
   */
  diagramState: DiagramState;

  /**
   * The state of the diagram minimap view.
   */
  isOverviewCollapsed: boolean;

  /**
   * A function to update the diagram minimap view.
   *
   * @param state - The new state of the diagram.
   */
  onIsOverviewCollapsedChange: (isCollapsed: boolean) => void;

  /**
   * A function to update the diagram state.
   *
   * @param state - The new state of the diagram.
   */
  onDiagramStateChange: (diagramState: DiagramState) => void;

  /**
   * An array of group options.
   */
  groups: DecisionTreeGroups | null;

  /**
   * Selected group column id
   */
  groupColumnId?: ColumnId | null;

  /**
   * Selected group or null if no group is selected.
   */
  selectedGroup: DTGroupOption | null;

  /**
   * The callback function for selecting a group.
   *
   * @param group - Selected group of null to reset group selection
   */
  onGroupSelect: (group: DTGroupOption | null) => void;

  /**
   * Retrieves the groups of compounds based on the provided result of the decision tree.
   *
   * @param result - The result of the current decision tree
   * @returns Groups of compounds passed to the decision tree.
   */
  getGroupedResult: (result: DecisionTreeResult) => DecisionTreeGroupedResult | null;

  /**
   * Retrieves the result of the decision tree based on the provided diagram state.
   *
   * @param diagramState - The diagram state representing the decision tree.
   * @returns The result of the decision tree.
   */
  getDiagramResult: (diagramState: DiagramState) => DecisionTreeResult;

  /**
   * Retrieves the base64 encoded histogram image
   *
   * @param nodeData - Data of the decision tree node
   * @param rowIds - The Set of row ids, displayed on the histogram
   * @returns Histogram image or null if the image cannot be generated
   */
  getHistogram: (nodeData: DiagramNodeData, rowIds: Set<DatasetRowId>) => string | null;

  /**
   * The callback function for selecting a node.
   *
   * @param node - Selected node or null to reset node selection
   */
  onNodeSelect: (nodeData: DiagramNodeData | null) => void;
  onResultsClick?: (
    nodeId: DTNodeId,
    propertyId: ColumnId | null,
    rowIDs: Set<DatasetRowId> | null
  ) => void;
  diagramRef: RefObject<ReactDiagram>;

  licenseKey?: string;
}

interface NodeMenuData {
  nodeId: DTNodeId;
  propertyId: ColumnId;
  items: MenuItem[];
  position: { x: number; y: number };
}

/**
 * A wrapper component that renders a diagram using ReactDiagram and manages its state.
 */
export const DiagramWrapper = ({
  getNodeMenu,
  licenseKey,

  columnLabelMap,

  diagramState,
  onDiagramStateChange,

  isOverviewCollapsed,
  onIsOverviewCollapsedChange,

  diagramRef,

  groups,
  selectedGroup,
  groupColumnId,
  onGroupSelect,

  getGroupedResult,
  getDiagramResult,
  getHistogram,

  onNodeSelect,
  onResultsClick,
}: DiagramWrapperProps) => {
  /**
   * A current state of the decision tree node menu popup.
   */
  const [nodeMenu, setNodeMenu] = useState<NodeMenuData | null>(null);

  /**
   * A current state of the histogram view in decision tree.
   */
  const [histogramView, setHistogramView] =
    useState<DiagramHistogramView>(DEFAULT_HISTOGRAM_VIEW);

  const [isCleared, setIsCleared] = useState(false);
  /**
   * A reference for a ReactDiagram component.
   */
  const diagram = diagramRef?.current?.getDiagram() || null;

  /**
   * Calculates the array of nodes with type mismatches.
   *
   * @returns An array of objects representing the type mismatches: node ID, name, and mismatch.
   */
  const mismatchedNodes = useMemo(() => {
    return diagramState.nodes.filter(
      ({ group, mismatch }) => !group && mismatch !== null
    );
  }, [diagramState.nodes]);

  /**
   * Updating model data to highlight the currently editable node on the canvas.
   */
  useEffect(() => {
    if (!diagram) return;

    updateModelData(diagram, {
      editableNodeId: diagramState.editableNodeId,
    });
  }, [diagram, diagramState.editableNodeId]);

  /**
   * Updating model data to highlight the currently editable node on the canvas.
   */
  useEffect(() => {
    if (!diagram) return;

    updateModelData(diagram, {
      columnLabelMap,
    });
    diagram.rebuildParts();
  }, [diagram, columnLabelMap]);

  /**
   * Retrieves the diagram and updates the node template
   */
  useEffect(() => {
    if (!diagram) return;

    Object.values(DTNodeType).forEach((nodeType) => {
      switch (nodeType) {
        case DTNodeType.Group:
          return;

        case DTNodeType.Property:
          return diagram.nodeTemplateMap.set(
            nodeType,
            propertyNodeTemplate({
              onEditConditionClick: onNodeSelect,
              getHistogram,
              onResultsClick,
              showMenu: getNodeMenu
                ? (object: go.GraphObject, diagram: go.Diagram, tool: go.Tool) => {
                    const nodeData = object.part?.data as DiagramNodeData | undefined;

                    if (!nodeData) {
                      tool.doCancel();

                      return;
                    }

                    if (diagram.lastInput.event instanceof MouseEvent) {
                      const { clientX: x, clientY: y } = diagram.lastInput.event;

                      setNodeMenu({
                        nodeId: nodeData.key,
                        propertyId: nodeData.text,
                        position: { x, y },
                        items: getNodeMenu(nodeData.key, nodeData.text),
                      });
                    }
                  }
                : undefined,
              hideMenu: () => {
                setNodeMenu(null);
              },
            })
          );

        case DTNodeType.Date:
          return diagram.nodeTemplateMap.set(
            nodeType,
            dateNodeTemplate({
              onEditConditionClick: onNodeSelect,
              getHistogram,
              onResultsClick,
              showMenu: getNodeMenu
                ? (object: go.GraphObject, diagram: go.Diagram, tool: go.Tool) => {
                    const nodeData = object.part?.data as DiagramNodeData | undefined;

                    if (!nodeData) {
                      tool.doCancel();

                      return;
                    }

                    if (diagram.lastInput.event instanceof MouseEvent) {
                      const { clientX: x, clientY: y } = diagram.lastInput.event;

                      setNodeMenu({
                        nodeId: nodeData.key,
                        propertyId: nodeData.text,
                        position: { x, y },
                        items: getNodeMenu(nodeData.key, nodeData.text),
                      });
                    }
                  }
                : undefined,
              hideMenu: () => {
                setNodeMenu(null);
              },
            })
          );

        case DTNodeType.StructureSearch:
          return diagram.nodeTemplateMap.set(
            nodeType,
            structureNodeTemplate({
              onEditConditionClick: onNodeSelect,
              onResultsClick,
              showMenu: getNodeMenu
                ? (object: go.GraphObject, diagram: go.Diagram, tool: go.Tool) => {
                    const nodeData = object.part?.data as DiagramNodeData | undefined;

                    if (!nodeData) {
                      tool.doCancel();

                      return;
                    }

                    if (diagram.lastInput.event instanceof MouseEvent) {
                      const { clientX: x, clientY: y } = diagram.lastInput.event;

                      setNodeMenu({
                        nodeId: nodeData.key,
                        propertyId: nodeData.text,
                        position: { x, y },
                        items: getNodeMenu(nodeData.key, nodeData.text),
                      });
                    }
                  }
                : undefined,
              hideMenu: () => {
                setNodeMenu(null);
              },
            })
          );

        case DTNodeType.Chart:
          return diagram.nodeTemplateMap.set(
            nodeType,
            chartNodeTemplate({
              onEditConditionClick: onNodeSelect,
              getHistogram,
              onResultsClick,
            })
          );

        case DTNodeType.ALN:
          return diagram.nodeTemplateMap.set(
            nodeType,
            atLeastNNodeTemplate({ onResultsClick })
          );

        case DTNodeType.AMX:
          return diagram.nodeTemplateMap.set(
            nodeType,
            atMostXNodeTemplate({ onResultsClick })
          );

        case DTNodeType.And:
          return diagram.nodeTemplateMap.set(
            nodeType,
            andNodeTemplate({ onResultsClick })
          );

        case DTNodeType.Or:
          return diagram.nodeTemplateMap.set(
            nodeType,
            orNodeTemplate({ onResultsClick })
          );

        default:
          ((x: never) => {
            throw new Error('Unexpected node type');
          })(nodeType);
      }
    });

    diagram.rebuildParts();
  }, [diagram, getHistogram, getNodeMenu, onNodeSelect, onResultsClick]);

  /**
   * Callback function invoked when the model of the diagram changes.
   * Updates the diagram state based on the incremental data.
   *
   * @param incrementalData - The incremental data representing the changes in the diagram.
   */
  const onModelChange = useCallback(
    (incrementalData: go.IncrementalData) => {
      if (Object.keys(incrementalData).length === 1 && incrementalData.modelData) {
        // Prevent diagram state updates if updates only in model data

        return;
      }

      onDiagramStateChange(
        applyUpdatesOnDiagramState(diagramState, incrementalData, diagram)
      );
    },
    [diagramState, onDiagramStateChange, diagram]
  );

  /**
   * Monitors the diagram for dropped elements.
   * Provides the DropResult to the DnDProvider.
   *
   * @param diagram - The observable diagram.
   */
  const [, dropRef] = useDiagramDragDrop(diagram);

  /**
   * A side effect hook that sets the decision tree result when the diagram state updates.
   */
  useEffect(() => {
    if (!diagram) return;

    const result = getDiagramResult(diagramState);

    updateModelData(diagram, {
      result,
      groups: getGroupedResult(result),
    });
  }, [diagram, diagramState, getGroupedResult, getDiagramResult, selectedGroup]);

  /**
   * Handles a change in the layout mode of a diagram.
   *
   * @param layoutMode - The new layout mode.
   */
  const onLayoutModeChange = useCallback(
    (layoutMode: DiagramLayoutType) => {
      if (!diagram || diagramState.layout === layoutMode) return;

      onDiagramStateChange({
        ...diagramState,
        layout: layoutMode,
      });
    },
    [diagram, diagramState, onDiagramStateChange]
  );

  /**
   * Applies diagram layout from diagram state to GoJS diagram.
   */
  useEffect(() => {
    if (!diagram) return;

    setDiagramLayout(diagram, diagramState.layout);
  }, [diagram, diagramState.layout]);

  /**
   * Applies histogram view to GoJS diagram.
   */
  useEffect(() => {
    if (!diagram) return;

    updateModelData(diagram, {
      histogramView,
    });

    diagram.rebuildParts();
  }, [diagram, histogramView]);

  useEffect(() => {
    if (!diagram) return;

    if (diagramState.name === '' && diagramState.versionName === undefined) {
      if (!isCleared) {
        diagram.clear();
        setIsCleared(true);
      }
    } else {
      setIsCleared(false);
    }
  }, [diagram, diagramState.name, diagramState.versionName, isCleared]);

  /**
   * Generates the name of the decision tree with the version name if available.
   */
  const treeName = useMemo(() => {
    let name = diagramState.name;

    if (diagramState.versionName) {
      name += ` (${diagramState.versionName})`;
    }

    return name;
  }, [diagramState.name, diagramState.versionName]);

  /**
   * Checks if there are any critical mismatches in the node conditions and metadata types.
   */
  const hasCriticalMismatches = useMemo(() => {
    return mismatchedNodes.some(
      ({ mismatch }) => mismatch?.level === NodeConditionMismatchLevel.Critical
    );
  }, [mismatchedNodes]);

  /**
   * Determines whether the diagram can be summarized based on the number of property nodes.
   */
  const canSummarise = useMemo(() => {
    const propertyNodes = diagramState.nodes.filter(
      (node) => !node.group && node.category === DTNodeType.Property
    );

    return propertyNodes.length >= 3;
  }, [diagramState.nodes]);

  /**
   * Regenerate summarized tree
   */
  const summarizeClickHandler = useCallback(() => {
    if (!diagram) return;

    const nodeSize = { x: 350, y: 450 };
    const perRow = 4;
    let x = 0;
    let y = 500;

    const groupNode: DiagramNodeData = {
      isGroup: true,
      key: DTNodeType.Group,
      category: DTNodeType.Group,
      text: 'Summary',
      position: { x: 0, y: 0 }, // TODO: calculate for manual layout
      condition: null,
      portGroupingType: null,
      structure: null,
      structSvg: null,
      mismatch: null,
      nodeN: null,
      columnId: null,
      showFullDataset: false,
    };

    const removeNodes: DiagramNodeData[] = [];
    const removeLinks: DiagramArrowData[] = [];
    const nodes: DiagramNodeData[] = [];

    const linksModel = diagram.model as go.GraphLinksModel;

    (linksModel.linkDataArray as DiagramArrowData[]).forEach((link) => {
      if (link.group === groupNode.key) {
        removeLinks.push(link);
      }
    });

    (linksModel.nodeDataArray as DiagramNodeData[]).forEach((node, index) => {
      if (node.category === DTNodeType.Group) return;

      if (node.group === groupNode.key) {
        removeNodes.push(node);
      } else {
        if (node.category === DTNodeType.Property) {
          const propNode: DiagramNodeData = {
            key: uuid(),
            category: node.category,
            text: node.text,
            condition: node.condition,
            portGroupingType: node.portGroupingType,
            position: { x, y },
            mismatch: node.mismatch,
            group: groupNode.key,
            nodeN: null,
            structure: null,
            structSvg: null,
            columnId: null,
            showFullDataset: false,
          };

          nodes.push(propNode);

          x += nodeSize.x;

          if (index % perRow === perRow - 1) {
            x = 0;
            y += nodeSize.y;
          }
        }
      }
    });

    const alnNode: DiagramNodeData = {
      key: uuid(),
      category: DTNodeType.ALN,
      text: '',
      condition: null,
      portGroupingType: null,
      structure: null,
      structSvg: null,
      mismatch: null,
      position: { x: (x - nodeSize.x) / 2, y: y + nodeSize.y },
      nodeN: Math.min(nodes.length, 3),
      columnId: null,
      group: groupNode.key,
      showFullDataset: false,
    };

    const arrows: DiagramArrowData[] = nodes.map((node) => ({
      group: groupNode.key,
      key: uuid(),
      from: node.key,
      fromPort: 'yes',
      to: alnNode.key,
      toPort: 'input',
    }));

    nodes.push(alnNode);

    if (!diagram.model.findNodeDataForKey(groupNode.key)) {
      nodes.push(groupNode);
    }

    linksModel.commit((_model) => {
      const model = _model as go.GraphLinksModel;

      model.removeNodeDataCollection(removeNodes);
      model.removeLinkDataCollection(removeLinks);
      model.addNodeDataCollection(nodes);
      model.addLinkDataCollection(arrows);
    });

    const groups = diagram.findTopLevelGroups();

    if (groups) {
      const group = groups.first();

      if (group) {
        diagram.clearSelection();
        diagram.centerRect(group.actualBounds);
      }
    }
  }, [diagram]);

  return (
    <div ref={dropRef} className={styles.root}>
      {diagram && (
        <div className={styles.diagramControlsWrapper}>
          <Space size={20}>
            <UndoRedo diagram={diagram} />

            <Button
              disabled={!canSummarise}
              icon={<RetweetOutlined />}
              title={!canSummarise ? 'There should be at least 3 property nodes' : ''}
              onClick={summarizeClickHandler}
            >
              Summarize
            </Button>
          </Space>

          <Space size={20}>
            {groups && (
              <GroupSelector
                columnId={groupColumnId}
                columnLabelMap={columnLabelMap}
                groups={groups}
                selectedGroup={selectedGroup}
                onGroupSelect={onGroupSelect}
              />
            )}

            <HistogramViewSelector
              view={histogramView}
              views={Object.values(DiagramHistogramView)}
              onSelect={setHistogramView}
            />

            <LayoutMode
              diagram={diagram}
              layoutMode={diagramState.layout}
              onLayoutModeChange={onLayoutModeChange}
            />
          </Space>
        </div>
      )}

      {mismatchedNodes.length > 0 && (
        <div
          className={styles.nodeMismatches}
          style={{
            backgroundColor: hasCriticalMismatches
              ? NODE_CONDITION_MISMATCH_ERROR
              : NODE_CONDITION_MISMATCH_WARNING,
          }}
        >
          <Space size={4} wrap={true}>
            <Dropdown
              menu={{
                items: mismatchedNodes.map(({ key, text: nodeName, mismatch }) => ({
                  key,
                  label: (
                    <div className={styles.nodeMismatchRow}>
                      <Space size={10}>
                        <div
                          className={styles.nodeMismatchLevel}
                          style={{
                            color:
                              mismatch?.level === NodeConditionMismatchLevel.Critical
                                ? MISMATCH_CRITICAL
                                : MISMATCH_WARNING,
                          }}
                        >
                          <WarningFilled />
                        </div>

                        <div>
                          <div className={styles.nodeMismatchColumn}>{nodeName}</div>
                          <div className={styles.nodeMismatchType}>
                            {mismatch && getMismatchDescription(mismatch)}
                          </div>
                        </div>
                      </Space>
                    </div>
                  ),
                })),
                selectable: true,
                selectedKeys: [selectedGroup ? selectedGroup.key : ''],
                onSelect: (event) => {
                  if (!diagram) return;

                  const node = diagram.findNodeForKey(event.key);

                  if (!node) return;

                  diagram.clearSelection();
                  node.isSelected = true;
                  diagram.centerRect(node.actualBounds);
                },
              }}
              overlayClassName={styles.nodeMismatchesDropdown}
              placement="bottomLeft"
              trigger={['click']}
            >
              <Space size={8}>
                <WarningFilled
                  style={{
                    color: hasCriticalMismatches ? MISMATCH_CRITICAL : MISMATCH_WARNING,
                  }}
                />

                <span className={styles.nodeMismatchesCount}>
                  {mismatchedNodes.length} node mismatches
                </span>
              </Space>
            </Dropdown>

            <span>
              for <b>{treeName}</b> Decision tree in the <b>current dataset</b>
            </span>
          </Space>
        </div>
      )}

      <div className={styles.diagramWrapper}>
        <ReactDiagram
          ref={diagramRef}
          divClassName={styles.diagram}
          initDiagram={() => initDiagram(diagramState, licenseKey)}
          linkDataArray={diagramState.arrows}
          modelData={diagram?.model.modelData || DEFAULT_DIAGRAM_DATA}
          nodeDataArray={diagramState.nodes}
          skipsDiagramUpdate={diagramState.skipsDiagramUpdate}
          onModelChange={onModelChange}
        />

        {nodeMenu && (
          <NodeMenu
            cancel={() => {
              if (diagram?.currentTool instanceof go.ContextMenuTool) {
                diagram.currentTool.doCancel();
              }
            }}
            items={nodeMenu.items}
            nodeId={nodeMenu.nodeId}
            position={nodeMenu.position}
            propertyId={nodeMenu.propertyId}
          />
        )}
      </div>

      <div
        className={classNames(styles.diagramOverviewWrapper, {
          [styles.diagramOverviewCollapsed]: isOverviewCollapsed,
        })}
      >
        <Button
          className={styles.diagramOverviewCollapseButton}
          icon={isOverviewCollapsed ? <ExpandAltOutlined /> : <ShrinkOutlined />}
          title={isOverviewCollapsed ? 'Expand minimap' : 'Collapse minimap'}
          onClick={() => onIsOverviewCollapsedChange(!isOverviewCollapsed)}
        />
        <Button
          className={styles.diagramCenterButton}
          icon={<AimOutlined />}
          title="Centering viewport"
          onClick={() => {
            if (!diagram) return;

            diagram.zoomToRect(diagram.documentBounds);
          }}
        />

        <ReactOverview
          divClassName={styles.diagramOverview}
          initOverview={initDiagramOverview}
          observedDiagram={diagram}
        />
      </div>
    </div>
  );
};
