import { v4 as uuid } from 'uuid';

import {
  DesirabilityFunctionRules,
  CustomCurveFunctionParams,
  IRange,
  ILinePoint,
  ServerLinePoint,
} from '../scoringTemplate';
import { IColumnMetaInfo } from '../tableInfo';

import {
  assignIndices,
  checkXBoundaries,
  checkYBoundaries,
  getRangeForLinearApprox,
  getValueFromLinearApprox,
  pointsCompare,
  removeIndices,
  toLine,
  updateLinePoint,
} from './rulesUtils';

export const customCurveFunctionRules: DesirabilityFunctionRules<CustomCurveFunctionParams> =
  {
    init(metadata: IColumnMetaInfo): CustomCurveFunctionParams {
      const params: CustomCurveFunctionParams = {
        points: [
          { x: metadata.statistics!.min, y: 0, originalPointIndex: 0, id: uuid() },
          { x: metadata.statistics!.max, y: 1, originalPointIndex: 1, id: uuid() },
        ],
      };

      return params;
    },
    getValue(xVal: number, params: CustomCurveFunctionParams): number {
      return getValueFromLinearApprox(xVal, params.points);
    },
    getRange(params: CustomCurveFunctionParams, metadata: IColumnMetaInfo): IRange {
      return getRangeForLinearApprox(params.points, metadata);
    },
    toLine(params: CustomCurveFunctionParams, range: IRange): ILinePoint[] {
      return toLine(params.points, range);
    },
    toServerTemplate(params: CustomCurveFunctionParams): ServerLinePoint[] {
      return removeIndices(params.points);
    },
    fromServerTemplate(points: ILinePoint[]): CustomCurveFunctionParams {
      return {
        points: assignIndices(points),
      };
    },
    movePoint(params: CustomCurveFunctionParams, point: ILinePoint) {
      return updateLinePoint(params.points, point) as CustomCurveFunctionParams;
    },
    addPoint(
      params: CustomCurveFunctionParams,
      point: ILinePoint
    ): CustomCurveFunctionParams {
      const newParams: CustomCurveFunctionParams = {
        points: [
          ...params.points,
          {
            x: checkXBoundaries(point.x),
            y: checkYBoundaries(point.y),
            originalPointIndex: point.originalPointIndex,
            id: uuid(),
          },
        ].sort(pointsCompare),
      };

      return newParams;
    },
    removePoint(params: CustomCurveFunctionParams, index: number) {
      if (params.points.length === 2) {
        return params;
      }

      const pointToRemove = params.points.find(
        (point) => point.originalPointIndex === index
      )!;

      if (pointToRemove.y === 1) {
        // check if there are more points with y = 1
        if (!params.points.some((point) => point !== pointToRemove && point.y === 1)) {
          return params;
        }
      }

      const newParams: CustomCurveFunctionParams = {
        points: params.points.filter((point) => point !== pointToRemove),
      };

      return newParams;
    },
  };
