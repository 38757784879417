import { IDecisionTreeConfigSubRoot } from './types';

export const selectDecisionTreeGroupColumnId = (state: IDecisionTreeConfigSubRoot) =>
  state.decisionTreeConfig.columnGroupId;

export const selectDecisionTreeDateColumnId = (state: IDecisionTreeConfigSubRoot) =>
  state.decisionTreeConfig.dateColumnId;

export const selectDecisionTreeDateFormat = (state: IDecisionTreeConfigSubRoot) =>
  state.decisionTreeConfig.dateFormat;

export const selectDecisionTreeDefaultPortGrouping = (
  state: IDecisionTreeConfigSubRoot
) => state.decisionTreeConfig.defaultPortGrouping;
