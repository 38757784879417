import { HistogramView, BarsRerender } from '@discngine/moosa-histogram';
import {
  IColumnMetaInfo,
  IScoringFuncProperty,
  SortFunctionTableType,
  DesirabilityFunctionSingleParams,
  DesirabilityFunctionType,
  IDiscretePoint,
  FieldType,
} from '@discngine/moosa-models';
import React, { FC, useCallback, useState } from 'react';

import { ResponsiveContainer } from '../ResponsiveContainer/ResponsiveContainer';

import styles from './DesirabilityFunctionEditor.module.less';
import DiscreteFunctionTable from './scoringFunctionTables/DiscreteFunctionTable/DiscreteFunctionTable';
import LineSegmentsFunctionTable from './scoringFunctionTables/LineSegmentsFunctionTable';
import LogarithmicFunctionTable from './scoringFunctionTables/LogarithmicFunctionTable';
import SigmoidFunctionTable from './scoringFunctionTables/SigmoidFunctionTable';
import UnitStepFunctionTable from './scoringFunctionTables/UnitStepFunctionTable';

export type DesirabilityFunctionEditorProps = {
  desirability: IScoringFuncProperty;
  metadata: IColumnMetaInfo;
  onParamsChange: (param: DesirabilityFunctionSingleParams) => void;
  onAddFunctionPoint: (param: {
    valueX: number | string;
    valueY: number;
    xSort?: SortFunctionTableType;
    ySort?: SortFunctionTableType;
    originalPointIndex?: number;
    fieldType?: FieldType;
  }) => void;
  onUpdateFunctionPoint: (x: number, y: number, idx: number, id: string) => void;
  onRemoveFunctionPoint: (index: number) => void;
  onMoveFinish: () => void;
  onDiscretePointsOrderChange: (
    columnName: string,
    fromPoint: string | number,
    toPoint: string | number
  ) => void;
  barsRerender?: BarsRerender;
};

export const DesirabilityFunctionEditor: FC<DesirabilityFunctionEditorProps> = React.memo(
  function DesirabilityFunctionEditor({
    desirability,
    metadata,
    onParamsChange,
    onAddFunctionPoint,
    onUpdateFunctionPoint,
    onRemoveFunctionPoint,
    onMoveFinish,
    onDiscretePointsOrderChange,
    barsRerender,
  }) {
    const [isHistogramVisible, setIsHistogramVisible] = useState(true);
    const onToggleHistogramVisible = useCallback(() => {
      setIsHistogramVisible((isHistogramVisible) => !isHistogramVisible);
    }, []);

    const onPointsChange = useCallback(
      (points: IDiscretePoint[]) => {
        onParamsChange({ points });
      },
      [onParamsChange]
    );

    const handleAddHistogramPoint = useCallback(
      (valueX: number, valueY: number, originalPointIndex: number) => {
        if (desirability.type === DesirabilityFunctionType.custom) {
          onAddFunctionPoint({
            valueX,
            valueY,
            originalPointIndex,
            fieldType: FieldType.Number,
          });
        }
      },
      [onAddFunctionPoint, desirability.type]
    );

    const histogram = () => (
      <div className={`moosa-desirability-histogram ${styles.chartRoot}`}>
        <ResponsiveContainer
          barsRerender={barsRerender}
          component={HistogramView}
          desirability={desirability}
          metadata={metadata}
          onAddFunctionPoint={handleAddHistogramPoint}
          onMoveFinish={onMoveFinish}
          onRemoveFunctionPoint={onRemoveFunctionPoint}
          onUpdateFunctionPoint={onUpdateFunctionPoint}
        />
      </div>
    );

    return (
      <>
        {desirability.type === DesirabilityFunctionType.discrete && (
          <>
            <DiscreteFunctionTable
              className="moosa-desirability-table"
              column={desirability.column}
              isHistogramVisible={isHistogramVisible}
              metadata={metadata}
              points={
                desirability.functionParams[DesirabilityFunctionType.discrete]?.points ||
                []
              }
              onAddFunctionPoint={onAddFunctionPoint}
              onFunctionPointsChange={onPointsChange}
              onPointsOrderChange={onDiscretePointsOrderChange}
              onRemoveFunctionPoint={onRemoveFunctionPoint}
              onToggleHistogramVisible={onToggleHistogramVisible}
            />

            {isHistogramVisible && histogram()}
          </>
        )}
        {desirability.type !== DesirabilityFunctionType.discrete && (
          <>
            {histogram()}

            {(desirability.type === DesirabilityFunctionType.linear ||
              desirability.type === DesirabilityFunctionType.triangular ||
              desirability.type === DesirabilityFunctionType.rectangular ||
              desirability.type === DesirabilityFunctionType.custom) && (
              <LineSegmentsFunctionTable
                className="moosa-desirability-table"
                metadata={metadata}
                params={desirability.functionParams[desirability.type]}
                onParamsChange={onParamsChange}
              />
            )}

            {desirability.type === DesirabilityFunctionType.unitStep && (
              <UnitStepFunctionTable
                className="moosa-desirability-table"
                metadata={metadata}
                params={desirability.functionParams.unitStep}
                onParamsChange={onParamsChange}
              />
            )}

            {desirability.type === DesirabilityFunctionType.logarithmic && (
              <LogarithmicFunctionTable
                className="moosa-desirability-table"
                metadata={metadata}
                params={desirability.functionParams.logarithmic}
                onParamsChange={onParamsChange}
              />
            )}

            {desirability.type === DesirabilityFunctionType.sigmoid && (
              <SigmoidFunctionTable
                className="moosa-desirability-table"
                metadata={metadata}
                params={desirability.functionParams.sigmoid}
                onParamsChange={onParamsChange}
              />
            )}
          </>
        )}
      </>
    );
  }
);
