import Icon from '@ant-design/icons';
import { hexToRgb } from '@discngine/moosa-common';
import { rgbToHex } from '@discngine/moosa-common';
import { classNames, IRGBColor } from '@discngine/moosa-common';
import { IColumnLabelMap, IGradient, IMoosaSarConfigTag } from '@discngine/moosa-models';
import { Popover, Tag, Tooltip } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useMemo, useState } from 'react';
import {
  Draggable,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';

import { ColorPickerPure } from '../../../ColorPickerPure/ColorPickerPure';
import styles from '../../MultiplePropertiesSelector.module.less';
import { ReactComponent as ColorPallet } from '../../resources/ColorPallet.svg';
import { ReactComponent as WithoutColor } from '../../resources/WithoutColor.svg';
import { ColorizingPanel } from '../ColorizingPanel/ColorizingPanel';

export interface IMoosaSarConfigTagProps {
  rowIndex: number;
  itemIndex: number;
  onTagDelete: (columnId: string) => void;
  onChange: (tag: IMoosaSarConfigTag) => void;
  tag: IMoosaSarConfigTag;
  draggingItem: { row: number; index: number };
  hideReordering: boolean;
  columnLabelMap?: IColumnLabelMap;
  isSimpleColorSelector?: boolean;
}

export const MoosaSarConfigTag: React.FC<IMoosaSarConfigTagProps> = ({
  rowIndex,
  itemIndex,
  onTagDelete,
  onChange,
  tag,
  draggingItem,
  hideReordering,
  columnLabelMap,
  isSimpleColorSelector = false,
}) => {
  const [isColorizingPanelVisible, setIsColorizingPanelVisible] = useState(false);
  const [isColorizingTooltipVisible, setIsColorizingTooltipVisible] = useState(false);
  const color = useMemo(
    () => rgbToHex(tag.gradient.scoredGradient[0]?.color),
    [tag.gradient.scoredGradient]
  );
  const isWithoutColor =
    !tag.isColored || (!tag.isScoreAvailable && !tag.isDatasetAvailable);

  const handleColorizingPanelVisibleChange = useCallback(
    (newVisible: boolean) => {
      if (!tag.isScoreAvailable && !tag.isDatasetAvailable) {
        return;
      }
      setIsColorizingPanelVisible(newVisible);

      if (newVisible) {
        onChange({ ...tag, isColored: true });
      }
    },
    [onChange, tag]
  );

  const onColorizingModeChange = useCallback(
    (isScoredColorizingMode: boolean) => {
      onChange({ ...tag, isScoredColorizingMode });
    },
    [onChange, tag]
  );

  const onToggleColorizing = useCallback(
    (isColored: boolean) => {
      onChange({ ...tag, isColored });
    },
    [onChange, tag]
  );

  const onDiscreteValueColorChange = useCallback(
    (indexOfSelectedColor: number, color: IRGBColor | null) => {
      const newDiscretePropertyValues = cloneDeep(tag.discretePropertyValues);

      newDiscretePropertyValues[indexOfSelectedColor].color = color;

      onChange({ ...tag, discretePropertyValues: newDiscretePropertyValues });
    },
    [onChange, tag]
  );

  const onGradientChange = useCallback(
    (gradient: IGradient) => {
      onChange({ ...tag, gradient });
    },
    [onChange, tag]
  );

  const onColorChange = useCallback(
    (colorCode: string) => {
      const color = hexToRgb(colorCode);

      onChange({
        ...tag,
        gradient: { scoredGradient: [{ color, percent: 0 }], datasetGradient: [] },
      });
    },
    [onChange, tag]
  );

  const isDragging = useMemo(() => {
    return draggingItem.row === rowIndex && draggingItem.index === itemIndex;
  }, [draggingItem.index, draggingItem.row, itemIndex, rowIndex]);

  const onColorizingTooltipVisibleChange = useCallback(
    (newVisible: boolean) => {
      if (!tag.isScoreAvailable && !tag.isDatasetAvailable) {
        setIsColorizingTooltipVisible(newVisible);
      }
    },
    [tag.isDatasetAvailable, tag.isScoreAvailable]
  );

  const label = columnLabelMap?.[tag.columnId]?.label || tag.columnId;

  const tagNameTooltip =
    tag.columnId === label ? tag.columnId : `${tag.columnId} / ${label}`;

  const onCloseTab = useCallback(
    () => onTagDelete(tag.columnId),
    [onTagDelete, tag.columnId]
  );

  const onSwapColors = useCallback(() => {
    const copyTag = structuredClone(tag);
    const firstColor = copyTag.gradient.datasetGradient[0].color;
    const gradLength = copyTag.gradient.datasetGradient.length;
    const lastColor = copyTag.gradient.datasetGradient[gradLength - 1].color;

    copyTag.gradient.datasetGradient[0].color = lastColor;
    copyTag.gradient.datasetGradient[gradLength - 1].color = firstColor;

    onChange({ ...copyTag });
  }, [onChange, tag]);

  return (
    <Draggable draggableId={rowIndex + ':' + itemIndex} index={itemIndex}>
      {({ innerRef, draggableProps, dragHandleProps }, snapshot) => {
        return (
          <div
            ref={innerRef}
            className={classNames(
              styles.tagElement,
              isDragging && styles.tagElementDragging
            )}
            {...draggableProps}
            {...dragHandleProps}
            style={
              // https://github.com/atlassian/react-beautiful-dnd/issues/374#issuecomment-569817782
              hideReordering
                ? getStyle(draggableProps.style, snapshot)
                : draggableProps.style
            }
          >
            <Tag closable={true} onClose={onCloseTab}>
              {isSimpleColorSelector ? (
                <div style={{ marginRight: '10px' }}>
                  <ColorPickerPure color={color} onColorChange={onColorChange} />
                </div>
              ) : (
                <Popover
                  content={
                    <ColorizingPanel
                      columnLabelMap={columnLabelMap}
                      discretePropertyValues={tag.discretePropertyValues}
                      handleColorizingPanelVisibleChange={
                        handleColorizingPanelVisibleChange
                      }
                      tag={tag}
                      onColorizingModeChange={onColorizingModeChange}
                      onDiscreteValueColorChange={onDiscreteValueColorChange}
                      onGradientChange={onGradientChange}
                      onSwapClick={onSwapColors}
                      onToggleColorizing={onToggleColorizing}
                    />
                  }
                  open={isColorizingPanelVisible}
                  placement="bottom"
                  trigger="click"
                  zIndex={10000}
                  onOpenChange={handleColorizingPanelVisibleChange}
                >
                  <Tooltip
                    mouseEnterDelay={0.4}
                    mouseLeaveDelay={0}
                    open={isColorizingTooltipVisible}
                    style={{ pointerEvents: 'none' }}
                    title={
                      'This property is absent in the scoring template and has only one value in the dataset'
                    }
                    trigger={'click'}
                    onOpenChange={onColorizingTooltipVisibleChange}
                  >
                    <Icon
                      className={`${styles.colorPalletIcon} ${
                        isWithoutColor ? styles.withoutColor : ''
                      }`}
                      component={isWithoutColor ? WithoutColor : ColorPallet}
                    />
                  </Tooltip>
                </Popover>
              )}
              <Tooltip
                mouseEnterDelay={0.4}
                mouseLeaveDelay={0}
                style={{ pointerEvents: 'none' }}
                title={tagNameTooltip}
              >
                <span className={styles.tagLabel}>{label}</span>
              </Tooltip>
            </Tag>
          </div>
        );
      }}
    </Draggable>
  );
};

function getStyle(
  style: DraggingStyle | NotDraggingStyle | undefined,
  snapshot: DraggableStateSnapshot
) {
  if (!snapshot.isDragging) return {};

  if (!snapshot.isDropAnimating) {
    return style;
  }

  return {
    ...style,
    // cannot be 0, but make it super tiny
    transitionDuration: `0.001s`,
  };
}
