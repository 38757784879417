import { v4 as uuid } from 'uuid';

import {
  DesirabilityFunctionRules,
  UnitStepFunctionParams,
  IRange,
  IPoint,
  ILinePoint,
  ServerLinePoint,
} from '../scoringTemplate';
import { IColumnMetaInfo } from '../tableInfo';

import {
  checkXBoundaries,
  checkYBoundaries,
  getRangeForLinearApprox,
} from './rulesUtils';

export const unitStepFunctionRules: DesirabilityFunctionRules<UnitStepFunctionParams> = {
  init(metadata: IColumnMetaInfo): UnitStepFunctionParams {
    const params: UnitStepFunctionParams = {
      xStep: (metadata.statistics!.max + metadata.statistics!.min) / 2,
      yLeft: 0,
      yRight: 1,
    };

    return params;
  },
  getValue(xVal: number, params: UnitStepFunctionParams): number {
    return xVal < params.xStep ? params.yLeft : params.yRight;
  },
  getRange(params: UnitStepFunctionParams, metadata: IColumnMetaInfo): IRange {
    const delta = (metadata.statistics!.max - metadata.statistics!.min) / 20;
    const interestingRange: IPoint[] = [
      { id: uuid(), x: params.xStep - delta, y: 0 },
      { id: uuid(), x: params.xStep + delta, y: 0 },
    ];

    return getRangeForLinearApprox(interestingRange, metadata);
  },
  toLine(params: UnitStepFunctionParams, range: IRange): ILinePoint[] {
    const line: ILinePoint[] = [
      { id: 'left', x: range.min, y: params.yLeft },
      {
        id: 'first',
        x: params.xStep,
        y: params.yLeft,
        mark: true,
        originalPointIndex: 0,
      },
      {
        id: 'second',
        x: params.xStep,
        y: params.yRight,
        mark: true,
        originalPointIndex: 1,
      },
      { id: 'right', x: range.max, y: params.yRight },
    ];

    if (range.min === params.xStep) {
      line.shift();
    }

    if (range.max === params.xStep) {
      line.pop();
    }

    return line;
  },

  toServerTemplate(params: UnitStepFunctionParams): ServerLinePoint[] {
    return [
      { x: params.xStep, y: params.yLeft },
      { x: params.xStep, y: params.yRight },
    ];
  },

  fromServerTemplate(points: ServerLinePoint[]): UnitStepFunctionParams {
    for (let i = 0; i < points.length - 1; ++i) {
      const point = points[i];
      const nextPoint = points[i + 1];

      if (point.x === nextPoint.x) {
        return {
          yLeft: point.y,
          yRight: nextPoint.y,
          xStep: point.x,
        };
      }
    }
    throw new Error('wrong points set for the UniStep function');
  },

  movePoint(
    params: UnitStepFunctionParams,
    { originalPointIndex, x: newX, y: newY }: ILinePoint
  ) {
    const unistepParams = { ...params };

    const x = checkXBoundaries(newX);
    const y = checkYBoundaries(newY);

    if (originalPointIndex === 0 && unistepParams.yLeft < 1) {
      unistepParams.yLeft = y;

      if (y === 0) {
        unistepParams.yRight = 1;
      } else if (y === 1) {
        unistepParams.yRight = 0;
      }
    } else if (originalPointIndex === 1 && unistepParams.yRight < 1) {
      unistepParams.yRight = y;

      if (y === 0) {
        unistepParams.yLeft = 1;
      } else if (y === 1) {
        unistepParams.yLeft = 0;
      }
    }
    unistepParams.xStep = x;

    return unistepParams;
  },
};
