import { Input, InputNumber } from 'antd';
import { ChangeEvent, useCallback } from 'react';

import styles from './RequestConfigEditor.module.less';

const { TextArea } = Input;

export interface IRequestConfigEditorProps {
  maxCompounds: number;
  config: string;
  onChangeConfig: (config: string) => void;
  onChangeMaxCompounds: (maxCompounds: number | null) => void;
}
export const RequestConfigEditor = ({
  config,
  onChangeConfig,
  onChangeMaxCompounds,
  maxCompounds,
}: IRequestConfigEditorProps) => {
  const onChange = useCallback(
    (evt: ChangeEvent<HTMLTextAreaElement>) => {
      onChangeConfig(evt.target.value);
    },
    [onChangeConfig]
  );

  const onMaxCompoundChange = useCallback(
    (value: number | null) => {
      onChangeMaxCompounds(value);
    },
    [onChangeMaxCompounds]
  );
  let text = config;

  try {
    text = JSON.stringify(JSON.parse(config), null, 2);
  } catch (err) {
    console.error(err);
  }

  return (
    <div className={styles.wrapper}>
      <TextArea rows={14} value={text} onChange={onChange} />
      <div className={styles.compounds}>
        <span># of compounds</span>
        <InputNumber
          max={30000}
          min={1}
          value={maxCompounds}
          onChange={onMaxCompoundChange}
        />
      </div>
    </div>
  );
};
