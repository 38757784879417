import {
  DEFAULT_COLUMN_NAMES,
  FieldType,
  IColumnHeader,
  MoosaDataContext,
} from '@discngine/moosa-models';
import { initFromTableMetadata } from '@discngine/moosa-store/scoringTemplate';
import { initTableInfo } from '@discngine/moosa-store/tableInfo';
import {
  MoosaTemplatesPanel,
  MoosaTemplatesPanelContext,
} from '@discngine/moosa-templates-panel';
import React, { FC, useEffect, useState } from 'react';
import { batch, Provider, useDispatch } from 'react-redux';

import { MoosaTemplatesWidgetProps } from './MoosaTemplatesWidgetProps';
import { store } from './store';
import { useTemplatePanelProvider } from './useTemplatePanelProvider';

const MoosaTemplatesWidgetCore: FC<MoosaTemplatesWidgetProps> =
  function MoosaTemplatesWidgetCore({ dataService, datasetId, ...props }) {
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(false);

    const [columnHeaders, setColumnHeaders] = useState<IColumnHeader[]>([]);

    useEffect(() => {
      /* eslint-disable react-hooks/exhaustive-deps */
      const initAsync = async () => {
        // dispatch(toggleTableInfoLoading(true));
        const _columnHeaders: IColumnHeader[] = [];

        try {
          const metadata = await dataService.getDatasetMetadata(datasetId);

          metadata.fields.forEach((field) => {
            _columnHeaders.push({
              columnId: field.name,
              title: DEFAULT_COLUMN_NAMES[field.name] || field.name,
              type: field.type,
              isComputedColumn: !!field.meta?.isComputed,
              isDiscreteColumn: field.statistics?.textCategories !== null,
              isDiscreteStringColumn:
                field.statistics?.textCategories !== null &&
                field.type === FieldType.String,
            });
          });

          batch(() => {
            dispatch(initTableInfo({ data: metadata }));
            dispatch(initFromTableMetadata(metadata));
          });

          // await dispatch(fetchDataWithScores(service.getDataWithScore));
          setColumnHeaders(_columnHeaders);
          setIsLoaded(true);
        } finally {
          // dispatch(toggleTableInfoLoading(false));
        }
      };

      initAsync();
    }, [datasetId, dataService, dispatch]);

    const value = useTemplatePanelProvider({
      ...props,
      columnHeaders,
    });

    return (
      <MoosaDataContext.Provider value={dataService}>
        <MoosaTemplatesPanelContext.Provider value={value}>
          {isLoaded && <MoosaTemplatesPanel />}
        </MoosaTemplatesPanelContext.Provider>
      </MoosaDataContext.Provider>
    );
  };

export const MoosaTemplatesWidget: FC<MoosaTemplatesWidgetProps> =
  function MoosaTemplatesWidget(props) {
    return (
      <Provider store={store}>
        <MoosaTemplatesWidgetCore {...props} />
      </Provider>
    );
  };
