import { DTOperation, SimpleCondition } from '@discngine/moosa-models';
import { InputNumber, Select } from 'antd';
import React, { useCallback } from 'react';

import styles from './shared/PropertyNodeEditPanel.module.less';

export interface SimpleConditionInputProps {
  condition: SimpleCondition;
  disabled: boolean;
  onChange: (condition: SimpleCondition) => void;
}

export const SimpleConditionInput = React.memo(
  ({ condition, disabled, onChange }: SimpleConditionInputProps) => {
    const onOperationChange = useCallback(
      (operation: DTOperation) => {
        onChange({ ...condition, operation });
      },
      [condition, onChange]
    );

    const onThresholdChange = useCallback(
      (value: number | null) => {
        onChange({ ...condition, threshold: value || 0 });
      },
      [condition, onChange]
    );

    return (
      <div className={styles.conditionRow}>
        <span>VALUE</span>

        <InputNumber
          addonBefore={
            <Select
              disabled={disabled}
              style={{ width: 'auto' }}
              value={condition.operation}
              onChange={onOperationChange}
              onSelect={onOperationChange}
            >
              {Object.values(DTOperation).map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          }
          className={styles.input}
          disabled={disabled}
          placeholder="Threshold"
          size="middle"
          style={{ width: '100%' }}
          value={condition.threshold}
          onChange={onThresholdChange}
        />
      </div>
    );
  }
);
