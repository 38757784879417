import { PlusOutlined, WarningFilled } from '@ant-design/icons';
import { isValidNumericString } from '@discngine/moosa-common';
import {
  DiscreteCondition,
  DiscreteValue,
  IColumnMetaInfo,
} from '@discngine/moosa-models';
import { Button, Space, Tooltip } from 'antd';
import sortBy from 'lodash/sortBy';
import React, { useCallback, useMemo, useState } from 'react';

import { DiscreteConditionNewValueItem } from './DiscreteConditionNewValueItem';
import { DiscreteConditionValueItem } from './DiscreteConditionValueItem';
import styles from './shared/PropertyNodeEditPanel.module.less';

const getNewValue = (): DiscreteValue => {
  return {
    value: '',
    isSelected: false,
  };
};

export interface DiscreteConditionInputProps {
  condition: DiscreteCondition;
  metaData?: IColumnMetaInfo;
  isNumeric: boolean;
  disabled: boolean | undefined;
  onChange: (condition: DiscreteCondition) => void;
}

export const DiscreteConditionInput = React.memo(
  ({
    condition,
    metaData,
    isNumeric,
    disabled,
    onChange,
  }: DiscreteConditionInputProps) => {
    const [isNewValueItemShown, setIsNewValueItemShown] = useState<boolean>(false);
    const [newValue, setNewValue] = useState<DiscreteValue>(getNewValue);
    const handleValuesChange = useCallback(
      (values: DiscreteValue[]) => {
        const newCondition = {
          ...condition,
          values,
        };

        onChange(newCondition);
      },
      [condition, onChange]
    );

    const handleDelete = useCallback(
      (index: number) => {
        const values = condition.values.filter(
          (_: DiscreteValue, i: number) => i !== index
        );

        handleValuesChange(values);
      },
      [condition, handleValuesChange]
    );

    const handleIsCheckedChange = useCallback(
      (index: number, isChecked: boolean) => {
        const element = {
          ...condition.values[index],
          isSelected: isChecked,
        };
        const values = [...condition.values];

        values[index] = element;
        handleValuesChange(values);
      },
      [condition, handleValuesChange]
    );

    const handlePositionChange = useCallback(
      (fromIndex: number, toIndex: number) => {
        const values = [...condition.values];
        const element = values[fromIndex];

        values.splice(fromIndex, 1);
        values.splice(toIndex, 0, element);
        handleValuesChange(values);
      },
      [condition, handleValuesChange]
    );

    const resetNewValue = useCallback(() => {
      setIsNewValueItemShown(false);
      setNewValue(getNewValue);
    }, []);

    const handleValueAdd = useCallback(
      (newValue: DiscreteValue) => {
        const isValidValue = !isNumeric || isValidNumericString(newValue.value);
        const isUnique = !condition.values.find(
          (value) => value.value === newValue.value
        );

        if (isUnique && isValidValue) {
          const values = [...condition.values, newValue];
          const sortedValues = isNumeric
            ? sortBy(values, ({ value }) => Number(value))
            : values;

          handleValuesChange(sortedValues);
        }
        resetNewValue();
      },
      [condition, isNumeric, handleValuesChange, resetNewValue]
    );

    const dataSetValues = useMemo(() => {
      return new Set(metaData?.statistics?.textCategories?.map(({ value }) => value));
    }, [metaData?.statistics?.textCategories]);

    const mismatchedValues = useMemo(() => {
      const mismatches: DiscreteValue[] = [];
      const values: Set<DiscreteValue['value']> = new Set(
        condition.values.map(({ value }) => value)
      );

      dataSetValues.forEach((value) => {
        const stringValue = String(value);

        if (!values.has(stringValue)) {
          mismatches.push({
            value: stringValue,
            isSelected: false,
          });
        }
      });

      return mismatches;
    }, [condition.values, dataSetValues]);

    const isAddValueDisabled = condition.values.length >= 12;

    return (
      <div className={styles.conditionValues}>
        {condition.values.map((item, i) => (
          <DiscreteConditionValueItem
            key={item.value}
            disabled={disabled}
            index={i}
            isDeletable={!dataSetValues.has(item.value)}
            item={item}
            onDelete={() => handleDelete(i)}
            onIsCheckedChange={(isChecked: boolean) => {
              handleIsCheckedChange(i, isChecked);
            }}
            onPositionChange={
              !isNumeric
                ? (toIndex: number) => handlePositionChange(i, toIndex)
                : undefined
            }
          />
        ))}
        {mismatchedValues.map((item, i) => (
          <div key={item.value} className={styles.mismatchedValueRow}>
            <WarningFilled
              style={{ color: '#E7615A' }}
              title="The value is present in the dataset, but not in the condition"
            />

            <DiscreteConditionValueItem
              disabled={disabled}
              index={i}
              isDeletable={!dataSetValues.has(item.value)}
              item={item}
              onIsCheckedChange={(isChecked: boolean) => {
                handleValueAdd({
                  ...item,
                  isSelected: isChecked,
                });
              }}
            />

            <div className={styles.addIcon}>
              <PlusOutlined
                style={{ color: '#898989' }}
                title="Add value to the condition and to the histogram"
                onClick={() => handleValueAdd(item)}
              />
            </div>
          </div>
        ))}
        {isNewValueItemShown ? (
          <div>
            <DiscreteConditionNewValueItem value={newValue} onChange={setNewValue} />

            <div style={{ margin: '8px 0' }}>
              <Button type="primary" onClick={() => handleValueAdd(newValue)}>
                Add to values
              </Button>
              <Button type="default" onClick={resetNewValue}>
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <Tooltip
              placement="right"
              title={
                isAddValueDisabled ? 'Maximum number of discrete values is exceeded' : ''
              }
            >
              <Button
                disabled={disabled || isAddValueDisabled}
                style={{ padding: 0 }}
                type="link"
                onClick={() => setIsNewValueItemShown(true)}
              >
                <Space size={8}>
                  <PlusOutlined />
                  Add new value
                </Space>
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
);
