import { createContext, useContext } from 'react';

import { IComponentsContext } from './IComponentsContext';

export const ComponentsContext = createContext<IComponentsContext | null>(null);

export const useComponentsContext = () => {
  const context = useContext(ComponentsContext);

  if (!context) {
    throw new Error('ComponentsContextProvider is not defined');
  }

  return context;
};
