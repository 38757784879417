import { DTNodeId, DatasetRowId, DecisionTreeResult } from '@discngine/moosa-models';
import * as go from 'gojs';

import '../extensions/RoundedRectangles';
import '../extensions/Buttons';

import Chart from '../../../components/PropertiesPanel/icons/chart.svg';
import { NODE_WIDTH } from '../../../constants';
import {
  CommonNodeTemplateProps,
  DiagramData,
  DiagramNodeData,
  DiagramNodePosition,
  NodeConditionMismatchLevel,
} from '../../../types';
import { getColumnLabel } from '../../../utils';
import {
  NODE_HEADER_FILL,
  NODE_HEADER_TEXT,
  NODE_FILL,
  getNodeBackgroundColor,
  getNodeBorderColor,
  NODE_BORDER,
  getNodeBorderSize,
} from '../colors';
import { MAX_LABEL_LENGTH, trimHeaderLabel } from '../utils';

import {
  InputPort,
  OutputPort,
  getNodeHeaderColor,
  getNodeTypeMismatchMessage,
} from './common';

const chartNodeHeader = (options?: Partial<go.Panel>) =>
  new go.Panel('Auto', { ...options })
    .add(
      new go.Shape('RoundedTopRectangle', {
        parameter1: 8,
        strokeWidth: 0,
        fill: NODE_HEADER_FILL,
      })
        .bind(
          new go.Binding('fill', 'columnId', (_, object) => getNodeHeaderColor(object))
        )
        .bind(
          new go.Binding('fill', 'editableNodeId', (_, object) =>
            getNodeHeaderColor(object)
          ).ofModel()
        )
    )
    .add(
      new go.Panel('Horizontal', {
        margin: new go.Margin(6, 8),
        alignment: go.Spot.Left,
      })
        .add(new go.Picture({ source: Chart, width: 16, height: 16 }))
        .add(
          new go.TextBlock({
            stroke: NODE_HEADER_TEXT,
            font: '400 14px Roboto, sans-serif',
            margin: new go.Margin(0, 0, -1.5, 8),
            toolTip: new go.Adornment(go.Panel.Auto)
              .add(
                new go.Shape({ fill: '#FFFFFF' }),
                new go.TextBlock({
                  margin: 10,
                  maxSize: new go.Size(300, NaN),
                  wrap: go.TextBlock.WrapDesiredSize,
                }).bind(
                  'text',
                  'columnId',
                  (columnId: DiagramNodeData['columnId'], object: go.GraphObject) => {
                    if (!columnId) return;

                    const modelData = object.diagram?.model.modelData as DiagramData;

                    return getColumnLabel(columnId, modelData?.columnLabelMap);
                  }
                )
              )
              .bind(
                'visible',
                'columnId',
                (columnId: DiagramNodeData['columnId'], object: go.GraphObject) => {
                  if (!columnId) return false;

                  const modelData = object.diagram?.model.modelData as DiagramData;
                  const label = getColumnLabel(columnId, modelData?.columnLabelMap);

                  return label.length > MAX_LABEL_LENGTH;
                }
              ),
          })
            .bind(
              'text',
              'columnId',
              (columnId: DiagramNodeData['columnId'], object: go.GraphObject) => {
                if (!columnId) {
                  return 'Chart';
                }
                const modelData = object.diagram?.model.modelData as DiagramData;
                const label = getColumnLabel(columnId, modelData?.columnLabelMap);

                return trimHeaderLabel(`Chart - ${label}`);
              }
            )
            .bind('stroke', 'columnId', (columnId: DiagramNodeData['columnId']) => {
              return columnId !== null ? NODE_HEADER_TEXT : '#FFFFFF';
            })
        )
    );

export interface IChartNodeTemplateProps extends CommonNodeTemplateProps {
  getHistogram?: (nodeData: DiagramNodeData, rowIds: Set<DatasetRowId>) => string | null;
  onEditConditionClick?: (data: DiagramNodeData) => void;
}

export const chartNodeTemplate = ({
  onEditConditionClick,
  getHistogram,
  onResultsClick,
}: IChartNodeTemplateProps) =>
  new go.Node('Vertical', {
    cursor: 'pointer',
    locationSpot: new go.Spot(0.5, 0, 0, 15),
    width: NODE_WIDTH,
    click: (_, object: go.GraphObject) => {
      onEditConditionClick?.(object.part?.data as DiagramNodeData);
    },
  })
    // Prevent movement for grouped nodes
    .bind('movable', 'group', (group: DTNodeId) => !group)
    .bind('selectable', 'group', (group: DTNodeId) => !group)

    // Node position
    .bind(
      new go.Binding(
        'location',
        'position',
        ({ x, y }: DiagramNodePosition): go.Point => new go.Point(x, y),
        ({ x, y }: go.Point): DiagramNodePosition => ({ x, y })
      )
    )

    .add(
      new go.Panel('Vertical', { width: NODE_WIDTH, defaultStretch: go.Panel.Horizontal })
        // Input port
        .add(InputPort('input'))

        .add(
          new go.Panel('Vertical', {
            name: 'container',
            defaultStretch: go.Panel.Horizontal,
          })
            // Node header
            .add(chartNodeHeader({ alignment: go.Spot.TopCenter }))

            // Node content
            .add(
              new go.Panel('Table', {
                defaultStretch: go.GraphObject.Fill,
                background: NODE_FILL,
              })
                .bind(
                  new go.Binding(
                    'background',
                    'highlightedNode',
                    getNodeBackgroundColor
                  ).ofModel()
                )
                .add(
                  new go.Shape({
                    row: 0,
                    column: 0,
                    fill: NODE_BORDER,
                    width: 1,
                    strokeWidth: 0,
                  })
                    .bind(
                      new go.Binding(
                        'fill',
                        'highlightedNode',
                        getNodeBorderColor
                      ).ofModel()
                    )
                    .bind(
                      new go.Binding(
                        'width',
                        'highlightedNode',
                        getNodeBorderSize
                      ).ofModel()
                    )
                )
                .add(
                  new go.Shape({
                    row: 0,
                    column: 2,
                    fill: NODE_BORDER,
                    width: 1,
                    strokeWidth: 0,
                  })
                    .bind(
                      new go.Binding(
                        'fill',
                        'highlightedNode',
                        getNodeBorderColor
                      ).ofModel()
                    )
                    .bind(
                      new go.Binding(
                        'width',
                        'highlightedNode',
                        getNodeBorderSize
                      ).ofModel()
                    )
                )
                .add(
                  new go.Panel('Vertical', { row: 0, column: 1 })
                    .add(getNodeTypeMismatchMessage())
                    .add(
                      new go.TextBlock('Chart is not specified', {
                        margin: new go.Margin(40, 0, 0, 0),
                        font: '600 14px Roboto, sans-serif',
                        verticalAlignment: go.Spot.Center,
                      }).bind('visible', 'columnId', (value) => value === null)
                    )
                    .add(
                      new go.Picture({ width: 294, height: 175 })
                        .bind(
                          new go.Binding(
                            'source',
                            'result',
                            (result: DecisionTreeResult, object: go.GraphObject) => {
                              if (!object.part) return null;

                              const node = object.part.data as DiagramNodeData;

                              return (
                                getHistogram?.(
                                  node,
                                  node.showFullDataset
                                    ? result.datasetRowIds
                                    : result.nodes[node.key].inputDatasetRowIds
                                ) || ''
                              );
                            }
                          ).ofModel()
                        )
                        .bind(
                          'visible',
                          'mismatch',
                          (mismatch: DiagramNodeData['mismatch']) => {
                            return (
                              mismatch?.level !== NodeConditionMismatchLevel.Critical
                            );
                          }
                        )
                        .bind(
                          'visible',
                          'columnId',
                          (columnId: DiagramNodeData['columnId']) => {
                            return !!columnId;
                          }
                        )
                    )
                )
            )
            .add(
              new go.Panel('Vertical', {
                defaultStretch: go.GraphObject.Horizontal,
                stretch: go.GraphObject.Horizontal,
              }).add(
                new go.Shape('RoundedBottomRectangle', {
                  parameter1: 8,
                  fill: NODE_FILL,
                  stroke: NODE_BORDER,
                  strokeWidth: 1,
                  height: 8,
                  margin: new go.Margin(-2, 0, 0, 0),
                })
                  .bind(
                    new go.Binding(
                      'fill',
                      'highlightedNode',
                      getNodeBackgroundColor
                    ).ofModel()
                  )
                  .bind(
                    new go.Binding(
                      'stroke',
                      'highlightedNode',
                      getNodeBorderColor
                    ).ofModel()
                  )
                  .bind(
                    new go.Binding(
                      'strokeWidth',
                      'highlightedNode',
                      getNodeBorderSize
                    ).ofModel()
                  )
              )
            )
        )
    )

    // Output ports
    .add(OutputPort({ portId: 'combine', onClick: onResultsClick }));
