import { Group } from '@visx/group';

import { SlicedBar } from './SlicedBar';
import { DiscreteBar, DiscreteHistogramData, ScaleBand, ScaleLinear } from './types';

export type selectBarComponentProps = {
  bar: DiscreteBar;
  barWidth: number;
  barX: number;
};

export const DiscreteSlicedBar = ({
  data,
  xScale,
  yScale,
  component,
}: {
  data: DiscreteHistogramData | null;
  xScale: ScaleBand;
  yScale: ScaleLinear;
  component?: (props: selectBarComponentProps) => JSX.Element;
}) => {
  return (
    <>
      {data?.bars.map((bar) => {
        const barWidth = xScale.bandwidth();
        const barX = xScale(bar.x);

        if (!barX) return null;

        return (
          <Group key={`bar-${barX}`}>
            <SlicedBar
              colors={data.colors}
              width={barWidth}
              x={barX}
              y={bar.y}
              yScale={yScale}
            />
            {component?.({ bar, barWidth, barX })}
          </Group>
        );
      })}
    </>
  );
};
