import { IPoint } from '@discngine/moosa-models';
import React, { FC, useCallback } from 'react';

import { useComponentsContext } from '../../../ComponentsContext/ComponentsContext';

export type PointEditorProps = {
  point: IPoint;
  xValidator: (x: number) => boolean;
  yValidator: (y: number) => boolean;
  onPointChange: (point: IPoint) => void;
};

export const PointEditor: FC<PointEditorProps> = React.memo(function PointEditor({
  point,
  xValidator,
  yValidator,
  onPointChange,
}) {
  const { ValueInput } = useComponentsContext();
  const handleXChange = useCallback(
    (val: string) => {
      const value = val ? Number(val) : NaN;

      if (xValidator(value)) {
        onPointChange({ x: value, y: point.y, id: point.id });
      }
    },
    [onPointChange, xValidator, point]
  );
  const handleYChange = useCallback(
    (val: string) => {
      const value = val ? Number(val) : NaN;

      if (yValidator(value)) {
        onPointChange({ x: point.x, y: value, id: point.id });
      }
    },
    [onPointChange, yValidator, point]
  );

  return (
    <tr>
      <td>
        <ValueInput value={point.x} onValueChange={handleXChange} />
      </td>
      <td>
        <ValueInput value={point.y} onValueChange={handleYChange} />
      </td>
    </tr>
  );
});
