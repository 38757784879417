import { classNames } from '@discngine/moosa-common';
import { IStructureRenderer, StructureRendererProps } from '@discngine/moosa-models';
import { createRef, FC, useRef, MouseEvent, useCallback } from 'react';

import { ISarItem } from '../../MoosaSarTablePanelProps';

import styles from './CellRenderer.module.less';
import { CopyMoleculeButton, RefType } from './CopyMoleculeButton/CopyMoleculeButton';
import { MenuItem, MenuOverlay } from './MenuRenderer/MenuRenderer';

export type CellRendererProps = Omit<StructureRendererProps, 'smiles'> & {
  // interface without smiles, because sarItemData includes smiles
  sarItemData: ISarItem;
  menu: (moleculeId: string) => MenuItem[];
  StructureRendererWithPopover: IStructureRenderer;
  onMoleculeClick: (event: MouseEvent<HTMLElement>) => void;
  onMoleculeHover: () => void;
  width?: number;
  height?: number;
};

export type CustomizedCellRendererProps = Omit<
  CellRendererProps,
  'menu' | 'StructureRendererWithPopover'
>;

export const CellRenderer: FC<CellRendererProps> = ({
  menu,
  sarItemData,
  StructureRendererWithPopover,
  className,
  onMoleculeClick,
  onMoleculeHover,
  height,
  width,
  ...props
}) => {
  const copyBtnRef = createRef<RefType>();
  const svgRef = useRef<SVGSVGElement>(null);

  const wrapperClassName = classNames(styles.structure, {
    [className!]: height === undefined && width === undefined,
  });

  const onBlur = useCallback(() => {
    copyBtnRef.current?.onSetCopiedToFalse();
  }, [copyBtnRef]);

  const onMouseLeave = useCallback(() => {
    copyBtnRef.current?.onSetCopiedToFalse();
  }, [copyBtnRef]);

  return (
    <div className={styles.cellRenderer} onBlur={onBlur} onMouseLeave={onMouseLeave}>
      <div
        className={wrapperClassName}
        style={{ height: `${height}px`, width: `${width}px` }}
        onClick={onMoleculeClick}
        onMouseEnter={onMoleculeHover}
      >
        <StructureRendererWithPopover
          smiles={sarItemData.structure}
          svgRef={svgRef}
          {...props}
        />
      </div>
      <MenuOverlay menu={menu} sarItemData={sarItemData} />
      <CopyMoleculeButton
        ref={copyBtnRef}
        className={styles.copy}
        id={sarItemData.rowId}
        structure={sarItemData.structure}
        svgRef={svgRef}
      />
    </div>
  );
};
