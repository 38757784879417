import { IDecisionTreeNew } from '@discngine/moosa-models';

import { TEXTINGS } from './constants';
import { SaveDialogValue, SaveMode } from './types';

export const treeToSaveDialogValue = (
  tree: IDecisionTreeNew,
  saveMode: SaveMode
): SaveDialogValue => ({
  id: tree._id,
  name: tree.name + TEXTINGS[saveMode].postfix,
  version:
    saveMode === 'SAVE_AS_COPY' || saveMode === 'SAVE_AS_VERSION' ? '' : tree.versionName,
  comment: saveMode === 'SAVE_AS_VERSION' ? '' : tree.description,
  parentId: tree.parentId,
});

export const treeFromSaveDialogValue = (
  tree: IDecisionTreeNew,
  value: SaveDialogValue
): IDecisionTreeNew => ({
  _id: value.id,
  parentId: value.parentId,
  name: value.name || '',
  versionName: value.version,
  description: value.comment,
  data: tree.data,
});
