import { useMoosaDataContext } from '@discngine/moosa-models';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAppStore } from '../store';

import { selectIsChartShown } from './charts.selector';
import { fetchFullDataset } from './charts.thunk';

const DEBOUNCE_DELAY_MS = 500;

const debouncedChartUpdate = debounce(
  (dispatch, getDataWithScore) => dispatch(fetchFullDataset(getDataWithScore)),
  DEBOUNCE_DELAY_MS
);

export function useUpdateChartAfterAction() {
  const dispatch = useDispatch();
  const dataService = useMoosaDataContext();
  const { getState } = useAppStore();

  return useCallback(() => {
    const isChartShown = selectIsChartShown(getState());

    if (isChartShown) {
      debouncedChartUpdate(dispatch, dataService.getDataWithScore);
    }
  }, [dispatch, getState, dataService]);
}
