import {
  DecisionTree,
  DecisionTreeResult,
  DatasetRowId,
  ColumnId,
} from '@discngine/moosa-models';
import { useCallback } from 'react';

import { calculateDecisionTreeResults } from '../calculateResults/calculateDecisionTreeResults';
import { DecisionTreeCellValuesGetter } from '../calculateResults/types';

export type GetDecisionTreeResult = (tree: DecisionTree) => DecisionTreeResult;

export const useGetDecisionTreeResult = (
  getCellValues: DecisionTreeCellValuesGetter,
  datasetRowIds: DatasetRowId[],
  /**
   * The function checks that the core structure is a substructure of the structure in corresponding column cell
   *
   * @param rowId
   * @param columnId - It is expected that columnId has chemical structures in the cells
   * @param core - a structure in mol2 format to compare with
   */
  checkSubstructure?: (rowId: DatasetRowId, columnId: ColumnId, core: string) => boolean
): GetDecisionTreeResult => {
  return useCallback(
    (tree: DecisionTree): DecisionTreeResult => {
      return calculateDecisionTreeResults(
        getCellValues,
        datasetRowIds,
        tree,
        checkSubstructure
      );
    },
    [getCellValues, datasetRowIds, checkSubstructure]
  );
};
