import { DTOperation, RangeCondition } from '@discngine/moosa-models';
import { InputNumber, Select } from 'antd';
import React, { useCallback } from 'react';

import styles from './shared/PropertyNodeEditPanel.module.less';

export interface RangeConditionInputProps {
  condition: RangeCondition;
  disabled: boolean;
  onChange: (condition: RangeCondition) => void;
}

export const RangeConditionInput = React.memo(
  ({ condition, disabled, onChange }: RangeConditionInputProps) => {
    const onMinOperationChange = useCallback(
      (value: DTOperation.Less | DTOperation.LessEqual) => {
        condition.min.operation = value;
        onChange({ ...condition });
      },
      [condition, onChange]
    );

    const onMaxOperationChange = useCallback(
      (value: DTOperation.Less | DTOperation.LessEqual) => {
        condition.max.operation = value;
        onChange({ ...condition });
      },
      [condition, onChange]
    );

    const onMinThresholdChange = useCallback(
      (value: number | null) => {
        condition.min.threshold = value || 0;
        onChange({ ...condition });
      },
      [condition, onChange]
    );

    const onMaxThresholdChange = useCallback(
      (value: number | null) => {
        condition.max.threshold = value || 0;
        onChange({ ...condition });
      },
      [condition, onChange]
    );

    return (
      <>
        <div className={styles.conditionRow}>
          <InputNumber
            addonAfter={
              <Select
                disabled={disabled}
                style={{ width: 'auto' }}
                value={condition.min.operation}
                onChange={onMinOperationChange}
              >
                {[DTOperation.Less, DTOperation.LessEqual].map((option) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            }
            className={styles.input}
            disabled={disabled}
            placeholder="Threshold"
            size="middle"
            value={condition.min.threshold}
            onChange={onMinThresholdChange}
          />

          <span>VALUE</span>

          <InputNumber
            addonBefore={
              <Select
                disabled={disabled}
                style={{ width: 'auto' }}
                value={condition.max.operation}
                onChange={onMaxOperationChange}
              >
                {[DTOperation.Less, DTOperation.LessEqual].map((option) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            }
            className={styles.input}
            disabled={disabled}
            placeholder="Threshold"
            size="middle"
            value={condition.max.threshold}
            onChange={onMaxThresholdChange}
          />
        </div>
      </>
    );
  }
);
