import { IDatasetMetaStatistics, ITableDataRow } from '@discngine/moosa-models';
import dayjs from 'dayjs';

import { cellValueToDate } from '../utils';

interface IHistogram {
  histogram: number[];
  histogramStep: number;
}

/**
 * Generally, copy-paste from backend Use Case
 * TODO: Make this code from BE shared between modules
 *
 * @param rows Dataset data rows
 * @param field Dataset field of date type
 * @param format Dayjs date format
 * @param resolution Minimum data width for each histogram bar. Values which differs in less than passed value will be clumped.
 */
export function computeDateStatistics(
  rows: ITableDataRow[],
  field: string,
  format?: string,
  resolution = 0
): IDatasetMetaStatistics {
  const stat: IDatasetMetaStatistics = {
    histogram: [],
    max: Number.NEGATIVE_INFINITY,
    min: Number.POSITIVE_INFINITY,
    histogramStep: 0,
    missingValues: 0,
    textCategories: null,
  };

  for (const row of rows) {
    const rawValue = row[field];

    if (rawValue == null) {
      stat.missingValues++;
      continue;
    }

    const value = cellValueToDate(rawValue, format)?.valueOf();

    if (value == null || isNaN(value)) {
      stat.missingValues++;
      continue;
    }

    // compute min and max
    if (value < stat.min) {
      stat.min = value;
    }

    if (value > stat.max) {
      stat.max = value;
    }
  }

  // Normalize min max values in according to resolution
  if (resolution) {
    stat.min = Math.floor(stat.min / resolution) * resolution;

    // Add 1xResolution to max value for histogram be able to draw the last column
    stat.max = (Math.ceil(stat.max / resolution) + 1) * resolution;
  }

  const { histogram, histogramStep } = computeHistogram(
    stat.min,
    stat.max,
    rows.length - stat.missingValues,
    rows.map((row) => dayjs(row[field], format).valueOf()),
    resolution
  );

  stat.histogram = histogram;
  stat.histogramStep = histogramStep;

  return stat;
}

function computeHistogram(
  min: number,
  max: number,
  count: number,
  values: Iterable<number>,
  minStep = 0
): IHistogram {
  const range = max - min;

  if (!range || count === 0) {
    // edge cases when all rows have the same value or column is empty, e.g. zero division
    return {
      histogram: [count],
      histogramStep: minStep,
    };
  }

  let barsCount = Math.floor(1 + Math.log2(count));
  let histogramStep = range / barsCount;

  // If histogram step is less than given resolution then, despite the dataset size, we're trying to make too much bars
  if (histogramStep < minStep) {
    histogramStep = minStep;
    barsCount = Math.ceil(range / minStep);
  } else if (minStep !== 0 && histogramStep % minStep !== 0) {
    // Take the closest multiple of minStep and recalculate barsCount so as
    // not to get columns of 1.5 day width or something
    histogramStep = Math.ceil(histogramStep / minStep) * minStep;
    barsCount = Math.ceil(range / histogramStep);
  }

  const histogram: number[] = Array(barsCount).fill(0);

  for (const value of values) {
    if (value == null) {
      continue;
    }

    const normalized = value - min;
    let index = Math.floor(normalized / histogramStep);

    // make sure rightmost value is consumed by the last histogram bar
    if (index === histogram.length) {
      index = histogram.length - 1;
    }

    histogram[index]++;
  }

  return { histogram, histogramStep };
}
