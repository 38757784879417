import { IRGBColor } from '@discngine/moosa-common';
import { DiscretePropertyValue } from '@discngine/moosa-models';
import { FC } from 'react';

import styles from './DiscretePropertyColorizingConfig.module.less';
import { DiscretePropertyItem } from './DiscretePropertyItem/DiscreteTextPropertyItem';

interface IDiscretePropertyColorizingConfigProps {
  discretePropertyValues: DiscretePropertyValue[];
  onColorChange: (indexOfSelectedColor: number, color: IRGBColor | null) => void;
}

/**
 * You need to use this component with a CSS class wrapper that provides the following css variables:
 * --discrete-text-property-item-background
 * --discrete-text-property-item-border
 * --discrete-text-property-item-color-box-border
 * --discrete-text-property-item-arrow-down-color
 *
 * Example: discretePropertyColorizingConfigColors class in ColorizingPanel (moosa-shared-components)
 **/

export const DiscretePropertyColorizingConfig: FC<
  IDiscretePropertyColorizingConfigProps
> = ({ discretePropertyValues, onColorChange }) => {
  return (
    <div className={styles.discretePropertyColorizingConfig}>
      {discretePropertyValues.map((item, index) => (
        <DiscretePropertyItem
          key={index}
          indexOfSelectedColor={index}
          item={item}
          onColorChange={onColorChange}
        />
      ))}
    </div>
  );
};
