import { v4 as uuid } from 'uuid';

import {
  SigmoidFunctionParams,
  DesirabilityFunctionRules,
  IRange,
  IPoint,
  ILinePoint,
  ServerLinePoint,
} from '../scoringTemplate';
import { IColumnMetaInfo } from '../tableInfo';

import { checkXBoundaries, getRangeForLinearApprox, segmentation } from './rulesUtils';

// calculate first derivation of sigmoid function
function derivateSigmoid(val: number, params: SigmoidFunctionParams): number {
  const { l, k, xCenter } = params;
  const _p = Math.exp(-k * (val - xCenter));

  return (k * l * _p) / ((_p + 1) * (_p + 1));
}

export const sigmoidFunctionRules: DesirabilityFunctionRules<SigmoidFunctionParams> = {
  init(metadata: IColumnMetaInfo): SigmoidFunctionParams {
    const params: SigmoidFunctionParams = {
      l: 1,
      k: 1,
      xCenter: (metadata.statistics!.max + metadata.statistics!.min) / 2,
    };

    return params;
  },
  getValue(xVal: number, params: SigmoidFunctionParams): number {
    const { l, k, xCenter } = params;

    /// L / (1 + exp(-K * (x - xCenter)))
    const sigmoid = 1 / (1 + Math.exp(-k * (xVal - xCenter)));

    if (l >= 0) {
      return l * sigmoid;
    }

    return l * sigmoid - l;
  },
  getRange(params: SigmoidFunctionParams, metadata: IColumnMetaInfo): IRange {
    const delta = (metadata.statistics!.max - metadata.statistics!.min) / 20;
    const interestingRange: IPoint[] = [
      { x: params.xCenter - delta, y: 0, id: uuid() },
      { x: params.xCenter + delta, y: 0, id: uuid() },
    ];

    return getRangeForLinearApprox(interestingRange, metadata);
  },
  toLine(params: SigmoidFunctionParams, range: IRange): ILinePoint[] {
    const p0: ILinePoint = {
      id: 'min',
      x: range.min,
      y: sigmoidFunctionRules.getValue(range.min, params),
    };
    const p1: ILinePoint = {
      id: 'max',
      x: range.max,
      y: sigmoidFunctionRules.getValue(range.max, params),
    };
    const pCenter: ILinePoint = {
      id: 'center',
      x: params.xCenter,
      y: sigmoidFunctionRules.getValue(params.xCenter, params),
      mark: true,
      originalPointIndex: 0,
    };

    const initialPoints =
      range.min <= params.xCenter && params.xCenter <= range.max
        ? [p0, pCenter, p1]
        : [p0, p1];
    const fx = (x: number) => sigmoidFunctionRules.getValue(x, params);
    const dfx = (x: number) => derivateSigmoid(x, params);
    const line = segmentation(initialPoints, fx, dfx);

    // console.info('sigmoid segmentation', line.length);

    return line;
  },
  toServerTemplate(params: SigmoidFunctionParams): ServerLinePoint[] {
    // TODO - a crutch: fix the crutch
    return [
      { x: params.l, y: params.k },
      { x: params.xCenter, y: 0 },
    ];
  },
  fromServerTemplate(points: ServerLinePoint[]): SigmoidFunctionParams {
    // TODO - and fix this as well
    return {
      l: points[0].x,
      k: points[0].y,
      xCenter: points[1].x,
    };
  },

  movePoint(sigParams: SigmoidFunctionParams, { originalPointIndex, x }: ILinePoint) {
    const params = { ...sigParams };

    // eslint-disable-next-line no-param-reassign
    x = checkXBoundaries(x);

    if (originalPointIndex === 0) {
      params.xCenter = x;
    }

    return params;
  },
};
