import { v4 as uuid } from 'uuid';

import {
  DesirabilityFunctionRules,
  TriangularFunctionParams,
  IRange,
  ILinePoint,
  ServerLinePoint,
} from '../scoringTemplate';
import { IColumnMetaInfo } from '../tableInfo';

import {
  assignIndices,
  getRangeForInitialization,
  getRangeForLinearApprox,
  getValueFromLinearApprox,
  removeIndices,
  toLine,
  updateLinePoint,
} from './rulesUtils';

export const triangularFunctionRules: DesirabilityFunctionRules<TriangularFunctionParams> =
  {
    init(metadata: IColumnMetaInfo): TriangularFunctionParams {
      const { min, max } = getRangeForInitialization(metadata);
      const middle = (min + max) / 2;
      const params: TriangularFunctionParams = {
        points: [
          { id: uuid(), x: min, y: 0, originalPointIndex: 0 },
          { id: uuid(), x: middle, y: 1, originalPointIndex: 1 },
          { id: uuid(), x: max, y: 0, originalPointIndex: 2 },
        ],
      };

      return params;
    },
    getValue(xVal: number, params: TriangularFunctionParams): number {
      return getValueFromLinearApprox(xVal, params.points);
    },
    getRange(params: TriangularFunctionParams, metadata: IColumnMetaInfo): IRange {
      return getRangeForLinearApprox(params.points, metadata);
    },
    toLine(params: TriangularFunctionParams, range: IRange): ILinePoint[] {
      return toLine(params.points, range);
    },
    toServerTemplate(params: TriangularFunctionParams): ServerLinePoint[] {
      return removeIndices(params.points);
    },
    fromServerTemplate(points: ILinePoint[]): TriangularFunctionParams {
      return { points: assignIndices(points) } as TriangularFunctionParams;
    },
    movePoint(params: TriangularFunctionParams, point: ILinePoint) {
      return updateLinePoint(params.points, point) as TriangularFunctionParams;
    },
  };
