import { updateModelData } from 'lib/go/utils';
import { useEffect } from 'react';
import { useDragDropManager, ConnectDropTarget, useDrop } from 'react-dnd';
import { getDiagramCoordinates, getNodeByCoordinates, getPortByCoordinates } from 'utils';

import { CANVAS_LOCATION, DND_ITEM_TYPE } from '../../../constants';
import { DropResult } from '../../../types';

/**
 * Hook for handling drag and drop functionality in a diagram.
 *
 * @param diagram - The GoJS diagram instance.
 * @returns {Array} An array containing the DropResult object and the drag drop reference.
 */
export const useDiagramDragDrop = (
  diagram: go.Diagram | null
): [{ isOver: boolean }, ConnectDropTarget] => {
  const [props, dragRef] = useDrop({
    accept: DND_ITEM_TYPE,
    drop: (_, monitor): DropResult => {
      const coords = monitor.getClientOffset();

      return {
        location: CANVAS_LOCATION,
        diagramCoords: coords && diagram ? getDiagramCoordinates(diagram, coords) : null,
        port: (coords && diagram && getPortByCoordinates(diagram, coords)) || null,
        node: (coords && diagram && getNodeByCoordinates(diagram, coords)) || null,
      };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver() && !monitor.didDrop(),
    }),
  });

  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();

  /**
   * Observing mouse movement to highlight the port over which the mouse is over.
   */
  useEffect(() => {
    if (!diagram) return;

    monitor.subscribeToOffsetChange(() => {
      const coords = monitor.getClientOffset();
      const highlightedPort =
        coords && diagram ? getPortByCoordinates(diagram, coords) : null;

      if (highlightedPort) {
        updateModelData(diagram, {
          highlightedPort,
        });
      } else {
        setTimeout(() => {
          updateModelData(diagram, {
            highlightedPort: null,
          });
        }, 100);
      }

      const highlightedNode =
        coords && diagram ? getNodeByCoordinates(diagram, coords) : null;

      if (highlightedNode) {
        updateModelData(diagram, {
          highlightedNode: highlightedNode.key,
        });
      } else {
        setTimeout(() => {
          updateModelData(diagram, {
            highlightedNode: null,
          });
        }, 100);
      }
    });
  }, [diagram, monitor]);

  /**
   * Updates the highlighting state of the diagram.
   */
  useEffect(() => {
    if (!diagram) return;

    updateModelData(diagram, {
      isHighlighted: props.isOver,
    });
  }, [diagram, props.isOver]);

  return [props, dragRef];
};
