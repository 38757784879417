import { IDecisionTreeNew } from '@discngine/moosa-models';
import { useCallback, useEffect, useRef } from 'react';

const SET_TREE_EVENT = 'setTreeEvent';

export const dispatchSetTreeEvent = (tree: IDecisionTreeNew) => {
  document.dispatchEvent(new CustomEvent(SET_TREE_EVENT, { detail: tree }));
};

export const useSetTreeEventListener = (isDisabled: () => boolean) => {
  const setTreeRef = useRef<(dt: IDecisionTreeNew) => void>();

  const setTreeHandler = useCallback(
    (event: CustomEvent<IDecisionTreeNew>) => {
      const setTree = setTreeRef.current;

      if (setTree && !isDisabled()) {
        setTree(event.detail);
      }
    },
    [isDisabled]
  );

  useEffect(() => {
    // custom event
    // @ts-ignore
    document.addEventListener(SET_TREE_EVENT, setTreeHandler);

    return () => {
      // custom event
      // @ts-ignore
      document.removeEventListener(SET_TREE_EVENT, setTreeHandler);
    };
  }, [setTreeHandler]);

  return setTreeRef;
};
