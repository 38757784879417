import { getTextWidth } from '@discngine/moosa-common';
import {
  Condition,
  ConditionType,
  DTOperation,
  DiscreteCondition,
  DesirabilityFunctionCondition,
} from '@discngine/moosa-models';
import { localPoint } from '@visx/event';
import React, { useCallback, useRef } from 'react';
import { FC, useEffect, useMemo, useState } from 'react';

import { HISTOGRAM_SELECTED_AREA } from '../colors';

import { formatDateForHistogram, MARGIN, TOOLTIP } from './histogramHelpers';
import style from './NumericHistogram.module.less';
import { ScaleLinear, ScaleTime } from './types';

const GREATER = [DTOperation.Greater, DTOperation.GreaterEqual];

interface NumericHistogramInputPropsBase {
  width: number;
  height: number;
  condition?: Exclude<Condition, DiscreteCondition | DesirabilityFunctionCondition>;
  onChange?: (condition: Condition) => void;
}

interface NumericHistogramInputLinearProps extends NumericHistogramInputPropsBase {
  xScale: ScaleLinear;
  dataType: 'numeric';
}

interface NumericHistogramInputDateProps extends NumericHistogramInputPropsBase {
  xScale: ScaleTime;
  dataType: 'date';
}

type NumericHistogramInputProps =
  | NumericHistogramInputLinearProps
  | NumericHistogramInputDateProps;

export const NumericHistogramInput: FC<NumericHistogramInputProps> = ({
  width,
  height,
  xScale,
  condition,
  onChange,
  dataType,
}) => {
  const ref = useRef<SVGGElement>(null);

  const [boundingRect, setBoundingRect] = useState<DOMRect | null>(null);
  const [localCondition, setLocalCondition] = useState(condition);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState<'min' | 'max' | null>(null);

  const yMax = height - MARGIN.bottom;
  const xMax = width - MARGIN.right;
  const maxHeight = Math.max(yMax - MARGIN.top, 0);

  useEffect(() => {
    setBoundingRect(ref.current?.getBoundingClientRect() || null);
  }, []);

  useEffect(() => {
    setLocalCondition(condition);
  }, [condition]);

  const areaPositions: { width: number; min: number; max: number }[] = useMemo(() => {
    switch (localCondition?.type) {
      case ConditionType.Simple: {
        if (GREATER.includes(localCondition.operation)) {
          const min = Math.max(
            MARGIN.left,
            Math.min(xMax, xScale(localCondition.threshold))
          );
          const max = xMax - min;

          return [{ min, max, width: Math.max(0, max) }];
        }

        const min = MARGIN.left;
        const max = Math.max(
          MARGIN.left,
          Math.min(xMax, xScale(localCondition.threshold))
        );

        return [{ min, max, width: Math.max(0, max - min) }];
      }

      case ConditionType.Range: {
        const minThresholdX = Number.isFinite(localCondition.min.threshold)
          ? xScale(localCondition.min.threshold)
          : MARGIN.left;
        const maxThresholdX = Number.isFinite(localCondition.max.threshold)
          ? xScale(localCondition.max.threshold)
          : xMax;

        if (!localCondition.isInverted) {
          const min = Math.max(MARGIN.left, Math.min(xMax, minThresholdX));
          const max = Math.max(MARGIN.left, Math.min(xMax, maxThresholdX));

          return [{ min, max, width: Math.max(0, max - min) }];
        } else {
          const min = Math.max(MARGIN.left, minThresholdX);
          const max = Math.max(MARGIN.left, maxThresholdX);

          return [
            { min: MARGIN.left, max: min, width: Math.max(0, min - MARGIN.left) },
            { min: max, max: xMax, width: Math.max(0, xMax - max) },
          ];
        }
      }

      default:
        return [{ min: 0, max: 0, width: 0 }];
    }
  }, [localCondition, xMax, xScale]);

  const handlersPosition = useMemo(() => {
    if (localCondition?.type === ConditionType.Simple) {
      const x = xScale(localCondition.threshold);

      return [Math.max(MARGIN.left, Math.min(xMax, x))];
    }

    if (localCondition?.type === ConditionType.Range) {
      const min = Number.isFinite(localCondition.min.threshold)
        ? xScale(localCondition.min.threshold)
        : MARGIN.left;
      const max = Number.isFinite(localCondition.max.threshold)
        ? xScale(localCondition.max.threshold)
        : xMax;

      return [
        Math.max(MARGIN.left, Math.min(xMax, min)),
        Math.max(MARGIN.left, Math.min(xMax, max)),
      ];
    }

    return [];
  }, [localCondition, xMax, xScale]);

  const onPointerDown = (newPosition: 'min' | 'max') => {
    setPosition(newPosition);
    setIsDragging(true);
  };

  const onPointerUp = useCallback(() => {
    setIsDragging(false);
    setPosition(null);

    localCondition && onChange?.(localCondition);
  }, [localCondition, onChange]);

  const onPointerMove = useCallback(
    (event: MouseEvent) => {
      if (!isDragging || !ref.current) {
        return;
      }

      let { x } = localPoint(event) ?? { x: 0 };

      if (boundingRect) {
        const { x: refX, width: refW } = boundingRect;

        if (event.clientX < refX) {
          x = 0;
        } else if (event.clientX > refX + refW) {
          x = xMax;
        }
      }

      const threshold = xScale.invert(x).valueOf();

      setLocalCondition((condition) => {
        if (condition?.type === ConditionType.Simple) {
          return { ...condition, threshold };
        }

        if (condition?.type === ConditionType.Range && position) {
          if (position === 'max' && threshold < condition.min.threshold) {
            return {
              ...condition,
              max: { ...condition.max, threshold: condition.min.threshold },
              min: { ...condition.min, threshold },
            };
          }

          if (position === 'min' && threshold > condition.max.threshold) {
            return {
              ...condition,
              max: { ...condition.max, threshold },
              min: { ...condition.min, threshold: condition.max.threshold },
            };
          }

          return {
            ...condition,
            [position]: { ...condition[position], threshold },
          };
        }
      });
    },
    [boundingRect, isDragging, position, xMax, xScale]
  );

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mouseup', onPointerUp);
      document.addEventListener('mousemove', onPointerMove);
    }

    return () => {
      document.removeEventListener('mouseup', onPointerUp);
      document.removeEventListener('mousemove', onPointerMove);
    };
  }, [isDragging, onPointerMove, onPointerUp]);

  return (
    <g ref={ref}>
      {areaPositions.map(({ min, width }, index) => (
        <rect
          key={index}
          fill={HISTOGRAM_SELECTED_AREA}
          height={maxHeight}
          opacity={0.3}
          width={width}
          x={min}
          y={MARGIN.top}
        />
      ))}

      <rect
        {...(isDragging && { className: style.handler })}
        fill="transparent"
        height={height}
        width={width}
        x={0}
        y={0}
      />

      {handlersPosition.map((position, idx) => {
        const label =
          dataType === 'numeric'
            ? xScale.invert(position).toFixed(2)
            : formatDateForHistogram(xScale.invert(position));

        const labelWidth = getTextWidth(label) + TOOLTIP.padding;

        return (
          <g key={`${position}-${idx}`}>
            <line
              className={style.handler}
              stroke={HISTOGRAM_SELECTED_AREA}
              strokeWidth={2}
              x1={position}
              x2={position}
              y1={MARGIN.top}
              y2={yMax}
            />

            <line
              className={style.handler}
              stroke="transparent"
              strokeWidth={6}
              x1={position}
              x2={position}
              y1={MARGIN.top}
              y2={yMax}
              onPointerDown={() => onPointerDown(idx === 0 ? 'min' : 'max')}
            />

            {isDragging && (
              <g>
                <rect
                  className={style.tooltip}
                  height={labelWidth}
                  rx={4}
                  width={TOOLTIP.width}
                  x={position - TOOLTIP.width / 2}
                  y={(yMax - MARGIN.top) / 2}
                />

                <foreignObject
                  height={labelWidth}
                  style={{ pointerEvents: 'none' }}
                  width={TOOLTIP.width}
                  x={position - TOOLTIP.width / 2}
                  y={(yMax - MARGIN.top) / 2}
                >
                  <div className={style.tooltipText}>{label}</div>
                </foreignObject>
              </g>
            )}
          </g>
        );
      })}
    </g>
  );
};
