import { classNames } from '@discngine/moosa-common';
import { IStructureRenderer } from '@discngine/moosa-models';
import React, { useEffect, useMemo, useState } from 'react';

import { useRDKitContext } from '../RDKit/RDKitContext';
import { smilesToSvg } from '../RDKit/RDKitUtils';

import { Queue } from './Queue';
import styles from './StructureRenderer.module.less';

const queue = new Queue(10);

export const StructureRenderer: IStructureRenderer = React.memo(
  ({
    smiles,
    svgRef,
    coreSmiles,
    highlightAllCores = false,
    alignSmiles,
    disableQueue,
    className,
    height,
    width,
  }) => {
    const { RDKit } = useRDKitContext();
    const [loading, setLoading] = useState(true);
    const [svg, setSvg] = useState<SVGElement | null>(null);
    const [svgProps, setSvgProps] = useState<Record<string, string>>({});

    useEffect(() => {
      if (!RDKit) {
        return;
      }
      setLoading(true);

      function getSvg() {
        let loading = false;

        const svgContent = smilesToSvg(
          RDKit!,
          smiles,
          coreSmiles,
          highlightAllCores,
          alignSmiles,
          width,
          height
        );

        if (svgContent) {
          const result: Record<string, string> = {};
          const attrs = svgContent.attributes;

          for (let i = attrs.length - 1; i >= 0; i--) {
            let attrname = attrs[i].name;

            if (attrname === 'xml:space') attrname = `xmlSpace`;

            if (attrname === 'xmlns:xlink') attrname = `xmlnsXlink`;
            result[attrname] = attrs[i].value;
          }

          setSvgProps(result);
          setSvg(svgContent);
        } else {
          setSvg(null);
        }
        setLoading(loading);
      }

      if (disableQueue) {
        getSvg();
      } else {
        const queueId = queue.add(getSvg);

        return () => {
          queue.remove(queueId);
        };
      }
    }, [
      RDKit,
      alignSmiles,
      coreSmiles,
      disableQueue,
      height,
      highlightAllCores,
      smiles,
      width,
    ]);

    const structure = useMemo(() => {
      return (
        <div className={styles.wrapper}>
          {!loading && !svg && (
            <div className={styles.svgHolder}>
              <div>
                Molecule could not
                <br /> be rendered
              </div>
            </div>
          )}
          {!loading && svg && (
            <div className={styles.svgHolder}>
              <svg
                {...svgProps}
                ref={svgRef}
                dangerouslySetInnerHTML={{
                  __html: svg.innerHTML ?? '',
                }}
              />
            </div>
          )}
          {loading && (
            <div className={styles.svgHolder}>
              <span className={styles.loader}></span>
            </div>
          )}
        </div>
      );
    }, [loading, svg, svgProps, svgRef]);

    return (
      <div className={classNames(styles.structureRenderer, className ?? '')}>
        {structure}
      </div>
    );
  }
);
