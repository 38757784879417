import { IScoreMap, IScoreState } from '@discngine/moosa-models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { tableInfoSlice } from '@discngine/moosa-store/tableInfo';

const initialState: IScoreState = {
  scoredDataset: {},

  values: {},
  comparisonValues: {},
};

export const scoresSlice = createSlice({
  name: 'scores',
  initialState,
  reducers: {
    setScoredDataset(state, action: PayloadAction<IScoreMap>) {
      state.scoredDataset = action.payload;
    },
    setScores(state, action: PayloadAction<IScoreMap>) {
      state.values = action.payload;
    },
    appendScores(state, action: PayloadAction<IScoreMap>) {
      state.values = { ...state.values, ...action.payload };
    },
    appendScoresAt(
      state,
      action: PayloadAction<{
        offset: number;
        scores: IScoreMap;
        comparisonScores?: IScoreMap;
      }>
    ) {
      const { scores, comparisonScores } = action.payload;

      state.values = { ...state.values, ...scores };
      state.comparisonValues = { ...state.comparisonValues, ...comparisonScores };
    },
    overwriteScores(state, action: PayloadAction<IScoreMap>) {
      state.values = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(tableInfoSlice.actions.initTableInfo.type, (state) => {
      state.values = {};
      state.comparisonValues = {};
    });
  },
});

export const {
  setScoredDataset,
  setScores,
  appendScores,
  appendScoresAt,
  overwriteScores,
} = scoresSlice.actions;
