import round from 'lodash/round';
import {
  FC,
  useCallback,
  useRef,
  useState,
  KeyboardEvent,
  useMemo,
  useEffect,
} from 'react';

import styles from './ColorPointerInput.module.less';

interface IColorPointerInputProps {
  left: number;
  gradientChange: (
    leftPosition: number,
    indexOfSelectedColor: number,
    isMouseUp: boolean
  ) => void;
  gradientScaleWidth: number;
  indexOfSelectedColor: number;
  range: { min: number; max: number };
  isScoredColorizingMode: boolean;
  isOnDrag: boolean;
  isInputVisible: boolean;
  onChangeInputVisibility: (indexOfSelectedColor: number) => void;
}

export const ColorPointerInput: FC<IColorPointerInputProps> = ({
  left,
  indexOfSelectedColor,
  isScoredColorizingMode,
  range,
  gradientScaleWidth,
  gradientChange,
  isOnDrag,
  onChangeInputVisibility,
  isInputVisible,
}) => {
  const initialInputValue = useMemo(() => {
    if (gradientScaleWidth !== 0) {
      return isScoredColorizingMode
        ? round(left / gradientScaleWidth, 2)
        : ((range.max - range.min) * left) / gradientScaleWidth + range.min;
    }

    return 0;
  }, [gradientScaleWidth, isScoredColorizingMode, left, range.max, range.min]);
  const [inputValue, setInputValue] = useState(initialInputValue);

  const inputRef = useRef<HTMLInputElement>(null);

  const onInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(Number(event.target.value));
  }, []);

  const onSubmit = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        let newLeftPosition = left;

        if (!inputValue) {
          gradientChange(left, indexOfSelectedColor, true);
          onChangeInputVisibility(-1);
        } else if (isScoredColorizingMode && Number(inputValue) > 1) {
          newLeftPosition = 1 * gradientScaleWidth;
        } else if (!isScoredColorizingMode && Number(inputValue) > range.max) {
          newLeftPosition = gradientScaleWidth;
        } else if (
          (isScoredColorizingMode && Number(inputValue) < 0) ||
          (!isScoredColorizingMode && Number(inputValue) < range.min)
        ) {
          newLeftPosition = 0;
        } else {
          newLeftPosition = isScoredColorizingMode
            ? Number(inputValue) * gradientScaleWidth
            : (gradientScaleWidth * (Number(inputValue) - range.min)) /
              (range.max - range.min);
        }
        gradientChange(newLeftPosition, indexOfSelectedColor, true);
        onChangeInputVisibility(-1);
      }
    },
    [
      gradientChange,
      gradientScaleWidth,
      indexOfSelectedColor,
      inputValue,
      isScoredColorizingMode,
      left,
      onChangeInputVisibility,
      range.max,
      range.min,
    ]
  );

  useEffect(() => {
    setInputValue(initialInputValue);
  }, [initialInputValue, isInputVisible]);

  useEffect(() => {
    if (inputRef.current && !isOnDrag) {
      inputRef.current.select();
    }
  }, [isInputVisible, isOnDrag]);

  return (
    <input
      ref={inputRef}
      className={styles.input}
      type="number"
      value={inputValue}
      onChange={onInputChange}
      onKeyPress={onSubmit}
    />
  );
};
