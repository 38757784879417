import { FieldType } from '../../serverModels/IDatasetMetaModel';

export enum SpecialColumn {
  RowNumber = 'RowNumber',
  Score = 'Score',
  Radar = 'Radar',
  NotUsed = 'NotUsed',
  Missing = 'Missing',
  Comparison = 'Comparison',
}

export type ColumnId = string;

export type ColumnType = FieldType | SpecialColumn;

export function isScoringType(
  type: ColumnType | undefined | null,
  isDiscreteColumn: boolean | undefined
) {
  return type === FieldType.Number || (type === FieldType.String && !!isDiscreteColumn);
}

export function isScoringColumn(col: Typed | undefined | null): col is ScoringTyped {
  return isScoringType(col?.type, col?.isDiscreteColumn);
}

export function isNumericColumn(col: Typed | undefined | null): boolean {
  return col?.type === FieldType.Number;
}

export interface Typed {
  type: ColumnType;
  isDiscreteColumn: boolean;
  isDiscreteStringColumn: boolean;
}

export interface ScoringTyped {
  type: FieldType.Number;
  isDiscreteColumn: boolean;
  isComputed: boolean;
  isDiscreteStringColumn: boolean;
}

export interface IColumnHeader {
  columnId: ColumnId;
  title: string;
  type: ColumnType;
  isDiscreteColumn: boolean;
  isComputedColumn: boolean;
  isDiscreteStringColumn: boolean;
}

export interface IColumnDetails {
  columnId: string;
  type: SpecialColumn;
  isDiscreteColumn: boolean | undefined;
}
