import { DatasetRowId } from '../../serverModels/IDatasetModel';

import { DTNodeId, DTNodeType } from './decisionTree';

/**
 * The result of applying the decision tree to the current dataset.
 * It contains information about dataset rowIds on each node output
 *
 * For cyclic decision tree we are still able to calculate a result
 * but the result contains only nodes from an acyclic part of the graph
 */
export interface DecisionTreeResult {
  datasetRowIds: Set<DatasetRowId>;
  nodes: Record<DTNodeId, DecisionTreeNodeResult>;
  isCyclicGraph: boolean;
}

/**
 * Node results that has
 *   outputDatasetRowIds: {
 *     yes: Set<DatasetRowId>;
 *     no: Set<DatasetRowId>;
 *     missingValues: Set<DatasetRowId>;
 *   };
 */
export type DecisionTreePropertyLikeNodeResult =
  | DecisionTreePropertyNodeResult
  | DecisionTreeDateNodeResult
  | DecisionTreeStructureNodeResult;

export type DecisionTreeNodeResult =
  | DecisionTreePropertyLikeNodeResult // property, date, or structure
  | DecisionTreeAndNodeResult
  | DecisionTreeOrNodeResult
  | DecisionTreeAtLeastNNodeResult
  | DecisionTreeAtMostXNodeResult
  | DecisionTreeChartNodeResult;

export interface DecisionTreeCommonNodeResult {
  id: DTNodeId;
  type: DTNodeType;

  inputDatasetRowIds: Set<DatasetRowId>;
  outputDatasetRowIds: {
    [slotName: string]: Set<DatasetRowId>;
  };
}

export interface DecisionTreePropertyNodeResult extends DecisionTreeCommonNodeResult {
  type: DTNodeType.Property;

  outputDatasetRowIds: {
    yes: Set<DatasetRowId>;
    no: Set<DatasetRowId>;
    missingValues: Set<DatasetRowId>;
  };
}

export interface DecisionTreeDateNodeResult extends DecisionTreeCommonNodeResult {
  type: DTNodeType.Date;

  outputDatasetRowIds: {
    yes: Set<DatasetRowId>;
    no: Set<DatasetRowId>;
    missingValues: Set<DatasetRowId>;
  };
}

export interface DecisionTreeStructureNodeResult extends DecisionTreeCommonNodeResult {
  type: DTNodeType.StructureSearch;

  outputDatasetRowIds: {
    yes: Set<DatasetRowId>;
    no: Set<DatasetRowId>;
    missingValues: Set<DatasetRowId>;
  };
}

export interface DecisionTreeAndNodeResult extends DecisionTreeCommonNodeResult {
  type: DTNodeType.And;

  outputDatasetRowIds: {
    combine: Set<DatasetRowId>;
  };
}

export interface DecisionTreeAtLeastNNodeResult extends DecisionTreeCommonNodeResult {
  type: DTNodeType.ALN;

  outputDatasetRowIds: {
    combine: Set<DatasetRowId>;
  };
}

export interface DecisionTreeAtMostXNodeResult extends DecisionTreeCommonNodeResult {
  type: DTNodeType.AMX;

  outputDatasetRowIds: {
    combine: Set<DatasetRowId>;
  };
}

export interface DecisionTreeOrNodeResult extends DecisionTreeCommonNodeResult {
  type: DTNodeType.Or;

  outputDatasetRowIds: {
    combine: Set<DatasetRowId>;
  };
}

export interface DecisionTreeChartNodeResult extends DecisionTreeCommonNodeResult {
  type: DTNodeType.Chart;

  outputDatasetRowIds: {
    combine: Set<DatasetRowId>;
  };
}

export type DecisionTreePropertyNodeOutputCounts = {
  [key in keyof DecisionTreePropertyNodeResult['outputDatasetRowIds']]: number;
};

export function isPropertyNodeResult(
  node: DecisionTreeNodeResult
): node is DecisionTreePropertyNodeResult {
  return node.type === DTNodeType.Property;
}

export function isDateNodeResult(
  node: DecisionTreeNodeResult
): node is DecisionTreeDateNodeResult {
  return node.type === DTNodeType.Date;
}

export function isStructureNodeResult(
  node: DecisionTreeNodeResult
): node is DecisionTreeStructureNodeResult {
  return node.type === DTNodeType.StructureSearch;
}

export function isPropertyLikeNodeResult(
  node: DecisionTreeNodeResult
): node is DecisionTreePropertyLikeNodeResult {
  return (
    node.type === DTNodeType.Property ||
    node.type === DTNodeType.Date ||
    node.type === DTNodeType.StructureSearch
  );
}

export function isOrNodeResult(
  node: DecisionTreeNodeResult
): node is DecisionTreeOrNodeResult {
  return node.type === DTNodeType.Or;
}

export function isAndNodeResult(
  node: DecisionTreeNodeResult
): node is DecisionTreeAndNodeResult {
  return node.type === DTNodeType.And;
}

export function isAtLeastNNodeResult(
  node: DecisionTreeNodeResult
): node is DecisionTreeAtLeastNNodeResult {
  return node.type === DTNodeType.ALN;
}

export function isAtMostXNodeResult(
  node: DecisionTreeNodeResult
): node is DecisionTreeAtMostXNodeResult {
  return node.type === DTNodeType.AMX;
}

export function isChartNodeResult(
  node: DecisionTreeNodeResult
): node is DecisionTreeChartNodeResult {
  return node.type === DTNodeType.Chart;
}
