import {
  DirectedGraph,
  DirectedGraphNode,
  sortDirectedGraphTopologically,
} from '@discngine/moosa-common';
import { DecisionTree, DTNodeId } from '@discngine/moosa-models';

function dtToGraph(dt: DecisionTree): DirectedGraph<DTNodeId> {
  const graph: DirectedGraph<DTNodeId> = new Map();

  for (const node of dt.nodes) {
    const dagNode: DirectedGraphNode<DTNodeId> = {
      id: node.id,
      in: new Set(),
      out: new Set(),
    };

    graph.set(node.id, dagNode);
  }

  // fill in and out
  dt.arrows.forEach((arrow) => {
    const from = graph.get(arrow.from);

    if (!from) {
      throw new Error(`No node ${arrow.from} for arrow ${arrow.id}`);
    }
    from.out.add(arrow.to);

    const to = graph.get(arrow.to);

    if (!to) {
      throw new Error(`No node ${arrow.to} for arrow ${arrow.id}`);
    }
    to.in.add(arrow.from);
  });

  return graph;
}

export function sortDecisionTreeTopologically(dt: DecisionTree): {
  nodeIds: DTNodeId[];
  isCyclicGraph: boolean;
} {
  const graph = dtToGraph(dt);

  return sortDirectedGraphTopologically(graph);
}
