import { FieldType } from '../../serverModels/IDatasetMetaModel';
import {
  DesirabilityFunctionFilter,
  DesirabilityFunctionMissingValue,
  DesirabilityFunctionType,
  IDesirabilityFunction,
  MeasurementError,
} from '../../serverModels/IScoringTemplate';

import { IColumnMetaInfo } from './tableInfo';

export interface IPoint {
  id: string;
  x: number;
  y: number;
  originalPointIndex?: number;
}

export interface IDiscretePoint {
  id: string;
  x: number | string;
  y: number;
  mark?: boolean; // show mark on the line
  originalPointIndex?: number;
  isAddedByUser?: boolean;
  count?: number;
  isNotInFunction?: boolean; // point exists in dataset but is absent in desirability function
}

export interface LinearFunctionParams {
  points: [IPoint, IPoint];
}

export interface TriangularFunctionParams {
  points: [IPoint, IPoint, IPoint];
}

export interface RectangularFunctionParams {
  points: [IPoint, IPoint, IPoint, IPoint];
}

export interface UnitStepFunctionParams {
  xStep: number;
  yLeft: number;
  yRight: number;
}

export interface LogarithmicFunctionParams {
  base: number;
  xForY0: number;
  xForY1: number;
}

// f(x) = L / (1 + exp(-K * (x - xCenter)))
export interface SigmoidFunctionParams {
  l: number; // [-1, 1]
  xCenter: number;
  k: number; // (0, Inf)
}

export interface CustomCurveFunctionParams {
  points: IPoint[];
}

export interface DiscreteFunctionParams {
  points: IDiscretePoint[];
}

export type DesirabilityFunctionParams = {
  [DesirabilityFunctionType.linear]: LinearFunctionParams;
  [DesirabilityFunctionType.unitStep]: UnitStepFunctionParams;
  [DesirabilityFunctionType.triangular]: TriangularFunctionParams;
  [DesirabilityFunctionType.rectangular]: RectangularFunctionParams;
  [DesirabilityFunctionType.logarithmic]: LogarithmicFunctionParams;
  [DesirabilityFunctionType.sigmoid]: SigmoidFunctionParams;
  [DesirabilityFunctionType.custom]: CustomCurveFunctionParams;
  [DesirabilityFunctionType.discrete]: DiscreteFunctionParams | null;
};

export type DesirabilityFunctionSingleParams =
  | LinearFunctionParams
  | UnitStepFunctionParams
  | TriangularFunctionParams
  | RectangularFunctionParams
  | LogarithmicFunctionParams
  | SigmoidFunctionParams
  | CustomCurveFunctionParams
  | DiscreteFunctionParams
  | null;

export interface ILinePoint extends IPoint {
  mark?: boolean; // show mark on the line
}

export interface ILineDiscretePoint extends IDiscretePoint {
  mark?: boolean; // show mark on the line
}

export type ServerLinePoint = Omit<ILinePoint, 'id' | 'originalPointIndex'>;
export type ServerDiscretePoint = Omit<IDiscretePoint, 'id' | 'originalPointIndex'>;

export interface IRange {
  min: number;
  max: number;
}

export interface DesirabilityFunctionRules<Params> {
  init(metadata: IColumnMetaInfo): Params;
  getValue(xVal: number | string, params: Params): number;
  getRange(params: Params, metadata: IColumnMetaInfo): IRange;
  toLine(params: Params, range: IRange): ILinePoint[] | ILineDiscretePoint[];
  toServerTemplate(params: Params): ServerLinePoint[] | ServerDiscretePoint[];
  fromServerTemplate(
    points: ServerLinePoint[] | ServerDiscretePoint[],
    metadata: IColumnMetaInfo
  ): Params;
  movePoint(params: Params, point: ILinePoint): Params;
  addPoint?(
    params: Params,
    point: ILinePoint | ILineDiscretePoint,
    type: FieldType,
    xSort: SortFunctionTableType,
    ySort: SortFunctionTableType
  ): Params;
  removePoint?(params: Params, index: number): Params;
  sortPoints?(params: Params, isAscendingSort: boolean): Params;
}

export type FunctionRules = {
  [DesirabilityFunctionType.linear]: DesirabilityFunctionRules<LinearFunctionParams>;
  [DesirabilityFunctionType.unitStep]: DesirabilityFunctionRules<UnitStepFunctionParams>;
  [DesirabilityFunctionType.triangular]: DesirabilityFunctionRules<TriangularFunctionParams>;
  [DesirabilityFunctionType.rectangular]: DesirabilityFunctionRules<RectangularFunctionParams>;
  [DesirabilityFunctionType.logarithmic]: DesirabilityFunctionRules<LogarithmicFunctionParams>;
  [DesirabilityFunctionType.sigmoid]: DesirabilityFunctionRules<SigmoidFunctionParams>;
  [DesirabilityFunctionType.custom]: DesirabilityFunctionRules<CustomCurveFunctionParams>;
  [DesirabilityFunctionType.discrete]: DesirabilityFunctionRules<DiscreteFunctionParams | null>;
};

export interface IScoringFuncProperty {
  column: string;
  isInUse: boolean;
  weight: number;
  type: DesirabilityFunctionType;
  functionParams: DesirabilityFunctionParams;
  color: string;
  filter?: DesirabilityFunctionFilter;
  missingValue?: DesirabilityFunctionMissingValue;
  measurementError: MeasurementError;
  name?: string;
  isDiscreteStringFunction?: boolean; // only for DesirabilityFunctionType.discrete
}

export type ScoringFunction = Record<string, IScoringFuncProperty>;

export interface IScoringTemplateData {
  func: ScoringFunction;
  order: string[];
}

export interface IScoringTemplateState extends IScoringTemplateData {
  func: ScoringFunction;
  order: string[];
  changed: boolean;
  openPropertyPanels: Record<string, 1>;
  customFunctions: IDesirabilityFunction[];
}

export interface IScoringTemplateSubRoot {
  scoringTemplate: IScoringTemplateState;
}

export enum SortFunctionTableType {
  Ascending = 'Ascending',
  Descending = 'Descending',
  None = 'None',
}
