import { ColumnId } from '@discngine/moosa-models';
import { setColumnNames } from '@discngine/moosa-store/tableConfig';
import { selectTableColumnNames } from '@discngine/moosa-store/tableConfig';
import { selectTableId } from '@discngine/moosa-store/tableInfo';
import { Button, Input } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './EditLabelsForm.module.less';

export const EditLabelsForm = () => {
  const dispatch = useDispatch();
  const tableID = useSelector(selectTableId);
  const columnNames = useSelector(selectTableColumnNames);

  const [editingNames, setEditingNames] = useState<Record<string, string>>({});
  const columnList = useMemo(() => Object.keys(columnNames), [columnNames]);

  useEffect(() => {
    setEditingNames(columnNames);
  }, [columnNames, setEditingNames]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.listWrapper}>
        {columnList.map((columnID: ColumnId) => {
          const value: string = editingNames[columnID];

          return (
            <div key={columnID} className={styles.rowWrapper}>
              <span>{columnID}</span>
              <Input
                className={styles.field}
                placeholder={columnID}
                value={value}
                onChange={(event) => {
                  const newNames = {
                    ...editingNames,
                    [columnID]: event.target.value,
                  };

                  setEditingNames(newNames);
                }}
              />
            </div>
          );
        })}
      </div>

      <Button
        onClick={() => {
          dispatch(
            setColumnNames({
              tableId: tableID,
              columnNames: editingNames,
            })
          );
        }}
      >
        Save
      </Button>
    </div>
  );
};
