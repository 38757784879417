import {
  AVAILABLE_COLORS_FOR_SAR_COLORIZING,
  IRGBColor,
  isEqualColor,
  rgbToString,
} from '@discngine/moosa-common';
import React, { FC, useCallback, useMemo } from 'react';

import { useComponentsContext } from '../ComponentsContext/ComponentsContext';

import styles from './AvailableColorsPanel.module.less';
import { IAvailableColorsPanel } from './IAvailableColorsPanel';

export const AvailableColorsPanel: FC<IAvailableColorsPanel> = React.memo(
  ({
    selectedColor,
    onColorChange,
    onRemoveColor,
    indexOfSelectedColor,
    isPossibleToRemoveColor,
    showFullColorsPalette,
  }) => {
    const { Icons } = useComponentsContext();

    const onRemove = useCallback(() => {
      onRemoveColor(indexOfSelectedColor);
    }, [onRemoveColor, indexOfSelectedColor]);

    const currentColorsPalette = useMemo(() => {
      if (!showFullColorsPalette) {
        return AVAILABLE_COLORS_FOR_SAR_COLORIZING.map((colorsColumn) => [
          colorsColumn[0],
        ]);
      }

      return AVAILABLE_COLORS_FOR_SAR_COLORIZING;
    }, [showFullColorsPalette]);

    return (
      <div className={styles.root}>
        <div className={styles.availableColors}>
          {currentColorsPalette.map((colorsColumn: IRGBColor[], index: number) => (
            <div key={index} className={styles.colorsColumn}>
              {colorsColumn.map((color, colorIndex) => (
                <div
                  key={colorIndex}
                  className={`${styles.colorItem} ${styles.discreteParameter}`}
                  style={{ background: rgbToString(color) }}
                  onClick={() => onColorChange(color, indexOfSelectedColor)}
                >
                  {selectedColor && isEqualColor(selectedColor, color) && (
                    <Icons.CheckIcon className={styles.checkIcon} />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        {isPossibleToRemoveColor && (
          <Icons.DeleteIcon className={styles.deleteIcon} onClick={onRemove} />
        )}
      </div>
    );
  }
);
