import {
  IStructureEditor,
  IStructureEditorAPI,
  IStructureRenderer,
  StructureType,
} from '@discngine/moosa-models';
import { Button, Modal } from 'antd';
import React, { useCallback, useState } from 'react';

import styles from './SarMatrixRequestCore.module.less';
import { SarMatrixRequestScaffold } from './SarMatrixRequestScaffold';
import { valueConverter } from './utils';

export interface ISarMatrixRequestScaffoldsProps {
  scaffoldData: string[];
  onSetScaffoldData: (structure: string[]) => void;
  StructureEditor: IStructureEditor;
  structureRenderer: IStructureRenderer;
}

export const SarMatrixRequestScaffolds: React.FC<ISarMatrixRequestScaffoldsProps> = ({
  scaffoldData,
  onSetScaffoldData,
  StructureEditor,
  structureRenderer,
}) => {
  const [isEditorVisible, setEditorVisibility] = useState(false);
  const [editedItem, setEditedItem] = useState(-1);
  const [editorApi, setEditorApi] = useState<IStructureEditorAPI>();
  // const [localScaffolds, setLocalScaffolds] = useState(scaffolds);

  const handleScaffoldsChange = useCallback(
    (index: number, value: string | null) => {
      const newStructure = [...scaffoldData];

      if (!value) {
        newStructure.splice(index, 1);
      } else {
        newStructure[index] = value;
      }

      onSetScaffoldData(newStructure);
      onSetScaffoldData(newStructure);
    },
    [onSetScaffoldData, scaffoldData]
  );

  const handleShowEditor = useCallback((index: number) => {
    setEditorVisibility(true);
    setEditedItem(index);
  }, []);

  const handleItemAdd = useCallback(() => {
    const index = scaffoldData.length;

    onSetScaffoldData([...scaffoldData, '']);
    handleShowEditor(index);
  }, [handleShowEditor, scaffoldData, onSetScaffoldData]);

  const handleItemRemove = useCallback(
    (index: number) => {
      const newStructure = [...scaffoldData];

      newStructure.splice(index, 1);
      onSetScaffoldData(newStructure);
      onSetScaffoldData(newStructure);
    },
    [scaffoldData, onSetScaffoldData]
  );

  const handleOk = useCallback(() => {
    if (editorApi) {
      editorApi.getStructure(StructureType.MOLFILE).then((value: string | null) => {
        const scaffoldValue = valueConverter ? valueConverter(value) : value;

        handleScaffoldsChange(editedItem, scaffoldValue);
        setEditorVisibility(false);
        setEditedItem(-1);
      });
    }
  }, [editedItem, editorApi, handleScaffoldsChange]);

  const handleCancel = useCallback(() => {
    setEditorVisibility(false);
  }, []);

  return (
    <>
      {scaffoldData.length === 0 && <h3>Core</h3>}

      <div>
        {scaffoldData.map((structureString, index) => (
          <SarMatrixRequestScaffold
            key={index}
            index={index}
            structure={structureString}
            StructureRenderer={structureRenderer}
            onRemove={handleItemRemove}
            onShowEditor={handleShowEditor}
          />
        ))}

        <Button className={styles.button} onClick={handleItemAdd}>
          {scaffoldData.length === 0 && <span>Add core</span>}
          {scaffoldData.length > 0 && <span>Add another core</span>}
        </Button>
      </div>
      <Modal
        open={isEditorVisible}
        title="Structure Editor"
        width={1280}
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <div className={styles.editorWrapper}>
          <StructureEditor
            structure={scaffoldData[editedItem] ?? ''}
            onInit={setEditorApi}
          />
        </div>
      </Modal>
    </>
  );
};
