import { EllipsisOutlined } from '@ant-design/icons';
import { IScoringTemplate, getColor } from '@discngine/moosa-models';
import { Popover } from 'antd';
import React, { useMemo, useState } from 'react';

import styles from './TemplateDetails.module.less';
import { TemplateDetailsColumn } from './TemplateDetailsColumn';

export const TEMPLATE_MENU_INTERNAL = 'template-menu-internal';

const colorGenerator = getColor();

export const TemplateDetails: React.FC<{ template: IScoringTemplate }> = ({
  template,
}) => {
  const [visible, setVisible] = useState<any>(undefined);

  const weights = useMemo(() => {
    let weights = template.columns.map((column) => column.weight);
    const max = Math.max(...weights);

    weights = weights.map((weight) => Math.ceil((weight / max) * 100));

    return weights;
  }, [template]);

  const colors = useMemo(
    () => template.columns.map((column) => column.color ?? colorGenerator.next().value),
    [template]
  );

  // forced close - otherwise there are artifacts because the menu closes first
  const visibleProp = visible !== undefined ? { visible } : {};

  return (
    <Popover
      content={
        <div>
          <p className={styles.popoverTitle}>{template.name}</p>
          <div className={styles.weights}>
            {weights.map((weight, index) => (
              <div
                key={index}
                className={styles.weight}
                style={{
                  backgroundColor: colors[index],
                  height: `${weight}%`,
                }}
              />
            ))}
          </div>
          <div className={styles.columnList}>
            {template.columns.map((column, index) => (
              <TemplateDetailsColumn
                key={index}
                color={colors[index]}
                templateItem={column}
              />
            ))}
          </div>
        </div>
      }
      overlayClassName={styles.overlay}
      placement="right"
      trigger="hover"
      {...visibleProp}
    >
      <EllipsisOutlined
        className={TEMPLATE_MENU_INTERNAL}
        onClick={() => setVisible(false)}
      />
    </Popover>
  );
};
