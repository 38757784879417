import {
  DTNodeId,
  DTArrowId,
  DTNodeType,
  DecisionTreeNode,
  DecisionTreeArrow,
  DecisionTreeResult,
  Condition,
  DecisionTreeGroupedResult,
  PortGroupingType,
  ColumnId,
  DatasetRowId,
  IColumnLabelMap,
} from '@discngine/moosa-models';
import { ReactChild } from 'react';

/**
 * Position of a diagram node.
 */
export type DiagramNodePosition = XYCoord;

/**
 * An enumeration of different histogram views in the diagram.
 */
export enum DiagramHistogramView {
  All = 'All',
  Incoming = 'Incoming',
  Hide = 'Hide',
}

/**
 * Layout options for the diagram.
 */
export type DiagramLayoutType = 'Auto' | 'Manual';

/**
 * Data of a diagram.
 * Used only in the diagram as local storage for calculated data.
 */
export interface DiagramData {
  result: DecisionTreeResult;
  groups: DecisionTreeGroupedResult | null;
  isHighlighted: boolean;
  highlightedPort: DiagramNodePort | DiagramArrowPort | null;
  highlightedNode: DTNodeId | null;
  histogramView: DiagramHistogramView;
  selectedNode: DiagramNodeData | null;
  editableNodeId: DTNodeId | null;
  columnLabelMap?: IColumnLabelMap;
}

/**
 * Data of a diagram node.
 */
export interface DiagramNodeData {
  isGroup?: Boolean;
  key: DTNodeId;
  group?: string;
  category: DTNodeType;
  text: string;
  position: DiagramNodePosition;

  // Property node
  condition: Condition | null;
  portGroupingType: PortGroupingType | null;
  mismatch: NodeConditionMismatch | null;

  // Structure search node
  structure: string | null;
  structSvg: string | null;

  // ALN node
  nodeN: number | null;

  // Chart node
  columnId: string | null;
  showFullDataset: boolean;
}

/**
 * Data of a diagram arrow.
 */
export interface DiagramArrowData {
  group?: string;
  key: DTArrowId;
  from: DTNodeId;
  fromPort: DTNodeOutputPortType | '';
  to: DTNodeId;
  toPort: 'input';
}

/**
 * The interface representing a decision tree.
 */
export interface DecisionTree {
  nodes: DecisionTreeNode[];
  arrows: DecisionTreeArrow[];
}

/**
 * The interface representing the state of the diagram drawn on the canvas.
 */
export interface DiagramState {
  id: string;
  name: string;
  versionName?: string;
  layout: DiagramLayoutType;
  nodes: DiagramNodeData[];
  arrows: DiagramArrowData[];
  skipsDiagramUpdate: boolean;
  editableNodeId: DTNodeId | null;
}

/**
 * The type of the draggable item.
 */
export type DndItem = {
  type: string;
};

/**
 * Result of a drop action in the diagram.
 */
export interface DropResult {
  location: string;
  diagramCoords: XYCoord | null;
  port: DiagramNodePort | DiagramArrowPort | null;
  node: DiagramNodeData | null;
}

/**
 * Coordinates in the diagram.
 */
export interface XYCoord {
  x: number;
  y: number;
}

/**
 * The interface representing a port of a specific node in the diagram.
 */
export interface DiagramNodePort {
  type: 'nodePort';
  nodeId: string;
  portType: DTNodeOutputPortType;
}

export interface DiagramArrowPort {
  type: 'arrowPort';
  arrowId: string;
}

/**
 * Available ports for a decision tree node.
 */
export type DTNodeOutputPortType = 'yes' | 'no' | 'missingValues' | 'combine';

/**
 * Available types of node condition and metadata mismatches.
 */
export enum NodeConditionMismatchType {
  MissingColumn = 'MissingColumn',
  MissingDiscreteValues = 'MissingDiscreteValues',
  Text = 'TextColumn',
  NumericToDiscrete = 'NumericToDiscrete',
  DiscreteTextToNumeric = 'DiscreteTextToNumeric',
  NotStructureColumn = 'NotStructureColumn',
  NotDateColumn = 'NotDateColumn',
}

/**
 * Levels of node condition and metadata mismatches.
 */
export enum NodeConditionMismatchLevel {
  Critical = 'Critical',
  Warning = 'Warning',
}

/**
 * Node condition and metadata mismatch.
 */
export interface NodeConditionMismatch {
  level: NodeConditionMismatchLevel;
  type: NodeConditionMismatchType;
}

/**
 * Represents a custom menu item that can be used in a node's menu.
 */
export interface MenuItem {
  label: string;
  icon?: ReactChild;
  isEnabled: boolean;
  onClick: (nodeId: DTNodeId, propertyId: ColumnId) => void;
}

export interface CommonNodeTemplateProps {
  onResultsClick?: (
    nodeId: DTNodeId,
    propertyId: ColumnId | null,
    rowIDs: Set<DatasetRowId> | null
  ) => void;
}
