import {
  ConditionType,
  DTNodeType,
  DTOperation,
  GraphNode,
  GraphArrow,
  IColumnMetaInfo,
  IDecisionTreeNew,
  isPropertyNode,
  PortGroupingType,
  ScoringFunction,
} from '@discngine/moosa-models';
import { v4 as uuid } from 'uuid';

import { NODE_WIDTH, NODES_GAP } from './constants';

export const convertScoringTemplateToGraphNodes = (
  scoringTemplates: ScoringFunction,
  columnMeta: Record<string, IColumnMetaInfo>
): GraphNode[] => {
  const columnCodes = Object.keys(scoringTemplates).filter((el) => columnMeta[el || '']);

  const nodes: GraphNode[] = [];

  for (const code of columnCodes) {
    const desirability = structuredClone(scoringTemplates[code]);

    delete desirability.name;

    const node: GraphNode = {
      x: 0,
      y: 0,
      id: uuid(),
      propertyId: code,
      condition: {
        type: ConditionType.DesirabilityFunction,
        desirability,
        operation: DTOperation.Greater,
        threshold: 0.7,
      },
      type: DTNodeType.Property,
      inputArrows: [],
      outputArrows: {
        yes: [],
        no: [],
        missingValues: [],
      },
      portGroupingType: PortGroupingType.Regular,
    };

    nodes.push(node);
  }

  return nodes;
};

export const convertScoringTemplateToDecisionTree = (
  scoringTemplate: ScoringFunction,
  columnMeta: Record<string, IColumnMetaInfo>
): IDecisionTreeNew => {
  const decisionTree: IDecisionTreeNew = {
    name: '',
    data: {
      layout: 'Auto',
      nodes: convertScoringTemplateToGraphNodes(scoringTemplate, columnMeta),
      arrows: [],
    },
  };

  if (decisionTree.data.nodes.length === 0) {
    return decisionTree;
  }

  // set coordinates
  const NODES_IN_ROW = 4;

  decisionTree.data.nodes.forEach((node, index) => {
    node.x = (NODE_WIDTH + NODES_GAP) * (index % NODES_IN_ROW);
    node.y = (NODE_WIDTH + NODES_GAP) * Math.floor(index / NODES_IN_ROW);
  });

  // add atLeastN node and arrows
  const alnNode: GraphNode = {
    x: ((NODE_WIDTH + NODES_GAP) * NODES_IN_ROW) / 2,
    y:
      (NODE_WIDTH + NODES_GAP) *
        Math.floor(decisionTree.data.nodes.length / NODES_IN_ROW) +
      1,
    id: uuid(),
    nodeN: Math.ceil(decisionTree.data.nodes.length / 2),
    type: DTNodeType.ALN,
    outputArrows: {
      combine: [],
    },
    inputArrows: [],
  };

  const arrows = decisionTree.data.nodes.map((node) => {
    const arrow: GraphArrow = {
      id: uuid(),
      from: node.id,
      to: alnNode.id,
    };

    if (isPropertyNode(node)) {
      node.outputArrows.yes.push(arrow.id);
    }
    alnNode.inputArrows.push(arrow.id);

    return arrow;
  });

  decisionTree.data.nodes.push(alnNode);
  decisionTree.data.arrows = arrows;

  return decisionTree;
};
