import { DTNodeType } from '@discngine/moosa-models';
import { Radio, Space } from 'antd';
import React, { useEffect } from 'react';

import { DiagramLayoutType } from '../../../types';

interface LayoutModeProps {
  /**
   * The GoJS diagram instance to handle external updates.
   */
  diagram: go.Diagram;

  /**
   * The current layout mode.
   */
  layoutMode: DiagramLayoutType;

  /**
   * The callback function for handling layout mode changes.
   *
   * @param layoutMode - The new layout mode.
   */
  onLayoutModeChange: (layoutMode: DiagramLayoutType) => void;
}

/**
 * Component for controlling the layout mode of a diagram.
 *
 * @param diagram - The GoJS diagram instance.
 * @param layoutMode - The current layout mode.
 * @param onLayoutModeChange - The callback function for handling layout mode changes.
 */
export const LayoutMode = ({
  diagram,
  layoutMode,
  onLayoutModeChange,
}: LayoutModeProps) => {
  useEffect(() => {
    const handler = (event: go.DiagramEvent) => {
      if (
        !event.diagram.selection.any(
          (part) => part.category === DTNodeType.Group || part.data.group
        )
      ) {
        onLayoutModeChange('Manual');
      }
    };

    diagram.addDiagramListener('SelectionMoved', handler);

    return () => diagram.removeDiagramListener('SelectionMoved', handler);
  }, [diagram, onLayoutModeChange]);

  return (
    <Space size={10} wrap={true}>
      <span>Layout:</span>

      <Radio.Group
        buttonStyle="solid"
        optionType="button"
        value={layoutMode}
        onChange={(event) => onLayoutModeChange(event.target.value)}
      >
        <Radio.Button key={'Auto'} value={'Auto'}>
          Auto
        </Radio.Button>

        <Radio.Button key={'Manual'} value={'Manual'}>
          Manual
        </Radio.Button>
      </Radio.Group>
    </Space>
  );
};
