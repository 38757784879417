import { ValueInputProps } from '@discngine/moosa-shared-components-gen';
import { Input } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';

export const ValueInput: FC<ValueInputProps> = React.memo(function ValueInput({
  value,
  onValueChange,
  disabled,
  size,
  className,
  type,
  addonAfter,
  placeholder,
}) {
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value]);

  const resetVal = useCallback(() => {
    setVal(value);
  }, [value]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setVal(event.target.value);
      onValueChange(event.target.value);
    },
    [onValueChange]
  );

  return (
    <Input
      addonAfter={addonAfter}
      className={className}
      disabled={disabled}
      placeholder={placeholder}
      size={size}
      type={type}
      value={val}
      onBlur={resetVal}
      onChange={handleChange}
    />
  );
});
