import { DecisionTreeResult } from '@discngine/moosa-models';
import * as go from 'gojs';

import '../extensions/RoundedRectangles';
import '../extensions/Buttons';

import {
  CommonNodeTemplateProps,
  DiagramNodeData,
  NodeConditionMismatchLevel,
} from '../../../types';

import { getNodeTypeMismatchMessage } from './common';
import { propertyLikeNodeFrame } from './propertyLikeCommon';

export interface IStructureNodeTemplateProps extends CommonNodeTemplateProps {
  // getHistogram?: (nodeData: DiagramNodeData, rowIds: Set<DatasetRowId>) => string | null;
  onEditConditionClick?: (data: DiagramNodeData) => void;
  showMenu?: (a: go.GraphObject, b: go.Diagram, c: go.Tool) => void;
  hideMenu?: () => void;
}

export const structureNodeTemplate = ({
  onEditConditionClick,
  onResultsClick,
  showMenu,
  hideMenu,
}: IStructureNodeTemplateProps) => {
  const bodyPanel = (bodyPanel: go.Panel) =>
    bodyPanel
      .add(getNodeTypeMismatchMessage())
      .add(
        new go.TextBlock('Substructure search is not specified', {
          margin: new go.Margin(40, 0, 0, 0),
          font: '600 14px Roboto, sans-serif',
          verticalAlignment: go.Spot.Center,
        }).bind('visible', 'structure', (value) => value === null)
      )
      .add(structurePanel());

  return propertyLikeNodeFrame({
    onEditConditionClick,
    onResultsClick,
    showMenu,
    hideMenu,
    bodyPanel,
  });
};

function structurePanel() {
  return new go.Picture({ width: 175, height: 175 })
    .bind(
      new go.Binding(
        'source',
        'result',
        (result: DecisionTreeResult, object: go.GraphObject) => {
          if (!object.part) return null;

          const node = object.part.data as DiagramNodeData;

          return node.structSvg
            ? `data:image/svg+xml;base64,${window.btoa(
                unescape(encodeURIComponent(node.structSvg))
              )}`
            : '';
        }
      ).ofModel()
    )
    .bind('visible', 'mismatch', (mismatch: DiagramNodeData['mismatch']) => {
      return mismatch?.level !== NodeConditionMismatchLevel.Critical;
    })
    .bind('visible', 'structure', (value) => value !== null);
}
