import { DTNodeId } from '@discngine/moosa-models';
import * as go from 'gojs';

import { CommonNodeTemplateProps, DiagramNodePosition } from '../../../types';
import {
  NODE_BORDER,
  NODE_FILL,
  OPERATOR_NODE_TEXT,
  getNodeBackgroundColor,
  getNodeBorderColor,
  getNodeBorderSize,
} from '../colors';

import { InputPort, OutputPort } from './common';

interface AndNodeTemplateProps extends CommonNodeTemplateProps {}

export const andNodeTemplate = ({ onResultsClick }: AndNodeTemplateProps) => {
  return (
    new go.Node('Vertical', {
      cursor: 'pointer',
      locationSpot: new go.Spot(0.5, 0, 0, 15),
    })
      // Prevent movement for grouped nodes
      .bind('movable', 'group', (group: DTNodeId) => !group)
      .bind('selectable', 'group', (group: DTNodeId) => !group)

      // Node position
      .bind(
        new go.Binding(
          'location',
          'position',
          (position: DiagramNodePosition): go.Point =>
            new go.Point(position.x, position.y),
          (location: go.Point): DiagramNodePosition => location
        )
      )

      // Input ports
      .add(InputPort('input'))

      // Node
      .add(
        new go.Panel('Spot', { name: 'container' })
          .add(
            new go.Shape('Circle', {
              alignment: go.Spot.Center,
              fill: NODE_FILL,
              width: 68,
              height: 68,
              stroke: NODE_BORDER,
              strokeWidth: 1,
            })
              .bind(
                new go.Binding(
                  'fill',
                  'highlightedNode',
                  getNodeBackgroundColor
                ).ofModel()
              )
              .bind(
                new go.Binding('stroke', 'highlightedNode', getNodeBorderColor).ofModel()
              )
              .bind(
                new go.Binding(
                  'strokeWidth',
                  'highlightedNode',
                  getNodeBorderSize
                ).ofModel()
              )
          )
          .add(
            new go.TextBlock({
              stroke: OPERATOR_NODE_TEXT,
              font: '500 14px Roboto, sans-serif',
              text: 'Intersect',
            })
          )
      )

      // Output ports
      .add(OutputPort({ portId: 'combine', onClick: onResultsClick }))
  );
};
