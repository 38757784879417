import go from 'gojs';
import { DiagramData, DiagramLayoutType } from 'types';

import { AutoLayout, ManualLayout } from './layouts';

/**
 * Updates a specific property of the diagram's model data with the provided value.
 * Disables the undo manager temporarily to prevent creating records in the diagram's history.
 *
 * @param diagram - The diagram object.
 * @param data - The partial DiagramData values to update.
 */

export const updateModelData = (diagram: go.Diagram, data: Partial<DiagramData>) => {
  // Disable the undo manager to prevent creating records in the diagram's history
  diagram.undoManager.isEnabled = false;

  // Update the specified property of the model's data with the provided value
  Object.entries(data).forEach(([propertyName, value]) => {
    diagram.model.setDataProperty(diagram.model.modelData, propertyName, value);
  });

  // Re-enable the undo manager
  diagram.undoManager.isEnabled = true;
};

/**
 * Diagram overview initialization method, which is passed to the ReactOverview component.
 * This method is responsible for making the diagram overview.
 */
export const initDiagramOverview = (): go.Overview => {
  return new go.Overview({ contentAlignment: go.Spot.Center });
};

/**
 * Checks if the provided layout is an auto layout.
 *
 * @param layout - The GoJS layout instance.
 * @returns True if the layout is an auto layout, false otherwise.
 */
export const isAutoLayout = (layout: go.Layout) => {
  return layout instanceof go.LayeredDigraphLayout;
};

/**
 * Retrieves the current layout mode of the diagram.
 *
 * @param diagram - The GoJS diagram instance.
 * @returns The layout mode of the diagram.
 */
export const getDiagramLayout = (diagram: go.Diagram): DiagramLayoutType => {
  return isAutoLayout(diagram.layout) ? 'Auto' : 'Manual';
};

/**
 * Applies the layout mode to the diagram.
 *
 * @param diagram - The GoJS diagram instance.
 * @param layoutMode - The layout mode to set.
 */
export const setDiagramLayout = (diagram: go.Diagram, layoutMode: DiagramLayoutType) => {
  diagram.layout = layoutMode === 'Auto' ? AutoLayout : ManualLayout;
  diagram.layout.doLayout(diagram);
};

/**
 * Find objects with a specific name at the mouse event's location in the diagram.
 *
 * @param event - The GoJS InputEvent generated by the diagram interaction.
 * @param name - The name of the objects to find in the diagram.
 * @returns - An iterator of GraphObjects matching the provided name or null.
 */
export const findObjectsByName = (event: go.InputEvent, name: string) => {
  const diagram = event.diagram;

  if (!diagram || !diagram.div) return null;

  const mouseEvent = event.event as MouseEvent;
  const diagramBox = diagram.div.getBoundingClientRect();
  const viewPoint = new go.Point(
    mouseEvent.clientX - diagramBox.left,
    mouseEvent.clientY - diagramBox.top
  );
  const documentPoint = diagram.transformViewToDoc(viewPoint);
  const objects = diagram.findObjectsAt(documentPoint);

  return objects.filter((object) => object.name === name);
};

export const MAX_LABEL_LENGTH = 35;

export const trimHeaderLabel = (label: string): string => {
  return label.length > MAX_LABEL_LENGTH
    ? label.substring(0, MAX_LABEL_LENGTH) + '...'
    : label;
};
