import {
  IDatasetMetaField,
  IColumnMetaInfo,
  IHistogramBar,
  createHistogramBars,
} from '@discngine/moosa-models';

export function toColumnMetaInfo(fields: IDatasetMetaField[]): IColumnMetaInfo[] {
  return fields.map((field) => {
    const histogramMax = Math.max(...(field.statistics?.histogram || [])) || 1; // todo temporary fix, wait for BE
    let histogramBars: IHistogramBar[] = [];
    let dataWidth = 0;
    const isDiscreteColumn = field.statistics?.textCategories !== null;

    if (field.statistics && field.statistics.histogram) {
      const min = field.statistics.min;
      const max = field.statistics.max;

      dataWidth = max - min;
      histogramBars = createHistogramBars(field.statistics);
    }

    const meta: IColumnMetaInfo = {
      ...field,
      histogramMax,
      histogramBars,
      dataWidth,
      isDiscreteColumn,
    };

    return meta;
  });
}
