import { DependencyList, RefObject, useLayoutEffect, useState, useCallback } from 'react';

export const useIsOverflow = (
  ref: RefObject<HTMLDivElement>,
  deps: DependencyList = []
) => {
  const [isOverflow, setIsOverflow] = useState<boolean | null>(null);

  const checkOverflow = useCallback(() => {
    if (!ref.current) return;

    setIsOverflow(ref.current.scrollWidth > ref.current.clientWidth);
  }, [ref]);

  useLayoutEffect(() => {
    if (!ref.current) return;

    checkOverflow();

    const resizeObserver = new ResizeObserver(() => checkOverflow());

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, checkOverflow, ...deps]);

  return isOverflow;
};
