import { DiscreteValue } from '@discngine/moosa-models';
import { Input, Space, Switch } from 'antd';
import React from 'react';

import styles from './shared/PropertyNodeEditPanel.module.less';

interface DiscreteConditionNewValueItemProps {
  value: DiscreteValue;
  onChange: (value: DiscreteValue) => void;
}

export const DiscreteConditionNewValueItem: React.FC<
  DiscreteConditionNewValueItemProps
> = ({ value, onChange }) => {
  return (
    <div>
      <div className={styles.discreteValueRow}>
        <div className={styles.value}>
          <Input
            autoComplete="off"
            required={true}
            value={value.value}
            onChange={(event) => onChange({ ...value, value: event.target.value })}
          />
        </div>

        <Space size={8}>
          <Switch
            checked={value.isSelected}
            checkedChildren="TRUE"
            unCheckedChildren="FALSE"
            onChange={(isSelected: boolean) => onChange({ ...value, isSelected })}
          />
          <div className={styles.deleteIcon} />
        </Space>
      </div>
    </div>
  );
};
