import { v4 as uuid } from 'uuid';

import {
  IDiscretePoint,
  IRange,
  ILineDiscretePoint,
  ServerDiscretePoint,
  ILinePoint,
} from '../scoringTemplate';

export function pointsCompare(p1: IDiscretePoint, p2: IDiscretePoint) {
  return p1.x === p2.x ? 0 : p1.x > p2.x ? 1 : -1;
}

export function toLine(points: IDiscretePoint[], range: IRange): ILineDiscretePoint[] {
  const line = points.reduce<IDiscretePoint[]>((acc, point, index) => {
    const fragment: IDiscretePoint[] = [
      {
        ...point,
        // to differentiate from original point
        id: uuid(),
        x: index,
        y: 0,
        mark: false,
        originalPointIndex: point.isNotInFunction ? undefined : point.originalPointIndex, // to not allow to move the point
      },
      {
        ...point,
        x: index,
        originalPointIndex: point.isNotInFunction ? undefined : point.originalPointIndex,
      },
      {
        ...point,
        // to differentiate from original point
        id: uuid(),
        x: index,
        y: 0,
        mark: false,
        originalPointIndex: point.isNotInFunction ? undefined : point.originalPointIndex,
      },
    ];

    acc.push(...fragment);

    return acc;
  }, []);

  return extendLineToRange(line, range);
}

export function convertToServerNeededFormat(
  points: IDiscretePoint[]
): ServerDiscretePoint[] {
  return points
    .filter((point) => !point.isNotInFunction)
    .map(({ x, y }) => ({
      x,
      y,
    }));
}

/**
 * this function mutate points array
 */
export function extendLineToRange(
  points: IDiscretePoint[],
  range: IRange
): ILineDiscretePoint[] {
  const first = points[0];
  const last = points[points.length - 1];

  // @ts-ignore
  if (range.min < first.x) {
    points.unshift({
      id: uuid(),
      x: range.min,
      y: first.y,
    });
  }

  // @ts-ignore
  if (range.max > last.x) {
    points.push({
      id: uuid(),
      x: range.max,
      y: last.y,
    });
  }

  return points;
}

export function updateLinePoint(
  points: ILineDiscretePoint[],
  { originalPointIndex, y: newY }: ILineDiscretePoint
): { points: ILineDiscretePoint[] } {
  const pnts = [...points];

  const y = checkYBoundaries(newY);

  const point = pnts.find((apoint) => apoint.originalPointIndex === originalPointIndex!)!;

  point.y = y;

  return { points: pnts };
}

const X_MIN = -1e24;
const X_MAX = 1e24;

export function checkXBoundaries(x: number | string) {
  // @ts-ignore
  if (x < X_MIN) return X_MIN;

  // @ts-ignore
  if (x > X_MAX) return X_MAX;

  return x;
}

export function checkYBoundaries(y: number) {
  if (y < 0) {
    return 0;
  }

  if (y > 1) {
    return 1;
  }

  return y;
}

const MAX_ERROR = 0.005;

/**
 * split range into segments to minimize error when approximate function by linear intervals
 *
 * @param initialPoints - array of initial points
 * @param fx - function f(x), we expect that f(x) has same sign of first derivative on each interval
 * @param dfx - derivative of f(x)
 */
export function segmentation(
  initialPoints: ILinePoint[],
  fx: (x: number) => number,
  dfx: (x: number) => number
): IDiscretePoint[] {
  const points: ILinePoint[] = [...initialPoints].sort(pointsCompare);

  const rangesStack: [ILinePoint, ILinePoint][] = [];

  for (let i = 0; i < points.length - 1; i++) {
    rangesStack.push([points[i], points[i + 1]]);
  }

  while (rangesStack.length) {
    const [p0, p1] = rangesStack.pop()!;
    const delta = p1.y - p0.y;
    const deltaApprox = dfx(p0.x) * (p1.x - p0.x);

    if (Math.abs(deltaApprox - delta) > MAX_ERROR) {
      const x = (p0.x + p1.x) / 2;
      const middle: ILinePoint = {
        id: uuid(),
        x,
        y: fx(x),
      };

      rangesStack.push([p0, middle]);
      rangesStack.push([middle, p1]);
      points.push(middle);
    }
  }

  return points.sort(pointsCompare);
}
