import { DeleteOutlined } from '@ant-design/icons';
import { classNames } from '@discngine/moosa-common';
import { HistogramView } from '@discngine/moosa-histogram';
import {
  DesirabilityFunctionType,
  FieldType,
  IColumnMetaInfo,
  IDesirabilityFunction,
  IScoringFuncProperty,
  SCORING_FUNCTIONS_RULES,
  getDefaultScoringFunction,
} from '@discngine/moosa-models';
import { Button, Empty, Input, List, Modal, Popconfirm } from 'antd';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState, UIEvent } from 'react';

import { getIcon } from '../../helpers';
import { InfinityListProps } from '../../types';

import styles from './DesirabilityFunctionTemplatesListDialog.module.less';

const OFFSET = 50;

interface DesirabilityFunctionTemplatesListDialogProps {
  metadata: IColumnMetaInfo;
  visible: boolean;
  infinityListProps: InfinityListProps;
  onSearch: (query: string) => void;
  onCancel: () => void;
  onDelete: (id: string) => Promise<void>;
  onSelect: (desirability: IDesirabilityFunction) => void;
}

export const DesirabilityFunctionTemplatesListDialog = ({
  metadata,
  visible,
  infinityListProps,
  onSearch,
  onCancel,
  onDelete,
  onSelect,
}: DesirabilityFunctionTemplatesListDialogProps) => {
  const [desirability, setDesirability] = useState<IDesirabilityFunction>();
  const [search, setSearch] = useState<string>('');

  const debouncedOnSearch = useMemo(() => {
    return debounce((query: string) => onSearch(query), 300);
  }, [onSearch]);

  // This needs to reload the data (e.g., otherwise, after changing a discrete function
  // to a linear one, the 'hasMore' flag remains in the previous state)
  useEffect(() => {
    if (visible) {
      infinityListProps.revalidate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    debouncedOnSearch(search);
  }, [debouncedOnSearch, search]);

  const {
    data: customFunctions,
    loadNext,
    isLoading,
    hasMore,
    revalidate,
  } = infinityListProps;

  const onLoadMore = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      const { target } = event;
      const targetElement = target as HTMLDivElement;

      const isScrolled =
        targetElement.scrollTop + targetElement.offsetHeight >=
        targetElement.scrollHeight - OFFSET;

      if (isScrolled && hasMore && !isLoading) {
        loadNext();
      }
    },
    [hasMore, isLoading, loadNext]
  );

  const handleDelete = useCallback(
    async (id: string) => {
      await onDelete(id);
      revalidate();
    },
    [onDelete, revalidate]
  );

  const currentExistingFunctions = useMemo(() => {
    if (metadata.type === FieldType.String && metadata.isDiscreteColumn) {
      return customFunctions.filter((func) => func.isDiscreteStringFunction);
    } else if (metadata.type === FieldType.Number && metadata.isDiscreteColumn) {
      return customFunctions.filter((func) => !func.isDiscreteStringFunction);
    } else {
      return customFunctions.filter(
        (func) => func.type !== DesirabilityFunctionType.discrete
      );
    }
  }, [customFunctions, metadata.isDiscreteColumn, metadata.type]);

  const iScoringFuncProp: IScoringFuncProperty | null = useMemo(() => {
    if (!desirability) return null;

    const props = getDefaultScoringFunction(metadata);

    props.type = desirability.type;
    props.name = desirability.name;
    props.functionParams[props.type] = SCORING_FUNCTIONS_RULES[
      props.type
    ].fromServerTemplate(desirability.points, metadata) as any;
    props.isDiscreteStringFunction = desirability.isDiscreteStringFunction;

    return props;
  }, [desirability, metadata]);

  const onModalClose = useCallback(() => {
    onCancel();
    setSearch('');
  }, [onCancel]);

  useEffect(() => {
    if (!desirability) return;

    if (!customFunctions.some(({ _id }) => _id === desirability._id)) {
      setDesirability(undefined);
    }
  }, [customFunctions, desirability]);

  return (
    <Modal
      centered
      className={styles.modal}
      destroyOnClose
      okButtonProps={{ disabled: !desirability }}
      okText="Select"
      open={visible}
      title="Custom desirability functions"
      width={600}
      onCancel={onModalClose}
      onOk={() => {
        if (!desirability) return;

        onSelect(desirability);
        onModalClose();
      }}
    >
      <div className={styles.wrapper}>
        <div className={styles.preview}>
          {!iScoringFuncProp && (
            <Empty
              description="Select the function below to see a preview"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}

          {iScoringFuncProp && (
            <>
              <HistogramView
                desirability={iScoringFuncProp}
                height={175}
                metadata={metadata}
                width={300}
                onAddFunctionPoint={() => {}}
                onMoveFinish={() => {}}
                onRemoveFunctionPoint={() => {}}
                onUpdateFunctionPoint={() => {}}
              />
              <b>{iScoringFuncProp.name}</b>
            </>
          )}
        </div>

        <Input
          placeholder="Enter function name..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />

        <div className={styles.listWrapper} onScroll={onLoadMore}>
          <List
            className={styles.list}
            dataSource={currentExistingFunctions}
            renderItem={(item) => (
              <List.Item
                key={item._id}
                actions={[
                  <Popconfirm
                    title="Are you sure to delete this function?"
                    onConfirm={() => handleDelete(item._id)}
                  >
                    <Button danger icon={<DeleteOutlined />} size="small" type="text" />
                  </Popconfirm>,
                ]}
                className={classNames(styles.listItem, {
                  [styles.selected]: desirability?._id === item._id,
                })}
                onClick={() => setDesirability(item)}
              >
                <span className={styles.listItemIcon}>{getIcon(item.type)}</span>
                {item.name}
              </List.Item>
            )}
            rowKey="_id"
          />
        </div>
      </div>
    </Modal>
  );
};
