import { DTArrowId, DTNodeType, PortGroupingType } from '@discngine/moosa-models';
import * as go from 'gojs';

import {
  DTNodeOutputPortType,
  DiagramArrowData,
  DiagramData,
  DiagramNodeData,
} from '../../../types';
import { ARROW_LINE, HIGHLIGHTED, HIGHLIGHTED_LIGHT } from '../colors';

const getArrowColor = (_: any, object: go.GraphObject) => {
  const arrowId = object.part?.key as DTArrowId | undefined;
  const arrowData = object.part?.data as DiagramArrowData | undefined;
  const modelData = object.diagram?.model.modelData as DiagramData | undefined;

  if (!arrowId || arrowData?.group || !modelData) return ARROW_LINE;

  if (!modelData.isHighlighted) return ARROW_LINE;

  return modelData.highlightedPort?.type === 'arrowPort' &&
    modelData.highlightedPort.arrowId === arrowId
    ? HIGHLIGHTED
    : HIGHLIGHTED_LIGHT;
};

export const arrowTemplate = new go.Link({
  corner: 30,
  relinkableFrom: true,
  relinkableTo: true,
  routing: go.Link.AvoidsNodes,
  curve: go.Link.JumpOver,
  fromShortLength: -12,
  fromEndSegmentLength: 5,
  toEndSegmentLength: 5,
  doubleClick: (_, object) => {
    if (!object.part) return;

    const arrowData = object.part.data as DiagramArrowData;
    const model = object.diagram?.model as go.GraphLinksModel;
    const parentNode = model.nodeDataArray.find((item) => item.key === arrowData.from) as
      | DiagramNodeData
      | undefined;

    if (
      !parentNode ||
      !arrowData.fromPort ||
      parentNode.category !== DTNodeType.Property
    ) {
      return;
    }

    let ports: DTNodeOutputPortType[];

    switch (parentNode.portGroupingType) {
      case PortGroupingType.TrueMissing:
      case PortGroupingType.FalseMissing:
      case PortGroupingType.HideMissing:
        ports = ['no', 'yes'];
        break;
      case PortGroupingType.Regular:
      case null:
        ports = ['no', 'missingValues', 'yes'];
        break;
      default:
        throw new Error('Unexpected port grouping type');
    }

    const newPort = ports[(ports.indexOf(arrowData.fromPort) + 1) % ports.length];

    const hasDuplicateArrow = model.linkDataArray.some((link) => {
      const data = link as DiagramArrowData;

      return (
        data.from === arrowData.from &&
        data.fromPort === newPort &&
        data.to === arrowData.to &&
        data.toPort === arrowData.toPort &&
        data.key !== arrowData.key
      );
    });

    model.commit((model) => {
      const _model = model as go.GraphLinksModel;

      if (hasDuplicateArrow) {
        _model.removeLinkData(arrowData);
      } else {
        _model.setFromPortIdForLinkData(arrowData, newPort);
      }

      object.diagram?.layout.invalidateLayout();
    }, 'relink arrow');
  },
})
  .add(
    new go.Shape({
      stroke: ARROW_LINE,
      strokeWidth: 1.5,
    })
      .bind(new go.Binding('stroke', 'highlightedPort', getArrowColor).ofModel())
      .bind(new go.Binding('stroke', 'isHighlighted', getArrowColor).ofModel())
      .bind(
        new go.Binding(
          'strokeWidth',
          'isHighlighted',
          (isHighlighted: DiagramData['isHighlighted'], object: go.GraphObject) => {
            const arrowData = object.part?.data as DiagramArrowData | undefined;

            return isHighlighted && !arrowData?.group ? 15 : 1.5;
          }
        ).ofModel()
      )
  )
  .add(
    new go.Shape({
      stroke: ARROW_LINE,
      scale: 1.5,
      toArrow: 'Feather',
    })
  );
