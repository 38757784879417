import { message } from 'antd';
import React, { useCallback, useEffect } from 'react';

import { useInfiniteListDataset } from '../../../store/datasetSlice';

import styles from './DatasetList.module.less';
import DatasetTable from './DatasetTable/DatasetTable';

interface Props {
  query: ReturnType<typeof useInfiniteListDataset>;
  uploadedItem: string | null;
  onDelete: (id: string) => Promise<void>;
  onUndelete: (id: string) => void;
  onClick: (id: string) => void;
}

const DatasetList: React.FC<Props> = ({
  query,
  uploadedItem,
  onUndelete,
  onClick,
  onDelete,
}) => {
  const { isLoading, loadNext, data, hasMore } = query;

  const onLoadMore = useCallback(() => {
    if (hasMore && !isLoading) {
      loadNext();
    }
  }, [hasMore, isLoading, loadNext]);

  const dataWithoutUploadedItem = data.filter((x) => x._id !== uploadedItem);

  useEffect(() => {
    if (!hasMore) {
      message.success(
        <span data-testid="DATASETS_TABLE_END">All datasets are loaded</span>
      );
    }
  }, [hasMore]);

  return (
    <div className={styles.root}>
      <DatasetTable
        className={styles.tableBlock}
        data={dataWithoutUploadedItem}
        data-testid="DATASETS_TABLE"
        loading={isLoading}
        openDataset={onClick}
        onLoadNext={onLoadMore}
        onRowDelete={onDelete}
        onRowRestore={onUndelete}
      />
    </div>
  );
};

export default DatasetList;
