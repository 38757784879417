import {
  EllipsisOutlined,
  SaveOutlined,
  FileOutlined,
  EditOutlined,
  CopyOutlined,
  UndoOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { classNames } from '@discngine/moosa-common';
import { DecisionTreeId, IDecisionTree, IDecisionTreeNew } from '@discngine/moosa-models';
import { Button, Dropdown, Select, Tooltip, message, Spin } from 'antd';
import React, { UIEvent, useCallback, useMemo, useState } from 'react';

import styles from './DecisionTreeSelector.module.less';
import { SaveDialog } from './SaveDialog/SaveDialog';
import { SaveMode } from './SaveDialog/types';

interface LabelledValue {
  key: string;
  label: string;
  value: string;
}

interface IDecisionTreeSelectorProps {
  decisionTree: IDecisionTreeNew;
  decisionTrees: IDecisionTree[];
  decisionTreeVersions: IDecisionTree[];
  canSave: boolean;
  isLoading: boolean;

  onCreate: () => void;
  onSave: (decisionTree: IDecisionTreeNew) => Promise<void>;
  onReset: (decisionTreeId?: DecisionTreeId) => void;
  onRename: (decisionTree: IDecisionTreeNew) => Promise<void>;
  onSelect: (decisionTreeId: DecisionTreeId) => void;
  onDelete: (decisionTreeId: DecisionTreeId) => void;
  onLoadMore: () => void;
  onSearchChange: (value: string) => void;
  search?: string | null;
}

export function DecisionTreeSelector({
  decisionTree,
  decisionTrees,
  decisionTreeVersions,
  canSave,
  onCreate,
  onSave,
  onReset,
  onRename,
  onSelect,
  onDelete,
  onLoadMore,
  isLoading,
  search,
  onSearchChange,
}: IDecisionTreeSelectorProps) {
  const [actionsVisible, setActionsVisible] = useState<boolean>(false);
  const [saveMode, setSaveMode] = useState<SaveMode | null>(null);

  const loadMore = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      const { target } = event;
      const targetElement = target as HTMLDivElement;

      const isScrolled =
        targetElement.scrollTop + targetElement.offsetHeight ===
        targetElement.scrollHeight;

      if (isScrolled && !isLoading) {
        onLoadMore();
      }
    },
    [isLoading, onLoadMore]
  );

  const handleSave = useCallback(
    async (decisionTree: IDecisionTreeNew): Promise<void> => {
      await onSave(decisionTree);
      message.success('DT successfully saved!');
      setSaveMode(null);
    },
    [onSave]
  );

  const handleRename = useCallback(
    async (name: string): Promise<void> => {
      const parentTree =
        decisionTreeVersions.length === 0 || !decisionTree.parentId
          ? decisionTree
          : decisionTreeVersions.find(({ parentId }) => !parentId);

      if (!parentTree) return;

      await onRename({ ...parentTree, name });

      message.success('DT successfully renamed!');
      setSaveMode(null);
    },
    [decisionTree, decisionTreeVersions, onRename]
  );

  const changeSaveMode = useCallback(
    async (mode: SaveMode) => {
      if (decisionTree._id && mode === 'SAVE') {
        handleSave(decisionTree);
      } else {
        setSaveMode(mode);
      }
    },
    [decisionTree, handleSave]
  );

  const options = useMemo(() => {
    return decisionTrees.map<LabelledValue>((item) => ({
      label: item.name,
      key: item._id!,
      value: item.parentId ?? item._id!,
    }));
  }, [decisionTrees]);

  const value = useMemo<LabelledValue | undefined>(() => {
    if (!decisionTree._id) {
      return undefined;
    }

    return {
      value: decisionTree.parentId ?? decisionTree._id,
      key: decisionTree._id,
      label:
        decisionTreeVersions.find(({ parentId }) => !parentId)?.name ?? decisionTree.name,
    };
  }, [decisionTree, decisionTreeVersions]);

  const onChange = useCallback((item: LabelledValue) => onSelect(item.value), [onSelect]);

  return (
    <>
      <div className={styles.wrapper}>
        <Select
          className={styles.select}
          filterOption={false}
          labelInValue
          loading={isLoading}
          notFoundContent={isLoading ? <Spin size="small" /> : null}
          options={options}
          placeholder="Select decision tree"
          searchValue={search ?? undefined}
          showSearch={true}
          value={value}
          onChange={onChange}
          onPopupScroll={loadMore}
          onSearch={onSearchChange}
        />

        <Select
          className={classNames(styles.versionSelect, {
            [styles.versionNameInitial]: decisionTree.versionName === '',
          })}
          optionFilterProp="label"
          placeholder="Select version"
          showSearch={true}
          value={decisionTree.versionName || 'initial'}
          onChange={onSelect}
        >
          {decisionTreeVersions.map((version) => (
            <Select.Option
              key={version._id}
              className={styles.option}
              label={version.versionName || 'initial'}
              value={version._id}
            >
              <span
                className={classNames(styles.versionName, {
                  [styles.versionNameInitial]: version.versionName === '',
                })}
              >
                {version.versionName || 'initial'}
              </span>
              {version.description && (
                <Tooltip
                  className={styles.tooltip}
                  placement="right"
                  title={version.description}
                >
                  <InfoCircleOutlined className={styles.infoCircleIcon} />
                </Tooltip>
              )}
            </Select.Option>
          ))}
        </Select>

        <Button
          disabled={!canSave}
          icon={<SaveOutlined />}
          shape="circle"
          type="text"
          onClick={() => changeSaveMode('SAVE')}
        />
        {decisionTree._id && (
          <>
            <Dropdown
              menu={{
                className: styles.menu,
                selectable: false,
                onClick: () => setActionsVisible(false),
                items: [
                  {
                    key: 'create-new',
                    icon: <FileOutlined />,
                    label: 'Create new',
                    onClick: onCreate,
                  },
                  {
                    key: 'RENAME',
                    disabled: !decisionTree._id || canSave,
                    icon: <EditOutlined />,
                    label: canSave ? (
                      <Tooltip title="It is necessary to save all changes before renaming">
                        Rename
                      </Tooltip>
                    ) : (
                      'Rename'
                    ),
                    onClick: () => changeSaveMode('RENAME'),
                  },
                  {
                    key: 'SAVE_AS_VERSION',
                    icon: <CopyOutlined />,
                    label: 'Save as version',
                    onClick: () => changeSaveMode('SAVE_AS_VERSION'),
                  },
                  {
                    key: 'SAVE_AS_COPY',
                    icon: <CopyOutlined />,
                    label: 'Save as copy',
                    onClick: () => changeSaveMode('SAVE_AS_COPY'),
                  },
                  {
                    key: 'reset',
                    icon: <UndoOutlined />,
                    label: 'Reset all changes',
                    disabled: !canSave,
                    onClick: () => onReset(decisionTree._id),
                  },
                  {
                    key: 'delete',
                    className: styles.delete,
                    icon: <DeleteOutlined className={styles.deleteIcon} />,
                    label: 'Delete',
                    onClick: () => decisionTree._id && onDelete(decisionTree._id),
                  },
                ],
              }}
              open={actionsVisible}
              onOpenChange={setActionsVisible}
            >
              <Button
                icon={<EllipsisOutlined className={styles.templateActions} />}
                shape="circle"
                type="text"
              />
            </Dropdown>
          </>
        )}
      </div>

      {saveMode && (
        <SaveDialog
          decisionTree={decisionTree}
          saveMode={saveMode}
          visible={true}
          onCancel={() => setSaveMode(null)}
          onRename={handleRename}
          onSave={handleSave}
        />
      )}
    </>
  );
}
