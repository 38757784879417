import { Bar } from '@visx/shape';
import React, { memo, SVGProps } from 'react';

import { ScaleLinear } from '../Histogram';

const PADDING = 1;
const SINGLE_BAR_WIDTH = 8;

interface Props extends SVGProps<SVGRectElement> {
  xScale: ScaleLinear;
  yScale: ScaleLinear;
  yMax: number;
  bar: { x0: number; x: number; y0: number; y: number };
}

export const HistogramBar = memo((props: Props) => {
  const { xScale, yScale, bar, yMax, ...rest } = props;

  let barX = xScale(bar.x0);
  let barWidth = Math.abs(xScale(bar.x) - barX) - PADDING;

  if (barWidth < 0) {
    barWidth = SINGLE_BAR_WIDTH;
    barX -= SINGLE_BAR_WIDTH / 2;
  }

  const barHeight = Math.abs(yMax - yScale(bar.y));
  const barY = yMax - barHeight;

  return (
    <Bar
      fill="rgb(18, 147, 154)"
      height={barHeight}
      width={barWidth}
      x={barX}
      y={barY}
      {...rest}
    />
  );
});
