import {
  selectScoringColumnOrder,
  selectScoringFunction,
} from '@discngine/moosa-store/scoringTemplate';
import { selectIsCompareMode, setCompareMode } from '@discngine/moosa-store/tableData';
import { selectTableColumnIds } from '@discngine/moosa-store/tableInfo';
import { Switch } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DataViewMode } from '../MultiDimensionsAnalysis';

import styles from './CompareModeSwitch.module.less';

export const CompareModeSwitch: React.FC<{ dataViewMode: DataViewMode }> = ({
  dataViewMode,
}) => {
  const isCompareMode = useSelector(selectIsCompareMode);
  const dispatch = useDispatch();
  const onCompareModeChange = useCallback(
    (value: boolean) => dispatch(setCompareMode(value)),
    [dispatch]
  );

  const order = useSelector(selectScoringColumnOrder);
  const columns = useSelector(selectTableColumnIds);
  const scoringFunction = useSelector(selectScoringFunction);

  const [disabled, disabledReason] = useMemo(() => {
    if (dataViewMode !== DataViewMode.TABLE) {
      return [true, 'Available only in Table mode'];
    }

    const items = order
      .filter((col) => columns.includes(col))
      .filter((col) => scoringFunction[col].isInUse);

    if (items.length < 3) {
      return [
        true,
        'Radar plot cannot be built cause there are less than 3 parameters in the scoring function',
      ];
    }

    return [false];
  }, [dataViewMode, order, columns, scoringFunction]);

  useEffect(() => {
    if (disabled) {
      dispatch(setCompareMode(false));
    }
  }, [dispatch, disabled]);

  return (
    <div className={styles.compareSwitch}>
      <Switch
        checked={isCompareMode}
        className={styles.controlElement}
        disabled={disabled}
        size="small"
        title={disabled ? disabledReason : undefined}
        onChange={onCompareModeChange}
      />
      Compare Substances
    </div>
  );
};
