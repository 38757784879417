import { IDecisionTreeNew, useMoosaDataContext } from '@discngine/moosa-models';
import { Alert, Col, Input, Modal, Row } from 'antd';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';

import { TEXTINGS } from './constants';
import styles from './SaveDialog.module.less';
import { SaveDialogValue, SaveMode, ValidationError } from './types';
import { treeFromSaveDialogValue, treeToSaveDialogValue } from './utils';

interface ISaveDialogProps {
  decisionTree: IDecisionTreeNew;
  visible: boolean;
  saveMode: SaveMode;
  onSave: (decisionTree: IDecisionTreeNew) => Promise<void>;
  onRename: (name: string) => Promise<void>;
  onCancel: () => void;
}

export const SaveDialog = ({
  decisionTree,
  visible,
  saveMode,
  onSave,
  onRename,
  onCancel,
}: ISaveDialogProps) => {
  const initSaveValue = () => {
    return treeToSaveDialogValue(decisionTree, saveMode);
  };
  const [data, setData] = useState<SaveDialogValue>(initSaveValue);
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
  const [isValidating, setIsValidating] = useState(false);
  const isRename = saveMode === 'RENAME';
  const isSaveAsCopy = saveMode === 'SAVE_AS_COPY';
  const displayCommentAndVersionFields = !isRename && !isSaveAsCopy && !!data.id;
  const dataService = useMoosaDataContext();
  const debouncedValidateErrors = debounce(
    async (name: string, version: string | undefined) => {
      const errors: ValidationError[] = [];

      setIsValidating(true);

      if (name.trim() === '') {
        errors.push({
          message: 'A decision tree name is required',
          type: 'error',
        });
      }

      switch (saveMode) {
        case 'SAVE':
        case 'RENAME':
        case 'SAVE_AS_COPY': {
          try {
            const response: { success: boolean } =
              await dataService.checkDecisionTreeName({
                name: name.trim(),
              });

            if (!response.success) {
              errors.push({
                message: 'A tree with the same name already exists',
                type: 'error',
              });
            }
          } catch (error) {
            console.error(error);
            errors.push({
              message: 'Unknown error',
              type: 'error',
            });
          }
          break;
        }
        case 'SAVE_AS_VERSION': {
          if (version === undefined || version.trim() === '') {
            errors.push({
              message: 'A decision tree version is required',
              type: 'error',
            });
            break;
          }
          try {
            const response: { success: boolean } =
              await dataService.checkDecisionTreeName({
                name: name.trim(),
                versionName: version.trim(),
                parentId: data.parentId || data.id,
              });

            if (!response.success) {
              errors.push({
                message: 'A tree version with the same name already exists',
                type: 'error',
              });
            }
          } catch (error) {
            console.error(error);
            errors.push({
              message: 'Unknown error',
              type: 'error',
            });
          }
          break;
        }
      }
      setValidationErrors(errors);
      setIsValidating(false);
    },
    300
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  const validateErrors = useCallback(debouncedValidateErrors, []);

  useEffect(() => {
    validateErrors(data.name, data.version);
  }, [validateErrors, data.name, data.version]);

  const { okText, title } = TEXTINGS[saveMode];
  const isValid = validationErrors.length === 0 && !isValidating;

  const onSubmit = useCallback(async () => {
    if (!isValid) return;

    const saveData: SaveDialogValue = {
      name: data.name,
    };

    switch (saveMode) {
      case 'SAVE':
        saveData.id = data.id;
        break;
      case 'RENAME':
        saveData.id = data.parentId || data.id;
        break;
      case 'SAVE_AS_VERSION':
        saveData.parentId = data.parentId || data.id;
        saveData.version = data.version;
        saveData.comment = data.comment;
        break;
    }
    try {
      if (saveMode === 'RENAME') {
        await onRename(data.name);
      } else {
        await onSave(treeFromSaveDialogValue(decisionTree, saveData));
      }
    } catch {
      validateErrors(data.name, data.version);
    }
  }, [validateErrors, isValid, data, saveMode, onSave, decisionTree]);

  return (
    <Modal
      centered
      destroyOnClose={true}
      okButtonProps={{ disabled: !isValid }}
      okText={okText}
      open={visible}
      title={title}
      width={480}
      onCancel={onCancel}
      onOk={onSubmit}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit();
        }}
      >
        <Row className={styles.row} gutter={[8, 8]}>
          <Col span={!displayCommentAndVersionFields ? 24 : 16}>
            <label htmlFor="name">Name</label>
            <Input
              autoComplete="off"
              disabled={saveMode === 'SAVE_AS_VERSION'}
              id="name"
              required={true}
              value={data.name}
              onChange={(event) =>
                setData((data) => ({ ...data, name: event.target.value }))
              }
            />
          </Col>
          {displayCommentAndVersionFields && (
            <Col span={8}>
              <label htmlFor="version">Version</label>
              <Input
                autoComplete="off"
                id="version"
                value={data.version}
                onChange={(event) =>
                  setData((data) => ({ ...data, version: event.target.value }))
                }
              />
            </Col>
          )}
        </Row>
        {displayCommentAndVersionFields && (
          <Row className={styles.row} gutter={8}>
            <Col span={24}>
              <label htmlFor="comment">Comment</label>
              <Input.TextArea
                autoComplete="off"
                id="comment"
                rows={2}
                value={data.comment}
                onChange={(event) =>
                  setData((data) => ({ ...data, comment: event.target.value }))
                }
              />
            </Col>
          </Row>
        )}

        {!isValid && (
          <Row gutter={8}>
            <Col span={24}>
              {validationErrors.map((error, index) => (
                <Alert key={index} message={error.message} type={error.type} />
              ))}
            </Col>
          </Row>
        )}
      </form>
    </Modal>
  );
};
