import { DatasetRowId, DecisionTreeResult } from '@discngine/moosa-models';
import * as go from 'gojs';

import '../extensions/RoundedRectangles';
import '../extensions/Buttons';

import {
  HISTOGRAM_HEIGHT,
  HISTOGRAM_WIDTH,
  NODE_CONDITION_PRECISION,
} from '../../../constants';
import {
  CommonNodeTemplateProps,
  DiagramData,
  DiagramHistogramView,
  DiagramNodeData,
  NodeConditionMismatchLevel,
} from '../../../types';
import { stringifyCondition } from '../../../utils';
import { NODE_CONDITION_TEXT } from '../colors';

import { getNodeTypeMismatchMessage } from './common';
import { propertyLikeNodeFrame } from './propertyLikeCommon';

const propertyNodeCondition = (options?: Partial<go.Panel>) =>
  new go.Panel('Horizontal', {
    margin: new go.Margin(20, 0, 12, 0),
    maxSize: new go.Size(285, 140),
    ...options,
  }).add(
    new go.TextBlock({
      stroke: NODE_CONDITION_TEXT,
      font: '400 14px Roboto, sans-serif',
      margin: new go.Margin(0, 8, 0, 0),
    })
      .bind('text', 'condition', (condition: DiagramNodeData['condition']) => {
        return condition ? stringifyCondition(condition, NODE_CONDITION_PRECISION) : '';
      })
      .bind('visible', 'condition', Boolean)
  );

const histogramPanel = (
  getHistogram?: (nodeData: DiagramNodeData, rowIds: Set<DatasetRowId>) => string | null
) =>
  new go.Picture({ width: HISTOGRAM_WIDTH, height: HISTOGRAM_HEIGHT })
    .bind(
      new go.Binding(
        'source',
        'result',
        (result: DecisionTreeResult, object: go.GraphObject) => {
          if (!object.part) return null;

          const node = object.part.data as DiagramNodeData;
          const modelData = object.diagram?.model.modelData as DiagramData;

          return (
            getHistogram?.(
              node,
              modelData.histogramView === DiagramHistogramView.All
                ? result.datasetRowIds
                : result.nodes[node.key].inputDatasetRowIds
            ) || ''
          );
        }
      ).ofModel()
    )
    .bind('visible', 'mismatch', (mismatch: DiagramNodeData['mismatch']) => {
      return mismatch?.level !== NodeConditionMismatchLevel.Critical;
    })
    .bind(
      new go.Binding(
        'visible',
        'histogramView',
        (histogramView: DiagramData['histogramView']) => {
          return histogramView !== DiagramHistogramView.Hide;
        }
      ).ofModel()
    );

export interface IPropertyNodeTemplateProps extends CommonNodeTemplateProps {
  getHistogram?: (nodeData: DiagramNodeData, rowIds: Set<DatasetRowId>) => string | null;
  onEditConditionClick?: (data: DiagramNodeData) => void;
  showMenu?: (a: go.GraphObject, b: go.Diagram, c: go.Tool) => void;
  hideMenu?: () => void;
}

export const propertyNodeTemplate = ({
  onEditConditionClick,
  getHistogram,
  onResultsClick,
  showMenu,
  hideMenu,
}: IPropertyNodeTemplateProps) => {
  const bodyPanel = (bodyPanel: go.Panel) =>
    bodyPanel
      .add(getNodeTypeMismatchMessage())
      .add(histogramPanel(getHistogram))
      .add(
        new go.TextBlock('Comparison condition is not specified', {
          margin: new go.Margin(-15, 0, 0, 0),
          font: '600 14px Roboto, sans-serif',
          verticalAlignment: go.Spot.Center,
        }).bind('visible', 'condition', (value) => !Boolean(value))
      )
      .add(propertyNodeCondition());

  return propertyLikeNodeFrame({
    onEditConditionClick,
    onResultsClick,
    showMenu,
    hideMenu,
    bodyPanel,
  });
};
