import { DEFAULT_COLORS } from '@discngine/moosa-common';
import {
  ColumnId,
  DatasetRowId,
  DecisionTreeGroupedResult,
  DecisionTreeGroups,
  DecisionTreeNodeOutputGroups,
  DecisionTreeResult,
  DTGroupOption,
  IDatasetPropertyValue,
  isAndNodeResult,
  isOrNodeResult,
  isPropertyNodeResult,
} from '@discngine/moosa-models';

import { HISTOGRAM_MISSING } from '../lib/go/colors';

import { CellGroupGetter } from './types';

/**
 * Groups dataset rows based on a specified group column ID.
 *
 * @param getGroup - a function to get a group name for a cell
 * @param datasetRowIds - The set of dataset rows to group.
 * @param groupColumnId - The ID of the column used for grouping.
 * @returns The grouped dataset rows.
 */
export const groupDatasetRowIds = (
  getGroup: CellGroupGetter,
  datasetRowIds: Set<DatasetRowId>,
  groupColumnId: ColumnId
): DecisionTreeNodeOutputGroups => {
  const groups: DecisionTreeNodeOutputGroups = new Map();

  datasetRowIds.forEach((id) => {
    const group = getGroup(id, groupColumnId);

    if (group === undefined) return;

    groups.set(group, (groups.get(group) || new Set()).add(id));
  });

  return groups;
};

/**
 * a column with all rows from the dataset is expected
 */
export const groupDatasetColumn = (
  column: [DatasetRowId, IDatasetPropertyValue | undefined][]
): DecisionTreeNodeOutputGroups => {
  const groups: DecisionTreeNodeOutputGroups = new Map();

  column.forEach(([id, group]) => {
    const sanitized = group === null ? null : String(group);

    if (group === undefined) return;

    groups.set(sanitized, (groups.get(sanitized) || new Set()).add(id));
  });

  return groups;
};

export const getGroupsForColumn = (
  column: [DatasetRowId, IDatasetPropertyValue | undefined][]
): DecisionTreeGroups => {
  const options: DTGroupOption[] = [];

  const groupedDataset = groupDatasetColumn(column);

  for (let name of groupedDataset.keys()) {
    if (name === null) continue;

    options.push({
      key: String(options.length),
      name,
      color: DEFAULT_COLORS[options.length % DEFAULT_COLORS.length],
    });
  }

  options.push({
    key: String(options.length),
    name: null,
    color: HISTOGRAM_MISSING,
  });

  return {
    options,
    groups: groupedDataset,
  };
};

/**
 * Groups the decision tree result based on the specified grouping rule.
 *
 * @param makeRowIdsGrouping - the grouping rule
 * @param result - The decision tree result to group.
 * @returns The grouped decision tree result.
 */
export const groupDecisionTreeResultByRule = (
  makeRowIdsGrouping: (rowIds: Set<DatasetRowId>) => DecisionTreeNodeOutputGroups,
  result: DecisionTreeResult
): DecisionTreeGroupedResult => {
  const groups: DecisionTreeGroupedResult = {};

  const nodeIds = Object.keys(result.nodes);

  nodeIds.forEach((nodeId) => {
    const nodeResult = result.nodes[nodeId];

    const inputGroups = makeRowIdsGrouping(nodeResult.inputDatasetRowIds);

    if (isPropertyNodeResult(nodeResult)) {
      groups[nodeId] = {
        id: nodeResult.id,
        type: nodeResult.type,
        inputGroups,
        outputGroups: {
          yes: makeRowIdsGrouping(nodeResult.outputDatasetRowIds.yes),
          no: makeRowIdsGrouping(nodeResult.outputDatasetRowIds.no),
          missingValues: makeRowIdsGrouping(nodeResult.outputDatasetRowIds.missingValues),
        },
      };
    } else if (isAndNodeResult(nodeResult) || isOrNodeResult(nodeResult)) {
      groups[nodeId] = {
        id: nodeResult.id,
        type: nodeResult.type,
        inputGroups,
        outputGroups: {
          combine: makeRowIdsGrouping(nodeResult.inputDatasetRowIds),
        },
      };
    }
  });

  return groups;
};

/**
 * Groups the decision tree result based on the specified group column ID.
 *
 * @param getGroup - a function that returns a group name for a dataset cell.
 * @param result - The decision tree result to group.
 * @param groupColumnId - The ID of the column used for grouping.
 * @returns The grouped decision tree result.
 */
export const groupDecisionTreeResult = (
  getGroup: CellGroupGetter,
  result: DecisionTreeResult,
  groupColumnId: ColumnId
): DecisionTreeGroupedResult => {
  const makeRowIdsGrouping = (rowIds: Set<DatasetRowId>) =>
    groupDatasetRowIds(getGroup, rowIds, groupColumnId);

  return groupDecisionTreeResultByRule(makeRowIdsGrouping, result);
};
