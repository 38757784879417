import { DTNodeType, IDecisionTree } from '@discngine/moosa-models';
import { DiagramData, DiagramHistogramView, DiagramState } from 'types';

export const CANVAS_LOCATION = 'Canvas';
export const DND_ITEM_TYPE = 'DTNodeItem';
export const TIMEZONE_OFFSET_MS = new Date().getTimezoneOffset() * 60 * 1000;

export const NODES_GAP = 40;
export const NODE_WIDTH = 325;

export const HISTOGRAM_WIDTH = 294;
export const HISTOGRAM_HEIGHT = 175;

export const DATE_HISTOGRAM_RESOLUTION = 86_400_000; // 24h in millis

/**
 * The array of compare operators available for decision tree nodes.
 */
export const COMPARE_OPERATORS = [
  { type: DTNodeType.And, name: 'Intersect' },
  { type: DTNodeType.Or, name: 'Union' },
  { type: DTNodeType.ALN, name: 'At least N' },
  { type: DTNodeType.AMX, name: 'At most X' },
  { type: DTNodeType.Chart, name: 'Chart' },
] as const;

/**
 * The default IDecisionTree object.
 * It represents a decision tree data stored in a database.
 */
export const DEFAULT_DECISION_TREE: Omit<IDecisionTree, '_id'> = {
  name: '',
  data: {
    layout: 'Auto',
    nodes: [],
    arrows: [],
  },
};

/**
 * The default DiagramState object.
 * It represents the data of a decision tree drawn on the canvas.
 */
export const DEFAULT_DIAGRAM_STATE: DiagramState = {
  id: '',
  name: '',
  layout: 'Auto',
  nodes: [],
  arrows: [],
  editableNodeId: null,
  skipsDiagramUpdate: false,
};

/**
 * The default view of histograms in decision tree nodes
 */
export const DEFAULT_HISTOGRAM_VIEW: DiagramHistogramView = DiagramHistogramView.Incoming;

/**
 * The default DiagramData object.
 * It represents local data used only in the diagram as local storage for calculated data.
 */
export const DEFAULT_DIAGRAM_DATA: DiagramData = {
  result: {
    datasetRowIds: new Set(),
    isCyclicGraph: false,
    nodes: {},
  },
  groups: {},
  isHighlighted: false,
  highlightedPort: null,
  highlightedNode: null,
  histogramView: DEFAULT_HISTOGRAM_VIEW,
  selectedNode: null,
  editableNodeId: null,
};

/**
 * The precision value used for string representation of node comparing condition.
 */
export const NODE_CONDITION_PRECISION = 5;
