import go from 'gojs';

/*
 *  Copyright (C) 1998-2023 by Northwoods Software Corporation. All Rights Reserved.
 */

// This file holds the definitions of several useful figures with rounded corners but straight sides:
// "RoundedTopRectangle", "RoundedBottomRectangle", "RoundedLeftRectangle", "RoundedRightRectangle",
// "CapsuleH", and "CapsuleV".
// The basic "RoundedRectangle" (corners on all four sides) is built into the GoJS library --
// you can see its definition in Figures.js.
// Two of these are demonstrated at ../samples/twoHalves.html and ../samples/roundedGroups.html.

go.Shape.defineFigureGenerator('RoundedTopRectangle', function (shape, width, height) {
  // this figure takes one parameter, the size of the corner
  var p1 = 5; // default corner size

  if (shape !== null) {
    var param1 = shape.parameter1;

    if (!isNaN(param1) && param1 >= 0) p1 = param1; // can't be negative or NaN
  }
  p1 = Math.min(p1, width / 3); // limit by width & height
  p1 = Math.min(p1, height);
  var geo = new go.Geometry();

  // a single figure consisting of straight lines and quarter-circle arcs
  geo.add(
    new go.PathFigure(0, p1)
      .add(new go.PathSegment(go.PathSegment.Arc, 180, 90, p1, p1, p1, p1))
      .add(new go.PathSegment(go.PathSegment.Line, width - p1, 0))
      .add(new go.PathSegment(go.PathSegment.Arc, 270, 90, width - p1, p1, p1, p1))
      .add(new go.PathSegment(go.PathSegment.Line, width, height))
      .add(new go.PathSegment(go.PathSegment.Line, 0, height).close())
  );
  // don't intersect with two top corners when used in an "Auto" Panel
  geo.spot1 = new go.Spot(0, 0, 0.3 * p1, 0.3 * p1);
  geo.spot2 = new go.Spot(1, 1, -0.3 * p1, 0);

  return geo;
});

go.Shape.defineFigureGenerator('RoundedBottomRectangle', function (shape, width, height) {
  // this figure takes one parameter, the size of the corner
  var p1 = 5; // default corner size

  if (shape !== null) {
    var param1 = shape.parameter1;

    if (!isNaN(param1) && param1 >= 0) p1 = param1; // can't be negative or NaN
  }
  p1 = Math.min(p1, width / 3); // limit by width & height
  p1 = Math.min(p1, height);
  var geo = new go.Geometry();

  // a single figure consisting of straight lines and quarter-circle arcs
  geo.add(
    new go.PathFigure(0, 0)
      .add(new go.PathSegment(go.PathSegment.Line, width, 0))
      .add(new go.PathSegment(go.PathSegment.Line, width, height - p1))
      .add(new go.PathSegment(go.PathSegment.Arc, 0, 90, width - p1, height - p1, p1, p1))
      .add(new go.PathSegment(go.PathSegment.Line, p1, height))
      .add(
        new go.PathSegment(go.PathSegment.Arc, 90, 90, p1, height - p1, p1, p1).close()
      )
  );
  // don't intersect with two bottom corners when used in an "Auto" Panel
  geo.spot1 = new go.Spot(0, 0, 0.3 * p1, 0);
  geo.spot2 = new go.Spot(1, 1, -0.3 * p1, -0.3 * p1);

  return geo;
});

go.Shape.defineFigureGenerator('RoundedLeftRectangle', function (shape, width, height) {
  // this figure takes one parameter, the size of the corner
  var p1 = 5; // default corner size

  if (shape !== null) {
    var param1 = shape.parameter1;

    if (!isNaN(param1) && param1 >= 0) p1 = param1; // can't be negative or NaN
  }
  p1 = Math.min(p1, width); // limit by width & height
  p1 = Math.min(p1, height / 3);
  var geo = new go.Geometry();

  // a single figure consisting of straight lines and quarter-circle arcs
  geo.add(
    new go.PathFigure(width, 0)
      .add(new go.PathSegment(go.PathSegment.Line, width, height))
      .add(new go.PathSegment(go.PathSegment.Line, p1, height))
      .add(new go.PathSegment(go.PathSegment.Arc, 90, 90, p1, height - p1, p1, p1))
      .add(new go.PathSegment(go.PathSegment.Line, 0, p1))
      .add(new go.PathSegment(go.PathSegment.Arc, 180, 90, p1, p1, p1, p1).close())
  );
  // don't intersect with two top corners when used in an "Auto" Panel
  geo.spot1 = new go.Spot(0, 0, 0.3 * p1, 0.3 * p1);
  geo.spot2 = new go.Spot(1, 1, -0.3 * p1, 0);

  return geo;
});

go.Shape.defineFigureGenerator('RoundedRightRectangle', function (shape, width, height) {
  // this figure takes one parameter, the size of the corner
  var p1 = 5; // default corner size

  if (shape !== null) {
    var param1 = shape.parameter1;

    if (!isNaN(param1) && param1 >= 0) p1 = param1; // can't be negative or NaN
  }
  p1 = Math.min(p1, width); // limit by width & height
  p1 = Math.min(p1, height / 3);
  var geo = new go.Geometry();

  // a single figure consisting of straight lines and quarter-circle arcs
  geo.add(
    new go.PathFigure(0, 0)
      .add(new go.PathSegment(go.PathSegment.Line, width - p1, 0))
      .add(new go.PathSegment(go.PathSegment.Arc, 270, 90, width - p1, p1, p1, p1))
      .add(new go.PathSegment(go.PathSegment.Line, width, height - p1))
      .add(new go.PathSegment(go.PathSegment.Arc, 0, 90, width - p1, height - p1, p1, p1))
      .add(new go.PathSegment(go.PathSegment.Line, 0, height).close())
  );
  // don't intersect with two bottom corners when used in an "Auto" Panel
  geo.spot1 = new go.Spot(0, 0, 0.3 * p1, 0);
  geo.spot2 = new go.Spot(1, 1, -0.3 * p1, -0.3 * p1);

  return geo;
});

// these two figures have rounded ends
go.Shape.defineFigureGenerator('Capsule', function (shape, width, height) {
  var geo = new go.Geometry();

  if (width < height) {
    var fig = new go.PathFigure(width / 2, 0, true);

    fig.add(
      new go.PathSegment(
        go.PathSegment.Bezier,
        width / 2,
        height,
        width,
        0,
        width,
        height
      )
    );
    fig.add(new go.PathSegment(go.PathSegment.Bezier, width / 2, 0, 0, height, 0, 0));
    geo.add(fig);

    return geo;
  } else {
    let fig = new go.PathFigure(height / 2, 0, true);

    geo.add(fig);
    // Outline
    fig.add(new go.PathSegment(go.PathSegment.Line, width - height / 2, 0));
    fig.add(
      new go.PathSegment(
        go.PathSegment.Arc,
        270,
        180,
        width - height / 2,
        height / 2,
        height / 2,
        height / 2
      )
    );
    fig.add(new go.PathSegment(go.PathSegment.Line, width - height / 2, height));
    fig.add(
      new go.PathSegment(
        go.PathSegment.Arc,
        90,
        180,
        height / 2,
        height / 2,
        height / 2,
        height / 2
      )
    );

    return geo;
  }
});
go.Shape.defineFigureGenerator('CapsuleH', 'Capsule'); // synonym

go.Shape.defineFigureGenerator('CapsuleV', function (shape, width, height) {
  var geo = new go.Geometry();

  if (height < width) {
    var fig = new go.PathFigure(0, height / 2, true);

    fig.add(
      new go.PathSegment(
        go.PathSegment.Bezier,
        width,
        height / 2,
        0,
        height,
        width,
        height
      )
    );
    fig.add(new go.PathSegment(go.PathSegment.Bezier, 0, height / 2, width, 0, 0, 0));
    geo.add(fig);

    return geo;
  } else {
    let fig = new go.PathFigure(0, width / 2, true);

    geo.add(fig);
    // Outline
    fig.add(
      new go.PathSegment(
        go.PathSegment.Arc,
        180,
        180,
        width / 2,
        width / 2,
        width / 2,
        width / 2
      )
    );
    fig.add(new go.PathSegment(go.PathSegment.Line, width, height - width / 2));
    fig.add(
      new go.PathSegment(
        go.PathSegment.Arc,
        0,
        180,
        width / 2,
        height - width / 2,
        width / 2,
        width / 2
      )
    );
    fig.add(new go.PathSegment(go.PathSegment.Line, 0, width / 2));

    return geo;
  }
});
