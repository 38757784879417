import { classNames } from '@discngine/moosa-common';
import { DateHistogram, DateHistogramData } from '@discngine/moosa-histogram';
import {
  ColumnId,
  Condition,
  ConditionType,
  DatasetRowId,
  DecisionTreeDateNode,
  DecisionTreeNodeResult,
  DTNodeType,
  FieldType,
  IColumnLabelMap,
  IColumnMetaInfo,
  isDateNodeResult,
  PortGroupingType,
  RangeCondition,
  SimpleCondition,
} from '@discngine/moosa-models';
import { Alert, Radio } from 'antd';
import React, { FC, useCallback, useMemo } from 'react';
import {
  NodeConditionMismatch,
  NodeConditionMismatchLevel,
  NodeConditionMismatchType,
} from 'types';
import { getMismatchDescription, getTypeMismatch } from 'utils';

import { HISTOGRAM_HEIGHT, HISTOGRAM_WIDTH } from '../../../constants';
import {
  NODE_CONDITION_MISMATCH_ERROR,
  NODE_CONDITION_MISMATCH_WARNING,
} from '../../../lib/go/colors';
import { EditorPanelHeader } from '../shared/EditorPanelHeader';
import { OutputsInfo } from '../shared/OutputsInfo';
import { PortGrouping } from '../shared/PortGrouping';
import styles from '../shared/PropertyNodeEditPanel.module.less';
import { ConditionOption } from '../types';
import { conditionTypesToOptions, isCompleted } from '../utils';

import { RangeDateConditionInput } from './RangeDateConditionInput';
import { SimpleDateConditionInput } from './SimpleDateConditionInput';
import {
  getDateNode,
  getDefaultDateConditionSettings,
  useDateNodeSettings,
} from './useDateNodeSettings';

function getStartOfDay(unixTime: number): number {
  const DAY_IN_MS = 1000 * 60 * 60 * 24;

  return DAY_IN_MS * Math.floor(unixTime / DAY_IN_MS);
}

export interface DateNodeEditPanelProps {
  nodeData: DecisionTreeDateNode;
  columns: ColumnId[];
  columnLabelMap?: IColumnLabelMap;
  getMetaData: (columnId: ColumnId) => IColumnMetaInfo | undefined;
  getNodeResult: (node: DecisionTreeDateNode) => DecisionTreeNodeResult | undefined;
  onNodeChange: (node: DecisionTreeDateNode) => void;
  onCancel: () => void;
  onReset: (node: DecisionTreeDateNode) => void;
  getDateHistogramData: (
    node: DecisionTreeDateNode,
    rowIds: Set<DatasetRowId>
  ) => DateHistogramData | null;
}

export const DateNodeEditPanel: FC<DateNodeEditPanelProps> = ({
  nodeData,
  columns,
  columnLabelMap,
  getMetaData,
  getNodeResult,
  getDateHistogramData,
  onNodeChange,
  onCancel,
  onReset,
}) => {
  const { nodeSettings, updateNodeSettings, changedDateNode } = useDateNodeSettings(
    nodeData,
    onNodeChange
  );

  const nodeResult = useMemo(() => {
    if (isCompleted(nodeSettings)) {
      const result = getNodeResult(changedDateNode);

      if (!result) return null;

      return isDateNodeResult(result) ? result : null;
    }
  }, [nodeSettings, getNodeResult, changedDateNode]);

  const dateHistogramData = useMemo(() => {
    const rowIds = nodeResult?.inputDatasetRowIds;

    if (!rowIds) return null;

    return getDateHistogramData(getDateNode(nodeSettings, nodeData), rowIds);
  }, [getDateHistogramData, nodeData, nodeResult?.inputDatasetRowIds, nodeSettings]);

  const metaData = getMetaData(nodeSettings.name);

  const handleConditionTypeChange = useCallback(
    (type: ConditionType) => {
      updateNodeSettings({
        ...nodeSettings,
        condition: (metaData
          ? getDefaultDateConditionSettings(metaData, type, nodeSettings.condition)
          : null) as SimpleCondition | RangeCondition,
      });
    },
    [metaData, nodeSettings, updateNodeSettings]
  );

  const handlePortGroupingTypeChange = useCallback(
    (portGroupingType: PortGroupingType) => {
      updateNodeSettings({
        ...nodeSettings,
        portGroupingType,
      });
    },
    [nodeSettings, updateNodeSettings]
  );

  const handleConditionChange = useCallback(
    (condition?: Condition) => {
      if (condition) {
        if (condition.type === ConditionType.Simple) {
          condition.threshold = getStartOfDay(condition.threshold);
        }

        if (condition.type === ConditionType.Range) {
          condition.min.threshold = getStartOfDay(condition.min.threshold);
          condition.max.threshold = getStartOfDay(condition.max.threshold);
        }
        updateNodeSettings({
          ...nodeSettings,
          condition: { ...condition } as SimpleCondition | RangeCondition,
        });
      }
    },
    [nodeSettings, updateNodeSettings]
  );

  const conditionTypeOptions: ConditionOption[] = useMemo(() => {
    if (!metaData) {
      if (nodeSettings.condition?.type) {
        return conditionTypesToOptions([nodeSettings.condition.type]);
      }

      return [];
    }

    if (metaData.type === FieldType.Date) {
      return conditionTypesToOptions([ConditionType.Simple, ConditionType.Range]);
    }

    return [];
  }, [metaData, nodeSettings.condition?.type]);

  const typeMismatch: NodeConditionMismatch | null = useMemo(() => {
    return getTypeMismatch(getDateNode(nodeSettings, nodeData), metaData);
  }, [metaData, nodeData, nodeSettings]);

  const disabledConditionInput = useMemo(
    () =>
      conditionTypeOptions.length === 0 ||
      typeMismatch?.level === NodeConditionMismatchLevel.Critical,
    [conditionTypeOptions.length, typeMismatch?.level]
  );

  return (
    <div className={styles.wrapper}>
      <EditorPanelHeader
        columnId={nodeSettings.name}
        columnLabelMap={columnLabelMap}
        columns={columns}
        getMetaData={getMetaData}
        nodeType={DTNodeType.Date}
        onCancel={onCancel}
        onSelect={(name) => {
          updateNodeSettings({
            ...nodeSettings,
            name,
          });
        }}
      />

      <>
        {dateHistogramData && (
          <DateHistogram
            condition={
              nodeSettings.condition?.type === ConditionType.Simple ||
              nodeSettings.condition?.type === ConditionType.Range
                ? nodeSettings.condition
                : undefined
            }
            data={dateHistogramData}
            height={HISTOGRAM_HEIGHT}
            width={HISTOGRAM_WIDTH}
            onChange={handleConditionChange}
          />
        )}
      </>

      {typeMismatch && (
        <div
          className={classNames(styles.typeMismatch, styles.conditionRow)}
          style={{
            backgroundColor:
              typeMismatch.level === NodeConditionMismatchLevel.Critical
                ? NODE_CONDITION_MISMATCH_ERROR
                : NODE_CONDITION_MISMATCH_WARNING,
          }}
        >
          {typeMismatch && getMismatchDescription(typeMismatch)}

          <i>Select a different column from the drop-down list above</i>

          {disabledConditionInput &&
            !(
              typeMismatch.type === NodeConditionMismatchType.MissingColumn ||
              typeMismatch.type === NodeConditionMismatchType.Text
            ) && (
              <>
                <i>
                  or reset the condition type (this will destroy the current condition)
                </i>

                <span className={styles.resetCondition} onClick={() => onReset(nodeData)}>
                  Reset condition
                </span>
              </>
            )}
        </div>
      )}

      <div className={styles.conditionRow}>
        <span>IF</span>

        <Radio.Group
          buttonStyle="solid"
          className={classNames(styles.conditionTypeSelector, {
            [styles.wrapConditionTypes]: conditionTypeOptions.length > 3,
          })}
          disabled={disabledConditionInput}
          options={conditionTypeOptions}
          optionType="button"
          value={nodeSettings.condition?.type}
          onChange={(event) => handleConditionTypeChange(event.target.value)}
        />
      </div>

      {!nodeSettings.condition && (
        <div className={styles.conditionRow}>
          <Alert
            message="Comparison condition is not specified"
            style={{ width: '100%' }}
            type="error"
          />
        </div>
      )}

      {nodeSettings.condition?.type === ConditionType.Simple && (
        <SimpleDateConditionInput
          condition={nodeSettings.condition}
          disabled={disabledConditionInput}
          onChange={handleConditionChange}
        />
      )}

      {nodeSettings.condition?.type === ConditionType.Range && (
        <RangeDateConditionInput
          condition={nodeSettings.condition}
          disabled={disabledConditionInput}
          onChange={handleConditionChange}
        />
      )}

      <OutputsInfo outputDatasetRowIds={nodeResult?.outputDatasetRowIds} />

      {nodeSettings.portGroupingType && (
        <PortGrouping
          portGroupingType={nodeSettings.portGroupingType}
          onChange={handlePortGroupingTypeChange}
        />
      )}
    </div>
  );
};
