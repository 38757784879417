import { CloseOutlined } from '@ant-design/icons';
import { classNames } from '@discngine/moosa-common';
import { Tooltip } from 'antd';
import React, { FC } from 'react';

import { TagRendererProps } from './TagRendererProps';
import styles from './TagWithCaption.module.less';

export interface TagWithCaptionProps extends TagRendererProps {
  tagLabel?: string;
  children?: React.ReactNode;
}

export const TagWithCaption: FC<TagWithCaptionProps> = ({
  tagId,
  tagLabel,
  onRemove,
  children,
  className,
}) => {
  const label = tagLabel || tagId;

  const tagNameTooltip = tagId === tagLabel ? tagId : `${tagId} / ${tagLabel}`;

  return (
    <div className={classNames(className, styles.tagElement)}>
      {children}
      <Tooltip
        mouseEnterDelay={0.4}
        mouseLeaveDelay={0}
        style={{ pointerEvents: 'none' }}
        title={tagNameTooltip}
      >
        <span className={styles.tagLabel}>{label}</span>
      </Tooltip>
      <CloseOutlined className={styles.tagClose} onClick={onRemove} />
    </div>
  );
};
