import { LogarithmicFunctionParams } from '@discngine/moosa-models';
import React, { FC, useCallback } from 'react';

import { useComponentsContext } from '../../ComponentsContext/ComponentsContext';

import styles from './DesirabilityCommonStyles.module.less';
import { DesirabilityFunctionTableParams } from './DesirabilityFunctionTableParams';
import { isValidValue } from './isValidValue';

const LogarithmicFunctionTable: FC<
  DesirabilityFunctionTableParams<LogarithmicFunctionParams>
> = React.memo(function LogarithmicFunctionTable({ className, params, onParamsChange }) {
  const { ValueInput } = useComponentsContext();
  const handleXForY0Change = useCallback(
    (val: string) => {
      const value = val ? Number(val) : NaN;

      if (isValidValue(value) && value !== params.xForY1) {
        onParamsChange({
          ...params,
          xForY0: value,
        });
      }
    },
    [onParamsChange, params]
  );

  const handleXForY1Change = useCallback(
    (val: string) => {
      const value = val ? Number(val) : NaN;

      if (isValidValue(value) && value !== params.xForY0) {
        onParamsChange({
          ...params,
          xForY1: value,
        });
      }
    },
    [onParamsChange, params]
  );

  const handleBaseChange = useCallback(
    (val: string) => {
      const value = val ? Number(val) : NaN;

      if (isValidValue(value) && value !== 1 && value > 0) {
        onParamsChange({
          ...params,
          base: value,
        });
      }
    },
    [onParamsChange, params]
  );

  return (
    <table className={className}>
      <thead>
        <tr className={styles.tableHeader}>
          <td>Param</td>
          <td>Value</td>
        </tr>
      </thead>
      <tbody className={styles.tableBody}>
        <tr>
          <td>base</td>
          <td>
            <ValueInput value={params.base} onValueChange={handleBaseChange} />
          </td>
        </tr>
        <tr>
          <td>x: y=0</td>
          <td>
            <ValueInput value={params.xForY0} onValueChange={handleXForY0Change} />
          </td>
        </tr>
        <tr>
          <td>x: y=1</td>
          <td>
            <ValueInput value={params.xForY1} onValueChange={handleXForY1Change} />
          </td>
        </tr>
      </tbody>
    </table>
  );
});

export default LogarithmicFunctionTable;
