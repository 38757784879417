import { IRGBColor, rgbToString } from '@discngine/moosa-common';
import React, { FC, useCallback, useState, MouseEvent } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';

import { AvailableColorsPanel } from '../../AvailableColorsPanel/AvailableColorsPanel';

import styles from './ColorPointer.module.less';
import { ColorPointerInput } from './ColorPointerInput/ColorPointerInput';

interface IColorPointerProps {
  color: IRGBColor;
  left: number;
  gradientChange: (
    leftPosition: number,
    indexOfSelectedColor: number,
    isMouseUp: boolean
  ) => void;
  gradientScaleWidth: number;
  onColorChange: (newColor: IRGBColor, indexOfSelectedColor: number) => void;
  onRemoveColor: (indexOfSelectedColor: number) => void;
  indexOfSelectedColor: number;
  isPossibleToRemoveColor: boolean;
  range: { min: number; max: number };
  isScoredColorizingMode: boolean;
  onColorPointerMouseDown: (indexOfSelectedColor: number) => void;
  onChangeInputVisibility: (indexOfSelectedColor: number) => void;
  isOnDrag: boolean;
  isInputVisible: boolean;
  isLastActiveColorPointer: boolean;
}

export const ColorPointer: FC<IColorPointerProps> = React.memo(
  ({
    color,
    left,
    isOnDrag,
    gradientChange,
    gradientScaleWidth,
    onColorChange,
    onRemoveColor,
    indexOfSelectedColor,
    isPossibleToRemoveColor,
    isScoredColorizingMode,
    range,
    onColorPointerMouseDown,
    isInputVisible,
    onChangeInputVisibility,
    isLastActiveColorPointer,
  }) => {
    const [isAvailableColorsPanelVisible, setIsAvailableColorsPanelVisible] =
      useState(false);
    const handleAvailableColorsPanelVisibleChange = useCallback((newVisible: boolean) => {
      setIsAvailableColorsPanelVisible(newVisible);
    }, []);

    const onMouseDown = useCallback(
      (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        onColorPointerMouseDown(indexOfSelectedColor);
      },
      [onColorPointerMouseDown, indexOfSelectedColor]
    );

    const onInputMouseDown = useCallback((event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
    }, []);

    const onRemove = useCallback(
      (indexOfSelectedColor: number) => {
        onRemoveColor(indexOfSelectedColor);
        handleAvailableColorsPanelVisibleChange(false);
      },
      [handleAvailableColorsPanelVisibleChange, onRemoveColor]
    );

    const onChange = useCallback(
      (newColor: IRGBColor | null, indexOfSelectedColor: number) => {
        onColorChange(newColor!, indexOfSelectedColor);
        handleAvailableColorsPanelVisibleChange(false);
      },
      [handleAvailableColorsPanelVisibleChange, onColorChange]
    );

    return (
      <>
        {isInputVisible && (
          <div className={styles.overlay} onClick={() => onChangeInputVisibility(-1)} />
        )}
        <div
          className={`${styles.root} ${
            isLastActiveColorPointer ? styles.lastActive : ''
          }`}
          style={{ left: left + 'px' }}
        >
          <div>
            <div className={styles.displayWrap}>
              <Popover
                containerStyle={{
                  zIndex: '100000',
                  backgroundColor: 'white',
                  boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.2)',
                }}
                content={({ position, childRect, popoverRect }) => (
                  <ArrowContainer
                    arrowColor={'white'}
                    arrowSize={10}
                    arrowStyle={{ bottom: '-5px' }}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    position={position}
                  >
                    <AvailableColorsPanel
                      indexOfSelectedColor={indexOfSelectedColor}
                      isPossibleToRemoveColor={isPossibleToRemoveColor}
                      selectedColor={color}
                      showFullColorsPalette={false}
                      onColorChange={onChange}
                      onRemoveColor={onRemove}
                    />
                  </ArrowContainer>
                )}
                isOpen={isAvailableColorsPanelVisible}
                padding={10}
                positions={['top']}
                onClickOutside={() => handleAvailableColorsPanelVisibleChange(false)}
              >
                <div
                  className={styles.colorBox}
                  style={{ background: rgbToString(color) }}
                  onClick={() =>
                    handleAvailableColorsPanelVisibleChange(
                      !isAvailableColorsPanelVisible
                    )
                  }
                />
              </Popover>
            </div>
            <div className={styles.pointer} />
          </div>
          <div className={styles.handleWrap} onMouseDown={onMouseDown}>
            <div
              className={`${styles.inputWrap} ${
                isInputVisible ? styles.inputVisible : ''
              }`}
              onMouseDown={onInputMouseDown}
            >
              <ColorPointerInput
                gradientChange={gradientChange}
                gradientScaleWidth={gradientScaleWidth}
                indexOfSelectedColor={indexOfSelectedColor}
                isInputVisible={isInputVisible}
                isOnDrag={isOnDrag}
                isScoredColorizingMode={isScoredColorizingMode}
                left={left}
                range={range}
                onChangeInputVisibility={onChangeInputVisibility}
              />
            </div>
            <div className={styles.handle} />
            <div className={styles.handlePointer} />
          </div>
        </div>
      </>
    );
  }
);
