import { Group } from '@visx/group';

import { EMPTY_DATA_TEXT } from '../../colors';

import { ReactComponent as EmptyChart } from './resources/empty.svg';

export const EmptyHistogramNotification = (props: { width: number; height: number }) => {
  const { width, height } = props;

  return (
    <Group>
      <Group transform={`translate(${(width - 55) / 2}, ${(height - 34) / 2 - 25})`}>
        <EmptyChart />
      </Group>

      <text
        fill={EMPTY_DATA_TEXT}
        fontFamily="Roboto, sans-serif"
        textAnchor="middle"
        transform="translate(0, 15)"
        x="50%"
        y="50%"
      >
        No data to display
      </text>
    </Group>
  );
};
