export interface DirectedGraphNode<NodeId extends string> {
  id: NodeId;
  in: Set<NodeId>;
  out: Set<NodeId>;
}

export type DirectedGraph<NodeId extends string> = Map<NodeId, DirectedGraphNode<NodeId>>;

/**
 * Performs topological sort on a directed graph.
 * If the graph is cyclic it will be detected and partial sorting returns
 *
 * @param graph
 * @param startNodeId - if node is defined the function returns topological sorting for a subgraph started from this node
 *
 * @return
 *  nodeIds - array of ids sorted topologically, for whole graph,
 *            in this case of subgraph it always starts with startNodeId
 *  isCyclic - always calculated for the whole graph
 *  nodeIdInCycle - if the graph is cyclic it returns one of nodeIds in the cycle
 */
export function sortDirectedGraphTopologically<NodeId extends string>(
  graph: DirectedGraph<NodeId>,
  startNodeId?: NodeId
): { nodeIds: NodeId[]; isCyclicGraph: boolean; nodeIdInCycle?: NodeId } {
  const graphClone = structuredClone(graph);
  //   L ← Empty list that will contain the sorted elements
  //   S ← Set of all nodes with no incoming edge
  //
  //   while S is not empty do
  //     remove a node n from S
  //     add n to L
  //     for each node m with an edge e from n to m do
  //       remove edge e from the graph
  //       if m has no other incoming edges then
  //          insert m into S
  //
  //   if graph has edges then
  //      return error   (graph has at least one cycle)
  //   else
  //     return L   (a topologically sorted order)
  const sorted: NodeId[] = [];

  const subgraphSortingMode = startNodeId !== undefined;
  const sortedSubgraph = new Set<NodeId>();

  if (subgraphSortingMode) sortedSubgraph.add(startNodeId);

  const startingNodes: NodeId[] = [...graphClone.values()]
    .filter((node) => node.in.size === 0)
    .map((node) => node.id);

  while (startingNodes.length) {
    const nodeId = startingNodes.shift()!;

    sorted.push(nodeId);

    const node = graphClone.get(nodeId);

    if (!node) {
      throw new Error(`Unexpectedly no node for ${nodeId}`);
    }

    if (sortedSubgraph.has(nodeId)) {
      for (const out of node.out) {
        sortedSubgraph.add(out);
      }
    }

    const outNodes = [...node.out.values()];

    for (const nextNodeId of outNodes) {
      const nextNode = graphClone.get(nextNodeId)!;

      // remove arrow
      node.out.delete(nextNodeId);
      nextNode.in.delete(nodeId);

      if (!nextNode.in.size) {
        startingNodes.push(nextNode.id);
      }
    }
  }

  const nodeInCycle = [...graphClone.values()].find((node) => !!node.in.size);
  const isCyclicGraph = !!nodeInCycle;

  return {
    nodeIds: subgraphSortingMode ? [...sortedSubgraph.values()] : sorted,
    isCyclicGraph,
    nodeIdInCycle: nodeInCycle?.id,
  };
}
