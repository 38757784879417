import { IPoint } from '@discngine/moosa-models';
import React, { FC, useCallback } from 'react';

import styles from './DesirabilityCommonStyles.module.less';
import { DesirabilityFunctionTableParams } from './DesirabilityFunctionTableParams';
import { isValidValue, isValidYValue } from './isValidValue';
import { PointEditor } from './PointEditor/PointEditor';

const LineSegmentsFunctionTable: FC<
  DesirabilityFunctionTableParams<{
    points: IPoint[];
  }>
> = React.memo(function LineSegmentsFunctionTable({ params, onParamsChange }) {
  const points = params.points;

  const onPointChange = useCallback(
    (index: number) => (point: IPoint) => {
      const newPoints = [...points];

      newPoints[index] = { ...point, originalPointIndex: index };
      onParamsChange({ points: newPoints });
    },
    [points, onParamsChange]
  );

  return (
    <table>
      <thead>
        <tr className={styles.tableHeader}>
          <td>X</td>
          <td>f(X)</td>
        </tr>
      </thead>
      <tbody className={styles.tableBody}>
        {points.map((point, index) => (
          <PointEditor
            key={index}
            point={point}
            xValidator={isValidValue}
            yValidator={isValidYValue}
            onPointChange={onPointChange(index)}
          />
        ))}
      </tbody>
    </table>
  );
});

export default LineSegmentsFunctionTable;
