import { classNames } from '@discngine/moosa-common';
import {
  ColumnId,
  DTNodeType,
  DecisionTreeNodeResult,
  DecisionTreeStructSearchNode,
  IColumnLabelMap,
  IColumnMetaInfo,
  isStructureNodeResult,
  IStructureEditor,
  IStructureEditorAPI,
  PortGroupingType,
  StructureType,
} from '@discngine/moosa-models';
import { Modal, Spin } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  NodeConditionMismatch,
  NodeConditionMismatchLevel,
  NodeConditionMismatchType,
} from 'types';
import { getMismatchDescription, getTypeMismatch } from 'utils';

import {
  NODE_CONDITION_MISMATCH_ERROR,
  NODE_CONDITION_MISMATCH_WARNING,
} from '../../lib/go/colors';

import { EditorPanelHeader } from './shared/EditorPanelHeader';
import { OutputsInfo } from './shared/OutputsInfo';
import { PortGrouping } from './shared/PortGrouping';
import styles from './shared/PropertyNodeEditPanel.module.less';

interface StructureNodeSettings {
  name: string;
  structure: string | null;
  structSvg: string | null;
  portGroupingType: PortGroupingType | null;
}

function nodeToSettings(node: DecisionTreeStructSearchNode): StructureNodeSettings {
  return {
    name: node.propertyId,
    structure: node.structure,
    structSvg: node.structSvg,
    portGroupingType: node.portGroupingType,
  };
}

function settingsToNode(
  node: DecisionTreeStructSearchNode,
  settings: StructureNodeSettings
): DecisionTreeStructSearchNode {
  return {
    ...node,
    propertyId: settings.name,
    structure: settings.structure,
    structSvg: settings.structSvg,
    portGroupingType: settings.portGroupingType,
  };
}

export interface PropertyNodeEditPanelProps {
  nodeData: DecisionTreeStructSearchNode;
  columns: ColumnId[];
  columnLabelMap?: IColumnLabelMap;
  getMetaData: (columnId: ColumnId) => IColumnMetaInfo | undefined;
  getNodeResult: (
    node: DecisionTreeStructSearchNode
  ) => DecisionTreeNodeResult | undefined;
  onNodeChange: (node: DecisionTreeStructSearchNode) => void;
  onCancel: () => void;
  onReset: (node: DecisionTreeStructSearchNode) => void;
  StructureEditor?: IStructureEditor;
  structureToSvg?: (structure: string | null) => string | null;
}

export const StructureNodeEditPanel: FC<PropertyNodeEditPanelProps> = ({
  nodeData,
  columns,
  columnLabelMap,
  getNodeResult,
  getMetaData,
  onNodeChange,
  onCancel,
  onReset,
  StructureEditor,
  structureToSvg,
}) => {
  const [isEditorVisible, setEditorVisibility] = useState(false);
  const [editorApi, setEditorApi] = useState<IStructureEditorAPI>();
  const [isCalculating, setIsCalculating] = useState(false);

  const [nodeSettings, setNodeSettings] = useState<StructureNodeSettings>(
    nodeToSettings(nodeData)
  );

  const cancelStructureEditing = useCallback(() => {
    setEditorVisibility(false);
  }, []);

  const updateNodeSettings = useCallback(
    (settings: StructureNodeSettings) => {
      setNodeSettings(settings);
      onNodeChange(settingsToNode(nodeData, settings));
    },
    [nodeData, onNodeChange]
  );

  const submitStructure = useCallback(() => {
    if (!editorApi) {
      return;
    }
    editorApi
      .getStructure(StructureType.MOLFILE)
      .then((structure: string | null) => {
        setIsCalculating(true);

        // a small timeout to show spinner before starting calculations
        return new Promise<string | null>((resolve) => {
          setTimeout(() => {
            resolve(structure);
          }, 100);
        });
      })
      .then((structure: string | null) => {
        let structSvg: null | string = null;

        try {
          structSvg = structureToSvg && structure ? structureToSvg(structure) : null;
        } catch (err) {
          console.error(err);
        }
        // It is expected that changing the structure will cause quite long term calculations
        updateNodeSettings({ ...nodeSettings, structure, structSvg });
        setEditorVisibility(false);
      })
      .finally(() => setIsCalculating(false));
  }, [editorApi, nodeSettings, updateNodeSettings, structureToSvg]);

  useEffect(() => {
    setNodeSettings(nodeToSettings(nodeData));
  }, [nodeData]);

  const nodeResult = useMemo(() => {
    const result = getNodeResult(nodeData);

    if (!result) return null;

    return isStructureNodeResult(result) ? result : null;
  }, [getNodeResult, nodeData]);

  const metaData = getMetaData(nodeData.propertyId);

  const typeMismatch: NodeConditionMismatch | null = useMemo(() => {
    return getTypeMismatch(settingsToNode(nodeData, nodeSettings), metaData);
  }, [metaData, nodeData, nodeSettings]);

  const handlePortGroupingTypeChange = useCallback(
    (portGroupingType: PortGroupingType) => {
      updateNodeSettings({
        ...nodeSettings,
        portGroupingType,
      });
    },
    [nodeSettings, updateNodeSettings]
  );

  return (
    <div className={styles.wrapper}>
      <EditorPanelHeader
        columnId={nodeSettings.name}
        columnLabelMap={columnLabelMap}
        columns={columns}
        getMetaData={getMetaData}
        nodeType={DTNodeType.StructureSearch}
        onCancel={onCancel}
        onSelect={(name) => {
          updateNodeSettings({
            ...nodeSettings,
            name,
          });
        }}
      />

      {nodeSettings.structSvg && (
        <div className={styles.structPanel} onClick={() => setEditorVisibility(true)}>
          <div dangerouslySetInnerHTML={{ __html: nodeSettings.structSvg }} />
        </div>
      )}
      {!nodeSettings.structure && (
        <div className={styles.coreItemText} onClick={() => setEditorVisibility(true)}>
          Click to draw a core structure
        </div>
      )}
      {!structureToSvg && (
        <div
          className={styles.coreItemTextError}
          onClick={() => setEditorVisibility(true)}
        >
          Structure renderer is not available
        </div>
      )}

      {StructureEditor && (
        <Modal
          cancelButtonProps={{ disabled: isCalculating }}
          okButtonProps={{ disabled: isCalculating }}
          open={isEditorVisible}
          title="Structure Editor"
          width={1280}
          onCancel={cancelStructureEditing}
          onOk={submitStructure}
        >
          <div className={styles.structEditorWrapper}>
            <StructureEditor
              structure={nodeSettings.structure ?? ''}
              onInit={setEditorApi}
            />
            {isCalculating && (
              <div className={styles.spinnerWrapper}>
                <Spin size="large" />
              </div>
            )}
          </div>
        </Modal>
      )}

      {typeMismatch && (
        <div
          className={classNames(styles.typeMismatch, styles.conditionRow)}
          style={{
            backgroundColor:
              typeMismatch.level === NodeConditionMismatchLevel.Critical
                ? NODE_CONDITION_MISMATCH_ERROR
                : NODE_CONDITION_MISMATCH_WARNING,
          }}
        >
          {typeMismatch && getMismatchDescription(typeMismatch)}

          <i>Select a different column from the drop-down list above</i>

          {typeMismatch?.level === NodeConditionMismatchLevel.Critical &&
            !(
              typeMismatch.type === NodeConditionMismatchType.MissingColumn ||
              typeMismatch.type === NodeConditionMismatchType.Text
            ) && (
              <>
                <i>
                  or reset the condition type (this will destroy the current condition)
                </i>

                <span className={styles.resetCondition} onClick={() => onReset(nodeData)}>
                  Reset condition
                </span>
              </>
            )}
        </div>
      )}

      <OutputsInfo outputDatasetRowIds={nodeResult?.outputDatasetRowIds} />

      {nodeSettings.portGroupingType && (
        <PortGrouping
          portGroupingType={nodeSettings.portGroupingType}
          onChange={handlePortGroupingTypeChange}
        />
      )}
    </div>
  );
};
