import React, { FC } from 'react';

import { useComponentsContext } from '../../../../../ComponentsContext/ComponentsContext';
import { CloseCircleIcon } from '../../../../../icons';

import styles from './DeletePointModal.module.less';

type DeletePointModalProps = {
  isDeleteModalVisible: boolean;
  onCancel: () => void;
  onDelete: () => void;
};

export const DeletePointModal: FC<DeletePointModalProps> = React.memo(
  ({ isDeleteModalVisible, onCancel, onDelete }) => {
    const { Col, Row, Button, Modal } = useComponentsContext();

    return (
      <Modal
        centered={true}
        closeIcon={<CloseCircleIcon className={styles.closeIcon} />}
        footer={null}
        open={isDeleteModalVisible}
        width={382}
        onCancel={onCancel}
      >
        <Col className={styles.modal}>
          <Row>
            Are you sure you want to&nbsp;
            <span className={styles.confirmationText}>delete the point</span>?
          </Row>
          <Row className={styles.buttons}>
            <Col>
              <Button className={styles.deleteBtn} onClick={onDelete}>
                Delete
              </Button>
            </Col>
            <Col>
              <Button className={styles.cancelBtn} onClick={onCancel}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Col>
      </Modal>
    );
  }
);
